import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button, NavItem } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import uuid from 'react-uuid';
import Calendar from 'react-calendar';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import { ThreeSixty } from '@material-ui/icons';
import toast, { Toaster } from 'react-hot-toast';


class ChangeRoomSettingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            btw:0,
            desc:'',
            facilities:[],
         
            id:'',
            images:[],
            facilities:[],
            image:null,
            prices:[],
            resourceId:0,
            stock:0,
            uploaded:false,
            newFacility:'',
            minDates:[],
            newMinDate:null,
            newMinDateamount:0,
            newAmountPerson:0,
            newAmountPrice:0,
           
        };
    }

    componentDidMount(){
        console.log(this.props.eventData.eventData)

        this.setState({
            name: this.props.eventData.eventData.name,
            btw:this.props.eventData.eventData.btw,
            desc:this.props.eventData.eventData.desc,
           
            id:this.props.eventData.eventData.id,
            
            resourceId:this.props.eventData.eventData.resourceId,
            stock:this.props.eventData.eventData.stock
        })

        let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/');
        images.on('value', snapshot => {
            this.setState({images:[]})
              snapshot.forEach((childSnap)=>{
                let state = childSnap.val();
                console.log(state)
                  //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                  this.setState(prevState => ({
                      images: [...prevState.images, state],
  
                  } ));
              })
          })

          let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/');
          facilities.on('value', snapshot => {
              this.setState({facilities:[]})
                snapshot.forEach((childSnap)=>{
                  let state = childSnap.val();
                  console.log(state)
                    //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                    this.setState(prevState => ({
                        facilities: [...prevState.facilities, state],
    
                    } ));
                })
            })
            let minDates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/');
            minDates.on('value', snapshot => {
              this.setState({minDates:[]})
                snapshot.forEach((childSnap)=>{
                  let state = childSnap.val();
                  console.log(state)
                    //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                    this.setState(prevState => ({
                        minDates: [...prevState.minDates, state],
    
                    } ));
                })
            })
            let prices = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/prices/');
            prices.on('value', snapshot => {
              this.setState({prices:[]})
                snapshot.forEach((childSnap)=>{
                  let state = childSnap.val();
                  console.log(state)
                    //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                    this.setState(prevState => ({
                        prices: [...prevState.prices, state],
    
                    } ));
                })
            })

    }

    planAppointment(){
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id).update({
            name: this.state.name,
            desc:this.state.desc, 
            stock: Number(this.state.stock)

    
        }).then((data)=>{
         return toast.success('Wijzigingen zijn verwerkt');
           

        }).catch((error)=>{
          toast.error('Er is iets misgegaan probeer opnieuw');
            //error callback
            console.log('error ' , error)
        })

    }

    checkFavourite(type){
        if(type === 'active'){
            return true
        }else{
            return false
        }

    }

    handleUpload(){
        const {image} = this.state;
        this.setState({
          uploaded:true
        })

        console.log(image)

        if(image != null){
          const uploadTask = Firebase.storage().ref(`images/${image.name}`).put(image);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('images')
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/').push().key;
                    Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+key).set({
                        type: 'not-active',
                        id: key,
                        url: url
                    }).then((data)=>{
                      toast.success('Afbeelding is verwerkt');
                       
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                  this.setState({
                    url: url,
                    uploading: true
                  })
                });
            }
          );
        }else{
          this.setState({
            errorMessageLogo: 'Er is geen foto geselecteert.',
            uploaded: false,
            uploading: false
          })
          
        }
    
        
      }

    changeActive(clickedItem){
      toast.success('Een favoriete afbeelding is verwerkt');
console.log(this.state.images[0].id)
        for(let i = 0; i < this.state.images.length; i++){
            Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+this.state.images[i].id).set({
                type: 'not-active',
                id:this.state.images[i].id,
                url: this.state.images[i].url
            }).then((data)=>{
              
               
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }

        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+clickedItem.id).set({
            type: 'active',
            id: clickedItem.id,
            url: clickedItem.url
        }).then((data)=>{
           
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })



    }

    addFacility(){
        if(this.state.newFacility != ''){
        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/').push().key;
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/'+key).set({
            id: key,
            name: this.state.newFacility
        }).then((data)=>{
          toast.success('Faciliteit is toegevoegd');
            this.setState({
                newFacility:''
            })
        }).catch((error)=>{
          toast.error('Faciliteit is niet toegevoegd, probeer het opnieuw');
            //error callback
            console.log('error ' , error)
        })}
    }

    deletePrice(pricesId){
      toast.success('De prijs is verwijderd');
      Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/prices/'+pricesId).remove()
    }

    deleteImage(image){
      toast.success('De afbeelding is verwijderd');
        console.log(image)
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+image).remove()

    }

    deleteFacility(facility){
      toast.success('De Faciliteit is verwijderd');
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/'+facility).remove()

    }

    handleChange(e){
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      addPrice(){
        if(this.state.newAmountPerson != 0){
          var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/prices/').push().key;
          Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/prices/'+key).set({
              id: key,
              person: Number(this.state.newAmountPerson),
              price: Number(this.state.newAmountPrice),
          }).then((data)=>{
            toast.success('De prijs is toegevoegd');
              this.setState({
                newAmountPrice:0,
                newAmountPerson:0
              })
          }).catch((error)=>{
            toast.error('De prijs is niet toegevoegd, probeer het opnieuw');
              //error callback
              console.log('error ' , error)
          })}
      }

      AddMinDate(){
          console.log(new Date(this.state.newMinDate).getMonth())
          if(this.state.newMinDate != null && this.state.newMinDateamount != 0){
            var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/').push().key;
            Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/'+key).set({
                id: key,
                amount: parseFloat(this.state.newMinDateamount),
                day:new Date(this.state.newMinDate).getDate(),
                month: new Date(this.state.newMinDate).getMonth(),
                year: new Date(this.state.newMinDate).getFullYear()
            }).then((data)=>{
              toast.success('Een minimaal aantal nachten is toegevoegd');
                this.setState({
                    newFacility:''
                })
            }).catch((error)=>{
              toast.error('Er is iets misgegaan probeer het later opnieuw');
                //error callback
                console.log('error ' , error)
            })}
      }

      deleteMinDate(minDateId){
        toast.success('Minimaal aantal nachten verwijderd');
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/'+minDateId).remove()
      }



    render() {
     
        return (
            <Row>
               <Toaster />
                <Col lg="12" className="backend-form">
                    <Row className="images-settings-row">
                        <Col sm="12"> <input type="file" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> </Col>
                        {this.state.images.map((image)=>(
                            <Col md="3" className="image-settings">
                                <span>
                                    <input type="checkbox"  checked={this.checkFavourite(image.type)} onClick={() => this.changeActive(image)} /> <label>Highlighted</label>
                                </span>
                                <span className="delete-image">
                                    <Button onClick={()=>this.deleteImage(image.id)}>Verwijder</Button>
                                </span>
                                <img src={image.url} />
                            </Col>
                        ))}
                    </Row>

                   

                    <label>
                       Naam kamer:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <label>
                      Omschrijving:
                    </label>
                    <br/>
                    <textarea className="textarea-rooms" value={this.state.desc} onChange={event => this.setState({ desc: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <label>
                       Aantal kamers:
                    </label>
                    <br/>
                    <input type="number" name="name-client" value={this.state.stock} onChange={event => this.setState({ stock: event.target.value, errorMessage:''})}/>    <br/> 
                    <Button onClick={()=> this.planAppointment()} className="button-create-appointment">Wijzig kamer</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> :null}<br /> <br />
                    <table className="add-facilitie-table"> 
                  <tr>
                    <th>Naam</th>
                    <th>Actie</th>
                  </tr>

                  {this.state.facilities.map((facility)=>(
                   <tr>
                   <td>{facility.name}</td>
                   <td><Button onClick={()=>this.deleteFacility(facility.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="text" value={this.state.newFacility} onChange={(event)=>this.setState({newFacility: event.target.value})}/></td>
                <td><Button onClick={()=>this.addFacility()}>Voeg toe</Button></td>
            </tr>
                {/*To do*/}
                  </table><br/><br/>
                  <table className="add-facilitie-table"> 
                  <tr>
                    <th>Aantal personen</th>
                    <th>Prijs per nacht</th>
                    <th>Actie</th>
                  </tr>

                  {this.state.prices.sort((a, b) => (a.person > b.person) ? 1 : -1).map((price)=>(
                   <tr>
                   <td>{price.person}</td>
                   <td>€ {price.price.toFixed(2)}</td>
                   <td><Button onClick={()=>this.deletePrice(price.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="number" value={this.state.newAmountPerson} onChange={(event)=>this.setState({newAmountPerson: event.target.value})}/></td>
                <td><input type="number" value={this.state.newAmountPrice} onChange={(event)=>this.setState({newAmountPrice: event.target.value})}/></td>
                <td><Button onClick={()=>this.addPrice()}>Voeg toe</Button></td>
            </tr>
                
                  </table><br/><br/>
                  <table className="add-facilitie-table"> 
                  <tr>
                    <th>Datum</th>
                    <th>Min. Aantal nachten</th>
                    <th>Actie</th>
                  </tr>

                  {this.state.minDates.map((minDate)=>(
                   <tr>
                   <td>{minDate.day} - {minDate.month} - {minDate.year} </td>
                   <td>{minDate.amount}</td>
                   <td><Button onClick={()=> this.deleteMinDate(minDate.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="date" value={this.state.newMinDate} onChange={(event)=>this.setState({newMinDate: event.target.value})}/></td>
                <td><input type="number" value={this.state.newMinDateamount} onChange={(event)=>this.setState({newMinDateamount: event.target.value})}/></td>
                <td><Button onClick={()=> this.AddMinDate()}>Voeg toe</Button></td>
            </tr>
                
                  </table>

                
                    
                    <Link className="back-link" to={{pathname: `/instellingen`}} >Terug</Link>

                 

             

                </Col>
            </Row>



        );
    }
};

export default ChangeRoomSettingsComponent;