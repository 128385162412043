import React, {useCallback, useContext} from "react";
import { Container, Row, Col } from "shards-react";

import CheckoutBreakfast from "../components/onlineCheckoutComponentBreakfast/Checkout";
import HeaderView from "./Header";

const OnlineCheckoutBreakfastScreen = (props) => (
  <Container fluid className="main-content-container px-4 layout-back" >

  <HeaderView />
    <CheckoutBreakfast data={props.location.state} />
    
  </Container>
);

export default OnlineCheckoutBreakfastScreen;
