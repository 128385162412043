import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import RoomOverviewComponent from "../../components/Booking/RoomOverviewComponent";
import RoomDetailComponent from "../../components/Booking/RoomDetailComponent";
import HeaderView from "../Header";
import FooterView from "../Footer";
import RoomDetailFamily from "../../components/Booking/RoomDetailFamilyRoom";


const RoomDetailFamilyView = (props) => (

  
  <Container fluid className="main-content-container sub-page">
    <HeaderView />

    <RoomDetailFamily/>
    <FooterView/>
    
  </Container>
);

export default RoomDetailFamilyView;
