import React from "react";
import { Container, Row, Col } from "shards-react";
import PaymentEndPointComponent from "../../components/paymentEndpoint/PaymentEndPointComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";


const PaymentEndPointView = () => (
  <Container fluid className="main-content-container sub-page">
    <HeaderView />
      <PaymentEndPointComponent />
      <FooterView />
    
  </Container>
);

export default PaymentEndPointView;
