import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import CopyIcon from "../../assets/images/svg/copy-solid.svg"
import EditIcon from "../../assets/images/svg/pen-solid.svg"
import DeleteIcon from "../../assets/images/svg/times-solid.svg"

class SettingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          salonInformation: 'inline',
          behandelingen: 'none',
          producten: 'none',
          salonActive: true,
          treatmentsActive: false,
          productsnActive:false,
          about:[],
          openingshours:[],
          employees: [],
          products: [],
          treatments: [],
          services:[],
          products:[],
          companyname:'',
          adres:'',
          phone:'',
          mail:'',
          kvk:'',
          btw:'',
          monday: '',
          tuesday:'',
          wednesday: '',
          thursday:'',
          friday: '',
          saturday: '',
          sunday: '',
          toeristenbelasting:''
        }
      }

      componentDidMount(){
        console.log("hello")
        let settingsRef = Firebase.database().ref('/clients/-uhfwoebfwioudb/');
        settingsRef.on('value', snapshot => {

          let settings = snapshot.val();

          this.setState({
            companyname:settings.name,
            adres:settings.Address,
            phone:settings.phone,
            mail:settings.email,
            kvk:settings.kvk,
            btw:settings.BTW,
            monday: settings.monday,
            tuesday:settings.tuesday,
            wednesday: settings.wednesday,
            thursday:settings.thursday,
            friday: settings.friday,
            saturday: settings.saturday,
            sunday: settings.sunday,
            toeristenbelasting: settings.toeristenbelasting
          })


        })

        //Need to connect the services
        let servicesRef = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        servicesRef.on('value', snapshot => {
              snapshot.forEach((childSnap)=>{
                let state = childSnap.val();
                console.log(state)
                  //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                  this.setState(prevState => ({
                      services: [...prevState.services, state],
  
                  } ));
              })
          })

          let productsRef = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/products');
          productsRef.on('value', snapshot => {
                snapshot.forEach((childSnap)=>{
                  let state = childSnap.val();
                  console.log(state)
                    //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                    this.setState(prevState => ({
                        products: [...prevState.products, state],
    
                    } ));
                })
            })

      }


      changeDetails(){
        Firebase.database().ref('/clients/-uhfwoebfwioudb/').update({
          BTW : this.state.btw,
        Address : this.state.adres,
          monday: this.state.monday,
          tuesday: this.state.tuesday,
          wednesday: this.state.wednesday,
          thursday: this.state.thursday,
          friday: this.state.friday,
          saturday: this.state.saturday,
          sunday: this.state.sunday,
          kvk : this.state.kvk,
          name : this.state.companyname,
          phone : this.state.phone,
          email : this.state.mail,
          toeristenbelasting: this.state.toeristenbelasting

          
      }).then((data)=>{

      }).catch((error)=>{
          //error callback
          console.log('error ' , error)
      })
      }


    render() {

        return (
            <Row>

            <Col lg="12" className="titles-row">
               <Row> 
                <Col sm="2">
                  
                </Col>
                <Col sm="8">
                  <Row>
                    <Col sm="4" className={this.state.salonActive ? "button-settings left active-settings" : 'button-settings left'}>
                        <a onClick={()=>this.setState({
                          salonInformation: 'inline',
                          behandelingen:'none',
                          producten:'none',
                          salonActive: true,
                          treatmentsActive: false,
                          productsnActive:false
                        })}>Algemene informatie</a>
                    </Col>
                    <Col sm="4" className={this.state.treatmentsActive ? 'button-settings center active-settings' : 'button-settings center'}>
                        <a onClick={()=>this.setState({
                          salonInformation: 'none',
                          behandelingen:'inline',
                          producten:'none',
                          salonActive: false,
                          treatmentsActive: true,
                          productsnActive:false
                        })}>Kamers</a>
                    </Col>
                    <Col sm="4"  className={this.state.productsnActive ? 'button-settings right active-settings' : 'button-settings right'}>
                        <a onClick={()=>this.setState({
                          salonInformation: 'none',
                          behandelingen:'none',
                          producten:'inline',
                          salonActive: false,
                          treatmentsActive: false,
                          productsnActive:true
                        })}>Producten</a>
                    </Col>

                  </Row>
                </Col>
                <Col sm="2"></Col>
              </Row>
              <Row className="margin-top">
                <Col sm="12" style={{display: this.state.salonInformation}} >
                  <Row>
                  <Col sm="6">
                      <h4 className="page-title">Bedrijfsinformatie</h4>
                      <div className="small-settings-section">
                        <li><span className="align-left">Bedrijsnaam</span> <span className="align-right"><input type="text" value={this.state.companyname} onChange={(event)=> this.setState({companyname: event.target.value})}/></span></li>
                        <li><span className="align-left">Adres</span> <span className="align-right"><input type="text" value={this.state.adres} onChange={(event)=> this.setState({adres: event.target.value})}/></span></li>
                        <li><span className="align-left">Telefoon</span> <span className="align-right"><input type="text" value={this.state.phone} onChange={(event)=> this.setState({phone: event.target.value})}/></span></li>
                        <li><span className="align-left">E-mail</span> <span className="align-right"><input type="text" value={this.state.mail} onChange={(event)=> this.setState({mail: event.target.value})}/></span></li>
                        <li><span className="align-left">KVK-nummer</span> <span className="align-right"><input type="text" value={this.state.kvk} onChange={(event)=> this.setState({kvk: event.target.value})}/></span></li>
                        <li><span className="align-left">BTW-nummer</span> <span className="align-right"><input type="text" value={this.state.btw} onChange={(event)=> this.setState({btw: event.target.value})}/></span></li>
                        <div className="settings-div">
                        { /* <span className="recover-button"></span>*/}
                          <span className="save-button" onClick={()=> this.changeDetails()}>Opslaan</span>

                        </div>
                      </div>
                  </Col>
               
                  <Col sm="6">
                    <h4 className="page-title">Inchecktijden</h4>
                    <div className="small-settings-section">
                        <li><span className="align-left">Maandag</span> <span className="align-right"> <input type="text" value={this.state.monday} onChange={(event)=> this.setState({monday: event.target.value})}/></span></li>
                        <li><span className="align-left">Dinsdag</span> <span className="align-right"><input type="text" value={this.state.tuesday} onChange={(event)=> this.setState({tuesday: event.target.value})}/></span></li>
                        <li><span className="align-left">Woensdag</span> <span className="align-right"><input type="text" value={this.state.wednesday} onChange={(event)=> this.setState({wednesday: event.target.value})}/></span></li>
                        <li><span className="align-left">Donderdag</span> <span className="align-right"><input type="text" value={this.state.thursday} onChange={(event)=> this.setState({thursday: event.target.value})}/></span></li>
                        <li><span className="align-left">Vrijdag</span> <span className="align-right"><input type="text" value={this.state.friday} onChange={(event)=> this.setState({friday: event.target.value})}/></span></li>
                        <li><span className="align-left">Zaterdag</span> <span className="align-right"><input type="text" value={this.state.saturday} onChange={(event)=> this.setState({saturday: event.target.value})}/></span></li>
                        <li><span className="align-left">Zondag</span> <span className="align-right"><input type="text" value={this.state.sunday} onChange={(event)=> this.setState({sunday: event.target.value})}/></span></li>
                        <div className="settings-div">
               
                        <span className="save-button" onClick={()=> this.changeDetails()}>Opslaan</span>

                        </div>
                      </div>
                  </Col>
                  <Col sm="6">
                      <h4 className="page-title">Toeristenbelasting</h4>
                      <div className="small-settings-section">
                        <li><span className="align-left">Toeristenbelasting</span> <span className="align-right"><input type="text" value={this.state.toeristenbelasting} onChange={(event)=> this.setState({toeristenbelasting: event.target.value})}/></span></li>
                       
                        <div className="settings-div">
                        { /* <span className="recover-button"></span>*/}
                          <span className="save-button" onClick={()=> this.changeDetails()}>Opslaan</span>

                        </div>
                      </div>
                  </Col>
                  
                  </Row>
                  
                </Col>
                <Col sm="12" style={{display: this.state.behandelingen}} >
                <Row>
                    <Col sm="12">
                    
                <table className="settings-table"> 
                  <tr>
                    <th>Naam</th>
                    <th>BTW percentage</th>
                    <th>Voorraad</th>
                  </tr>

                  {this.state.services.map((item, i) => (
                   <tr>
                   <td>{item.name}</td>
                   <td>{item.btw}</td>
                   <td>{item.stock}</td>
                   <td >{/*<img src={CopyIcon} className="svg-icon "/> */}  <Link  to={{pathname: `/instellingen/kamer-wijzigen`,state: {eventData:item}}} >Bekijk</Link></td>
                 </tr>
                ))}

              
                  
                  </table>
                  <div className="end-of-table">
                    Geen kamers meer.
                  </div>
                  <Link className="button-add-appointment small-button" to={{pathname: `/instellingen/kamer-toevoegen`}} >Voeg toe</Link>
                  </Col>
                  <Col sm="12" className="separator-column">

                  </Col>
                  
                  </Row>
                </Col>
                <Col sm="12" style={{display: this.state.producten}} >
                <Row>
                    <Col sm="12">
                   
                <table className="settings-table"> 
                  <tr>
                    <th>Product</th>
                    <th>Voorraad</th>
                    <th>Prijs</th>
                    <th></th>
                  </tr>

                  {this.state.products.map((item, i) => (
                   <tr>
                   <td>{item.name}</td>
                   <td>{item.btw}</td>
                   <td>{item.stock}</td>
                   <td >{/*<img src={CopyIcon} className="svg-icon "/> */}  <Link  to={{pathname: `/instellingen/product-wijzigen`,state: {eventData:item}}} >Bekijk</Link></td>
                 </tr>
                ))}
                
                  </table>
                  <div className="end-of-table">
                    Geen producten meer.
                  </div>
                 {/* <Link className="button-add-appointment small-button" to={{pathname: `/instellingen/product-toevoegen`}} >Voeg toe</Link>*/}
                  </Col>
                  <Col sm="12" className="separator-column">

                  </Col>
                  
                  </Row>
                </Col>
              </Row>
              </Col>
          </Row>
        );
    }
};

export default SettingsComponent;
