import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import SettingsComponent from "../../components/SettingsComponent";

const SettingsScreen = () => (
  <Container fluid className="main-content-container px-4 backend">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Instellingen" subtitle="Cashier'r" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
    <Row>
      <Col lg="12">
          <SettingsComponent />
       
      </Col>
    </Row>
  </Container>
);

export default SettingsScreen;
