import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';




class SignOnHUScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactPerson:'',
            email:'',
            phone:'',
            projectTitle:'',
            govermentProject:'',
            shortDesc:'',
            sizeProject:'',
            budgetProject:'',
            partnersProject:'',
            targetProject:'',
            initiatiefProject:'',
            streetname:'',
            houseNumber:'',
            zip:'',
            city:'',
            category:'',
            socialmedia:'',
            initiatiefDescProject:'',
            questions:'',
            errorMessage:'',
            lat:'',
            long:'',
            submitClicked: false
        };
    }


    handleChange = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          checkedStandard: e.target.checked
        })
      }

      handleChangeElectro = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          electro: e.target.checked
        })
      }

      handleChangeGround = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          checkedStandardGround: e.target.checked
        })
      }

      handleChangeSell = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          checkedStandardSell: e.target.checked
        })
      }

      handleChangeVoorwaarden = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          algVoorwaarden: e.target.checked
        })
      }

      handleChangePrivacy = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          privacy: e.target.checked
        })
      }

      componentDidMount(){
          console.log("0.3");
      }

    async  contactSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            document.getElementById('hook-form').submit();
            let data={
                firstname: this.state.firstname,
                lastname: this.state.lastname
            };

            let response=  await fetch('https://us-central1-cashierr-pixelpros.cloudfunctions.net/user/hu',{
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin' : '*',
                },
                body: JSON.stringify(data)
            })

            let text = await response.text();
            console.log(text);
        } else {

        }

    }

    async submitForm(){

        await fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+this.state.streetname+'+'+this.state.houseNumber+',+'+this.state.zip+',+'+this.state.city+'&key=AIzaSyCg9FZhTByAlhSrEMg1Bt4HtRuNWx4NcT8')
        .then(res => res.json())
        .then(
          (result) => {
              console.log(result.results[0].geometry.location.lat)
            this.setState({
              isLoaded: true,
              lat: String(result.results[0].geometry.location.lat),
              long: String(result.results[0].geometry.location.lng)
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

        
        let checkForm = await this.handleValidation();


        if(checkForm === true){
            var xhr = new XMLHttpRequest();

            console.log("lat:"+this.state.lat);

            // listen for `load` event
xhr.onload = () => {
    console.log("status is:"+xhr.status);
    if(xhr.status === 201){
    window.location.href="https://beta5.pixelpros.nl/bedankt/";
    }

};



            xhr.open('POST', 'https://us-central1-cashierr-pixelpros.cloudfunctions.net/user/hu');
            xhr.setRequestHeader("Content-Type", "application/json");
            let data ={
                clientId:'-907c8a70q98nlcnl9',
                contactPerson:this.state.contactPerson,
            email:this.state.email,
            phone:this.state.phone,
            projectTitle:this.state.projectTitle,
            govermentProject:this.state.govermentProject,
            shortDesc:this.state.shortDesc,
            sizeProject:this.state.sizeProject,
            budgetProject:this.state.budgetProject,
            partnersProject:this.state.partnersProject,
            targetProject:this.state.targetProject,
            initiatiefProject:this.state.initiatiefProject,
            streetname:this.state.streetname,
            houseNumber:this.state.houseNumber,
            zip:this.state.zip,
            city:this.state.city,
            category:this.state.category,
            socialmedia:this.state.socialmedia,
            initiatiefDescProject:this.state.initiatiefDescProject,
            questions:this.state.questions,
            lat:this.state.lat,
            long:this.state.long,
            }
            xhr.send(JSON.stringify(data));
        }

        console.log("form is:"+checkForm)
    }

 handleValidation() {
        let formIsValid = true;
        this.setState({submitClicked: true,Error: false});

                if (!this.state.category) {
                    formIsValid = false;
                        this.setState({
                            errorMessage: 'Controlleer het formulier of u food of non-food verkoopt.',
                            submitClicked: false,
                            Error: true,         
                    })
                }


            

        //city
        if (!this.state.city) {
            formIsValid = false;
            this.setState({
                errorMessage: 'Controlleer de woonplaats.',
                submitClicked: false,
                Error: true,
         })
        }

        //postal
        if (typeof this.state.zip !== 'undefined') {
            if (!this.state.zip.match(/^[1-9]{1}[0-9]{3} ?[A-Za-z]{2}$/)) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'De postcode is niet geldig.',
                    submitClicked: false,
                    Error: true,
                })
            }
        }

        //houseNumber
        if (!this.state.houseNumber.trim()) {
            formIsValid = false;
            this.setState({
                errorMessage: 'Een huisnummer is verplicht.',
                submitClicked: false,
                Error: true,
            })
        }

        //streetName
        if (!this.state.streetname) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de straatnaam.',
                    submitClicked: false,
                   Error: true,
                    
             })
        }
        if (!this.state.initiatiefDescProject) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de omschrijving over de initiatiefnemer(s) van uw project.',
                    submitClicked: false,
                    Error: true,         
            })
        }
        if (!this.state.initiatiefProject) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de initiatiefnemer van uw project.',
                    submitClicked: false,
                    Error: true,         
            })
        }
        if (!this.state.targetProject) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de doelgroep van uw project.',
                    submitClicked: false,
                    Error: true,         
            })
        }

        if (!this.state.partnersProject) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de project partners.',
                    submitClicked: false,
                    Error: true,         
            })
        }
        if (!this.state.budgetProject) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het budget.',
                    submitClicked: false,
                    Error: true,         
            })
        }

        if (!this.state.sizeProject) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Geef een duidelijke omschrijving van uw project.',
                    submitClicked: false,
                    Error: true,         
            })
        }

        if (!this.state.shortDesc) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Geef een duidelijke omschrijving van uw project.',
                    submitClicked: false,
                    Error: true,         
            })
        }
        if (!this.state.govermentProject) {
            formIsValid = false;
            this.setState({
                errorMessage: 'Controlleer de gemeente van het project.',
                submitClicked: false,
                Error: true,
            })
        
    }

        if (!this.state.projectTitle) {
            formIsValid = false;
            this.setState({
                errorMessage: 'Controlleer de titel van het project.',
                submitClicked: false,
                Error: true,
            })
        
    }
                //phone
                if (!this.state.phone) {
                    formIsValid = false;
                        this.setState({
                            errorMessage: 'Controlleer het telefoonnummer.',
                            submitClicked: false,
                            Error: true,         
                    })
                }

        //Email  
        if (typeof this.state.email.trim() !== 'undefined') {
            let lastAtPos = this.state.email.lastIndexOf('@');
            let lastDotPos = this.state.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het e-mail adres.',
                    submitClicked: false,
                    Error: true,
                })
            }
        }


        
        if (!this.state.contactPerson) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de contactpersoon naam.',
                    submitClicked: false,
                    Error: true,
                })
            
        }

        

 


        return formIsValid;

      
    }

    changeSelect(e, type) {
        this.setState({
            category: e,
        })
    }

      



    render() {  

        
        return (
            <div>

                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8} className="h3-blue-left data-section">
                        <form  method="post" action="https://us-central1-cashierr-pixelpros.cloudfunctions.net/user" id="hook-form" onSubmit={(this.contactSubmit.bind(this))}>
                        <input type="date" name="date" placeholder="Datum *" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}  hidden/>
                        <label class="main-label" for="contact_person">Naam contactpersoon *</label>
                        <input type="text" name="contact_person" value={this.state.contactPerson} onChange={event => this.setState({ contactPerson: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="email">E-maildres *</label>
                        <input type="email" name="email" value={this.state.email} onChange={event => this.setState({ email: event.target.value, errorMessage:''})} />
                        
                        <label class="main-label" for="phone">Telefoonnummer *</label>
                        <input type="phone" name="phone" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="title_project">Titel van het project  *</label>
                        <input type="text" name="title_project" value={this.state.projectTitle} onChange={event => this.setState({ projectTitle: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="gov_project">In welke gemeente vindt het project plaats  *</label>
                        <input type="text" name="gov_project" value={this.state.govermentProject} onChange={event => this.setState({ govermentProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="short_desc">Korte omschrijving van het project *</label>
                        <textarea onChange={event => this.setState({ shortDesc: event.target.value, errorMessage:''})} />
                        
                        <label class="main-label" for="size_project">Wat is de projectgrootte in Ha (+ eventuele toelichting)?  *</label>
                        <input type="text" name="size_project" value={this.state.sizeProject} onChange={event => this.setState({ sizeProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="budget_project">Wat is het budget (+ eventuele toelichting)?  *</label>
                        <input type="text" name="budget_project" value={this.state.budgetProject} onChange={event => this.setState({ budgetProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="partner_project">Wie zijn de project partners? *</label>
                        <textarea onChange={event => this.setState({ partnersProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="target_project">Wat is de doelgroep (+ eventuele toelichting)?  *</label>
                        <input type="text" name="target_project" value={this.state.targetProject} onChange={event => this.setState({ targetProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="initiatief_project">Wie is/zijn de initiatiefnemer(s)? *</label>
                        <textarea onChange={event => this.setState({ initiatiefProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="initiatief_desc_project">Geef een omschrijving van de initiatiefnemer(s) *</label>
                        <textarea onChange={event => this.setState({ initiatiefDescProject: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="streetname">Bezoekadres straatnaam  *</label>
                        <input type="text" name="streetname" value={this.state.streetname} onChange={event => this.setState({ streetname: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="house_number">Bezoekadres huisnummer  *</label>
                        <input type="text" name="house_number" value={this.state.houseNumber} onChange={event => this.setState({ houseNumber: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="zip">Bezoekadres postcode  *</label>
                        <input type="text" name="zip" value={this.state.zip} onChange={event => this.setState({ zip: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="city">Bezoekadres woonplaats  *</label>
                        <input type="text" name="city" value={this.state.city} onChange={event => this.setState({ city: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="cat">Onder welke categorie valt uw project? *</label>
                        <select id="cat" name="cat" onChange={(event) => this.changeSelect(event.target.value, 'country')} value={this.state.category}>
                        <option value="" disabled selected>Selecteer een categorie</option>
                        <option value="natuur">Natuur</option>
                        <option value="eten">Eten</option>
                        <option value="sport">Sport</option>
                        <option value="overig">Overig</option>
                        </select>

                        <label class="main-label" for="socialmedia">Heeft u social media waar bezoekers eventueel terecht kunnen? (optioneel)</label>
                        <textarea onChange={event => this.setState({ socialmedia: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="questions">Opmerking? (optioneel)</label>
                        <textarea onChange={event => this.setState({ questions: event.target.value, errorMessage:''})} />

                        </form>
                    </Col>
                    
                </Row>
                <Row className="navigation-row">
                    <Col md={1}></Col>
                    <Col md={5} className="picoo-button-next">
                   
                  <button type="submit"  onClick={()=>this.submitForm()}>{this.state.submitClicked ?  <Spinner color="#FFF" />: <span>Bevestig aanvraag</span>} </button>
                  {this.state.Error?
                        <Row>
                            <Col sm={12}>
                                <span className="error-message">{this.state.errorMessage}</span>
                            </Col>
                        </Row>
                        :null}
                    </Col>
                
                </Row>
                
            
            
            </div>
        );
    }
};

export default SignOnHUScreen;
