import React from "react";
import { Container, Row, Col } from "shards-react";
import BillDetailOrderComponent from "../../components/Bills/BillDetailOrderComponent";

import PageTitle from "../../components/common/PageTitle";



const BillDetailOrderView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Rekening - Detail" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <BillDetailOrderComponent billData={props.location.state}/>
  </Container>
);

export default BillDetailOrderView;
