import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import uuid from 'react-uuid'

import Firebase from 'firebase';



class PaymentBreakfastEndPointComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alreadyExistDates:[],
            allDates:[],
            dates:[],
            events:[],
            eventDb:[],
            writes:0


        };
    }

    getDaysArray(start, end){
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };

    addDays = (date, days = 1) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      };
      
      dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = new Date(startDate);
      
        while (currentDate <= new Date(endDate)) {
          dateArray.push(new Date(currentDate));
          // Use UTC date to prevent problems with time zones and DST
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }
      
        return dateArray;
      }



    generateBookingId(){
        let lengthIs = 0;

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        return 'P'+year+month+this.state.numberBooking
    }

 componentDidMount(){



    if(localStorage.getItem('bookingID')){
             
       /* var newPostKey = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/orders').push().key;
              Firebase.database().ref('/clients/-uhfwoebfwioudb/content/orders/'+newPostKey).set({
                key: newPostKey,
                email:localStorage.getItem('mail'),
                date: localStorage.getItem('date'),
                id: localStorage.getItem('bookingID'),
                bookingsnumber: localStorage.getItem('bookingID'),//aanpaasen
                ispayed: true,
                phone:localStorage.getItem('phone'),
                place:'biejanssen.nl',
                namereceiver: localStorage.getItem('namereceiver'),
                amount: localStorage.getItem('amount'),
                totalPrice: localStorage.getItem('total'),
                name: localStorage.getItem('name'),
                adres: localStorage.getItem('adres'),
                zip: localStorage.getItem('postal'),
                city: localStorage.getItem('city'),
                time: localStorage.getItem('time'),
                shippingCost:  localStorage.getItem('shippingCost'),
                unitPrice:  localStorage.getItem('unitPrice'),
            }).then((data)=>{
                localStorage.removeItem('bookingID') 
            }).catch((error)=>{
                //error callback
           
            })*/
        }
       
    }



    render() {  

        
        return (
      
                <Row className="thank-you-page">
                    <Col sm={2}></Col>
                   <Col sm={8}  className="thank-you-page-container">
                       <h2 className="thanks-text">Dank voor je bestelling!</h2>
                       <hr />
                       <Row>
                           <Col sm={6} className="thank-you-page-colum" >
                               <h3>Goed om te weten</h3>
                               <ul>
                                   <li>Heb je nog vragen over het ontbijt? Stuur dan een mail naar <a href="mailto:info@biejanssen.nl">info@biejanssen.nl</a> of bel of app naar 06-25226150</li>
                               </ul>
                            </Col>
                           <Col sm={6} className="thank-you-page-colum">
                               <h3>Ons bezoeken?</h3>
                               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2513.7555937256793!2d5.762323315372197!3d50.94673285936211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0c1cf8202fa5b%3A0x9b674d3a541840d1!2sB%26B%20Bie%20Janssen!5e0!3m2!1snl!2snl!4v1614591265714!5m2!1snl!2snl" ></iframe>
                               
                            </Col>
                       </Row>
                       
                       <Row>
                           <Col sm={4}></Col>
                           <Col sm={4} className="social-icons">
                                <ul>
                                    <li><a href="https://www.facebook.com/BenBBieJanssen/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/bie_janssen/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/b-b-bie-janssen/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                           </Col>
                           <Col sm={4} className="back-link">
                            <Link className="frontend-button-dark" to={{pathname: `/home`,state: {}}} >Naar home</Link>
                           </Col>
                       </Row>
                   </Col>
                   <Col sm={3}></Col>
                   
                </Row>
                
            
            
       
        );
    }
};

export default PaymentBreakfastEndPointComponent;
