import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button, NavItem } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import uuid from 'react-uuid';
import Calendar from 'react-calendar';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import { ThreeSixty } from '@material-ui/icons';


class AddRoomSettingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            btw:0,
            desc:'',
            facilities:[],
            id:'',
            images:[],
            facilities:[],
            image:null,
            prices:[],
            resourceId:0,
            stock:0,uploaded:false,
            newFacility:'',
            minDates:[],
            newMinDate:null,
            newMinDateamount:0
           
        };
    }

    componentDidMount(){

    }

   

    handleUpload(){
        const {image} = this.state;
        this.setState({
          uploaded:true
        })

        console.log(image)

        if(image != null){
          const uploadTask = Firebase.storage().ref(`images/${image.name}`).put(image);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('images')
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/').push().key;
                    Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+key).set({
                        type: 'not-active',
                        id: key,
                        url: url
                    }).then((data)=>{
                       
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                  this.setState({
                    url: url,
                    uploading: true
                  })
                });
            }
          );
        }else{
          this.setState({
            errorMessageLogo: 'Er is geen foto geselecteert.',
            uploaded: false,
            uploading: false
          })
          
        }
    
        
      }

    changeActive(clickedItem){
console.log(this.state.images[0].id)
        for(let i = 0; i < this.state.images.length; i++){
            Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+this.state.images[i].id).set({
                type: 'not-active',
                id:this.state.images[i].id,
                url: this.state.images[i].url
            }).then((data)=>{
               
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }

        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+clickedItem.id).set({
            type: 'active',
            id: clickedItem.id,
            url: clickedItem.url
        }).then((data)=>{
           
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })



    }

    addFacility(){
        if(this.state.newFacility != ''){
        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/').push().key;
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/'+key).set({
            id: key,
            name: this.state.newFacility
        }).then((data)=>{
            this.setState({
                newFacility:''
            })
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })}
    }

    deleteImage(image){
        console.log(image)
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/images/'+image).remove()

    }

    deleteFacility(facility){

        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/facilities/'+facility).remove()

    }

    handleChange(e){
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      AddMinDate(){
          console.log(new Date(this.state.newMinDate).getMonth())
          if(this.state.newMinDate != null && this.state.newMinDateamount != 0){
            var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/').push().key;
            Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/'+key).set({
                id: key,
                amount: parseFloat(this.state.newMinDateamount),
                day:new Date(this.state.newMinDate).getDate(),
                month: new Date(this.state.newMinDate).getMonth(),
                year: new Date(this.state.newMinDate).getFullYear()
            }).then((data)=>{
                this.setState({
                    newFacility:''
                })
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })}
      }

      deleteMinDate(minDateId){
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.props.eventData.eventData.id+'/minDates/'+minDateId).remove()
      }



    render() {
     
        return (
            <Row>
                <Col lg="12" className="backend-form">
                    <Row className="images-settings-row">
                        <Col sm="12"> <input type="file" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> </Col>
                        {this.state.images.map((image)=>(
                            <Col md="3" className="image-settings">
                                <span>
                                    <input type="checkbox"  checked={this.checkFavourite(image.type)} onClick={() => this.changeActive(image)} /> <label>Highlighted</label>
                                </span>
                                <span className="delete-image">
                                    <Button onClick={()=>this.deleteImage(image.id)}>Verwijder</Button>
                                </span>
                                <img src={image.url} />
                            </Col>
                        ))}
                    </Row>

                   

                    <label>
                       Naam kamer:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <label>
                      Omschrijving:
                    </label>
                    <br/>
                    <textarea className="textarea-rooms" value={this.state.desc} onChange={event => this.setState({ desc: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <Button onClick={()=> this.planAppointment()} className="button-create-appointment">Wijzig kamer tekst</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> :null}<br /> <br />
                    <table className="add-facilitie-table"> 
                  <tr>
                    <th>Naam</th>
                    <th>Actie</th>
                  </tr>

                  {this.state.facilities.map((facility)=>(
                   <tr>
                   <td>{facility.name}</td>
                   <td><Button onClick={()=>this.deleteFacility(facility.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="text" value={this.state.newFacility} onChange={(event)=>this.setState({newFacility: event.target.value})}/></td>
                <td><Button onClick={()=>this.addFacility()}>Voeg toe</Button></td>
            </tr>
                
                  </table><br/><br/>
                  <table className="add-facilitie-table"> 
                  <tr>
                    <th>Datum</th>
                    <th>Min. Aantal nachten</th>
                    <th>Actie</th>
                  </tr>

                  {this.state.minDates.map((minDate)=>(
                   <tr>
                   <td>{minDate.day} - {minDate.month} - {minDate.year} </td>
                   <td>{minDate.amount}</td>
                   <td><Button onClick={()=> this.deleteMinDate(minDate.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="date" value={this.state.newMinDate} onChange={(event)=>this.setState({newMinDate: event.target.value})}/></td>
                <td><input type="number" value={this.state.newMinDateamount} onChange={(event)=>this.setState({newMinDateamount: event.target.value})}/></td>
                <td><Button onClick={()=> this.AddMinDate()}>Voeg toe</Button></td>
            </tr>
                
                  </table>

                
                    
                    <Link className="back-link" to={{pathname: `/instellingen`}} >Terug</Link>

                    

             

                </Col>
            </Row>



        );
    }
};

export default AddRoomSettingsComponent;