import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ChangeAppointment from "../../components/calendar/changeAppointment";
import AddAppointment from "../../components/calendar/addAppointment";
import AddRoomSettingsComponent from "../../components/SettingsComponent/AddRoomSettingsComponent";


const AddRoomSettingsView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen - Kamer toevoegen" subtitle="Cashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <AddRoomSettingsComponent />
    
  </Container>
);

export default AddRoomSettingsView;
