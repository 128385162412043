import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';




class SignOnMarketScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName:'',
            firstname:'',
            lastname:'',
            streetname: '',
            houseNumber:'',
            zip:'',
            city:'',
            country:'',
            email:'',
            emailRepeat:'',
            phone:'',
            kvk:'',
            numberStandard:0,
            numberStandardGround:0,
            numberStandardSell:0,
            height:'',
            food:'',
            volts:'',
            wattage:'',
            market:'',
            descProducts:'',
            questions:'',
            algVoorwaarden:false,
            checkedStandard: false,
            checkedStandardGround: false,
            checkedStandardSell: false,
            electro:false,
            privacy: false,
            errorMessage:'',
            submitClicked: false
        };
    }


    handleChange = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          checkedStandard: e.target.checked
        })
      }

      handleChangeElectro = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          electro: e.target.checked
        })
      }

      handleChangeGround = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          checkedStandardGround: e.target.checked
        })
      }

      handleChangeSell = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          checkedStandardSell: e.target.checked
        })
      }

      handleChangeVoorwaarden = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          algVoorwaarden: e.target.checked
        })
      }

      handleChangePrivacy = (e) => {
        console.log(e.target.checked)
        const { checked } = e.target.checked
        this.setState({
          privacy: e.target.checked
        })
      }

      componentDidMount(){
      }

    async  contactSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            document.getElementById('hook-form').submit();
            let data={
                firstname: this.state.firstname,
                lastname: this.state.lastname
            };

            let response=  await fetch('https://us-central1-cashierr-pixelpros.cloudfunctions.net/user',{
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin' : '*',
                },
                body: JSON.stringify(data)
            })

            let text = await response.text();
            console.log(text);
        } else {

        }

    }

    async submitForm(){

        let checkForm = this.handleValidation();


        if(checkForm === true){
            var xhr = new XMLHttpRequest();

            // listen for `load` event
xhr.onload = () => {
    console.log("status is:"+xhr.status);
    if(xhr.status === 201){
    window.location.href="https://beta1.pixelpros.nl/bedankt/";
    }

};



            xhr.open('POST', 'https://us-central1-cashierr-pixelpros.cloudfunctions.net/user');
            xhr.setRequestHeader("Content-Type", "application/json");
            let data ={
                clientId:'-3evre723ohfew',
                companyName: this.state.companyName,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                streetname: this.state.streetname,
                housenumber: this.state.houseNumber,
                zip: this.state.zip,
                city: this.state.city,
                country: this.state.country,
                email: this.state.email,
                phone: this.state.phone,
                kvk: this.state.kvk,
                checkedStandard: this.state.checkedStandard,
                numberStandard: this.state.numberStandard,
                checkedStandardGround: this.state.checkedStandardGround,
                numberStandardGround: this.state.numberStandard,
                checkedStandardSell: this.state.checkedStandardSell,
                numberStandardSell: this.state.numberStandardSell,
                height: this.state.height,
                food: this.state.food,
                electro: this.state.electro,
                volts: this.state.volts,
                wattage: this.state.wattage,
                market: this.state.market,
                descProducts: this.state.descProducts,
                questions: this.state.questions,
                algVoorwaarden: this.state.algVoorwaarden,
                privacy: this.state.privacy
            }
            xhr.send(JSON.stringify(data));
        }

        console.log("form is:"+checkForm)

        /*var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://us-central1-cashierr-pixelpros.cloudfunctions.net/user');
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({ firstname: "joppe",lastname:"meijers" }));*/
    }

    handleValidation() {
        let formIsValid = true;
        this.setState({submitClicked: true,Error: false});

        if (!this.state.descProducts) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Geef een duidelijke omschrijving van uw producten.',
                    submitClicked: false,
                    Error: true,         
            })
        }
        if (!this.state.privacy) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Ga akkoord met onze privacy voorwaarden.',
                    submitClicked: false,
                    Error: true,         
            })
        }

        if (!this.state.algVoorwaarden) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Ga akkoord met onze algemene voorwaarden.',
                    submitClicked: false,
                    Error: true,         
            })
        }

        if (this.state.electro) {
            if(!this.state.volts){
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de benodigde spanning.',
                    submitClicked: false,
                    Error: true,         
                })
            } else if(!this.state.wattage){
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het verwachte vermogen.',
                    submitClicked: false,
                    Error: true,         
                })
            }
        }


        if (!this.state.food) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het formulier of u food of non-food verkoopt.',
                    submitClicked: false,
                    Error: true,         
            })
        }


        if (this.state.checkedStandardSell) {
            if(!this.state.numberStandardSell){
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het het aantal reserveringen.',
                    submitClicked: false,
                    Error: true,         
                })
            }
            
        }


        if (this.state.checkedStandardGround) {
            if(!this.state.numberStandardGround){
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het het aantal reserveringen.',
                    submitClicked: false,
                    Error: true,         
                })
            }
            
        }


                        if (this.state.checkedStandard) {
                            if(!this.state.numberStandard){
                                formIsValid = false;
                                this.setState({
                                    errorMessage: 'Controlleer het het aantal reserveringen.',
                                    submitClicked: false,
                                    Error: true,         
                                })
                            }
                            
                        }
                //kvk
                if (!this.state.kvk) {
                    formIsValid = false;
                        this.setState({
                            errorMessage: 'Controlleer het KVK-nummer.',
                            submitClicked: false,
                            Error: true,         
                    })
                }

        //phone
        if (!this.state.phone) {
            formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het telefoonnummer.',
                    submitClicked: false,
                    Error: true,         
            })
        }

            //country
                if (!this.state.country) {
                    formIsValid = false;
                    this.setState({
                        errorMessage: 'Controlleer het land.',
                        submitClicked: false,
                       Error: true,
                        
                 })
            }

        //city
        if (!this.state.city) {
            formIsValid = false;
            this.setState({
                errorMessage: 'Controlleer de woonplaats.',
                submitClicked: false,
                Error: true,
         })
        }

        //postal
        if (typeof this.state.zip !== 'undefined') {
            if (!this.state.zip.match(/^[1-9]{1}[0-9]{3} ?[A-Za-z]{2}$/)) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'De postcode is niet geldig.',
                    submitClicked: false,
                    Error: true,
                })
            }
        }

        //houseNumber
        if (!this.state.houseNumber.trim()) {
            formIsValid = false;
            this.setState({
                errorMessage: 'Een huisnummer is verplicht.',
                submitClicked: false,
                Error: true,
            })
        }

        //streetName
        if (!this.state.streetname) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de straatnaam.',
                    submitClicked: false,
                   Error: true,
                    
             })
        }

        //Email  
        if (typeof this.state.email.trim() !== 'undefined') {
            let lastAtPos = this.state.email.lastIndexOf('@');
            let lastDotPos = this.state.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2 && this.state.email === this.state.emailRepeat)) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer het e-mail adres.',
                    submitClicked: false,
                    Error: true,
                })
            }
        }

        //lastName
        if (typeof this.state.lastname.trim() !== 'undefined') {
            if (!this.state.lastname.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de achternaam.',
                    submitClicked: false,
                    Error: true,
                })
            }
        }

        //firstName  
        if (typeof this.state.firstname.trim() !== 'undefined') {
            if (!this.state.firstname.match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de voornaam.',
                    submitClicked: false,
                    Error: true,
                })
            }
        }

        //companyName  
        if (!this.state.streetname) {
                formIsValid = false;
                this.setState({
                    errorMessage: 'Controlleer de bedrijfsnaam.',
                    submitClicked: false,
                    Error: true,
                })
            
        }




        return formIsValid;
    }

    changeSelect(e, type) {
        if (type === 'country') {
                this.setState({
                    country: e,
                })
            
        } else if(type === 'food'){
            this.setState({
                food: e,
            })
        }
        else if(type === 'volts'){
            this.setState({
                volts: e,
            })
        }
        else if(type === 'market'){
            this.setState({
                market: e
            })
        }
    }

      



    render() {  

        
        return (
            <div>

                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8} className="h3-blue-left data-section">
                        <form  method="post" action="https://us-central1-cashierr-pixelpros.cloudfunctions.net/user" id="hook-form" onSubmit={(this.contactSubmit.bind(this))}>
                        <input type="date" name="date" placeholder="Datum *" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}  hidden/>
                        <label class="main-label" for="company_name">Bedrijfsnaam *</label>
                        <input type="text" name="company_name" value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="contact_firstname">Voorletters *</label>
                        <input type="text" name="contact_firstname"  value={this.state.firstname} onChange={event => this.setState({ firstname: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="contact_lastname">Achternaam *</label>
                        <input type="text" name="contact_lastname" value={this.state.lastname} onChange={event => this.setState({ lastname: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="streetname">Straatnaam *</label>
                        <input type="text" name="streetname" value={this.state.streetname} onChange={event => this.setState({ streetname: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="house_number">Huisnummer *</label>
                        <input type="text" name="house_number" value={this.state.houseNumber} onChange={event => this.setState({ houseNumber: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="zip">Postcode *</label>
                        <input type="text" name="zip" value={this.state.zip} onChange={event => this.setState({ zip: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="city">Woonplaats *</label>
                        <input type="text" name="city" value={this.state.city} onChange={event => this.setState({ city: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="country">Land *</label>
                        <select id="country" name="country" onChange={(event) => this.changeSelect(event.target.value, 'country')} value={this.state.country}>
                        <option value="" disabled selected>Selecteer land</option>
                        <option value="nl">Nederland</option>
                        <option value="be">België</option>
                        <option value="de">Duitsland</option>
                        </select>
                        <label class="main-label" for="email">Email *</label>
                        <input type="email" name="email"  value={this.state.email} onChange={event => this.setState({ email: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="email_check">Herhaal email *</label>
                        <input type="email" name="email_check" value={this.state.emailRepeat} onChange={event => this.setState({ emailRepeat: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="phone">Telefoonnummer *</label>
                        <input type="phone" name="phone" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="kvk">KVK-nummer *</label>
                        <input type="text" name="kvk" value={this.state.kvk} onChange={event => this.setState({ kvk: event.target.value, errorMessage:''})} />
                        <label class="main-label">Reservering voor:</label><br />
                        <input type="checkbox" name="standard" onChange={e => this.handleChange(e)} defaultChecked={this.state.checkedStandard}/> <label for="standard"> Standaardmarktkraam zonder zeil</label><br />
                        {this.state.checkedStandard ? <input type="number" name="standard_number" placeholder="Aantal stuks zonder zeil" value={this.state.numberStandard} onChange={event => this.setState({ numberStandard: event.target.value, errorMessage:''})}/> : null}
                        <input type="checkbox" name="standard" onChange={e => this.handleChangeGround(e)} defaultChecked={this.state.checkedStandardGround}/> <label for="standard_ground"> Grondplaats voor eigen materiaal</label><br />
                        {this.state.checkedStandardGround ? <input type="number" name="standard_number" placeholder="Aantal meter eigen materiaal" value={this.state.numberStandardGround} onChange={event => this.setState({ numberStandardGround: event.target.value, errorMessage:''})}/> : null}
                        <input type="checkbox" name="standard" onChange={e => this.handleChangeSell(e)} defaultChecked={this.state.checkedStandardSell}/> <label for="standard_sell"> Grondplaats voor verkoopwagen</label><br />
                        {this.state.checkedStandardSell ? <input type="number" name="standard_number" placeholder="Aantal meter verkoopwagen" value={this.state.numberStandardSell} onChange={event => this.setState({ numberStandardSell: event.target.value, errorMessage:''})}/> : null}
                        <label class="main-label" >Indien van toepassing breedte-diepte-hoogte verkoopwagen</label>
                        <input type="text" name="height_car"  value={this.state.height} onChange={event => this.setState({ height: event.target.value, errorMessage:''})} />

                        <label class="main-label" for="country">Food/Non food *</label>
                        <select id="country" name="country" onChange={(event) => this.changeSelect(event.target.value, 'food')} value={this.state.food}>
                        <option value="" disabled selected>Selecteer land</option>
                        <option value="food">Food</option>
                        <option value="non_food">Non Food</option>
                        </select>
                        <label class="main-label" for="country">Duidelijke omschrijving te verhandelen produkten *</label>
                        <textarea onChange={event => this.setState({ descProducts: event.target.value, errorMessage:''})}/>
                        <label class="main-label" for="country">Elektriciteit nodig?</label><br />
                        <input type="checkbox" name="electricity" onChange={e => this.handleChangeElectro(e)} defaultChecked={this.state.electro}/> <label for="electricity">Ja</label><br />
                        {this.state.electro ?                <span><label class="main-label"  for="spanning">Welke spanning</label>
                        <select id="spanning" name="spanning" onChange={(event) => this.changeSelect(event.target.value, 'volts')} value={this.state.volts}>
                        <option value="" disabled selected>Selecteer aantal volt</option>
                        <option value="240">240 V</option>
                        <option value="400">400 V</option>
                        </select>
                        <label class="main-label" for="country">Te verwachte vermogen</label>
                        <input type="text" name="wattage"  value={this.state.wattage} onChange={event => this.setState({ wattage: event.target.value, errorMessage:''})} required/> </span>: null}
                        <label class="main-label" for="market">Was u al eerder op onze markt</label>
                        <select id="market" name="market" onChange={(event) => this.changeSelect(event.target.value, 'market')} value={this.state.market}>
                        <option value="" disabled selected>Selecteer keuze</option>
                        <option value="yes">Ja</option>
                        <option value="no">Nee</option>
                        </select>
                        <label class="main-label" for="country">Opmerkingen en/of vragen</label>
                        <textarea onChange={event => this.setState({ questions: event.target.value, errorMessage:''})} />
                        <label class="main-label" for="country">Indien van toepssing foto toevoegen van materiaal waarmee u deelneemt</label><br/>
                        {/*<input type="file" name="fileToUpload" id="fileToUpload"></input>*/}
                        <input type="checkbox" name="algVoorwaarden" onChange={e => this.handleChangeVoorwaarden(e)} defaultChecked={this.state.checkedStandardSell}/> <label for="standard_sell"> Ik heb kennis genomen van de algemene voorwaarden</label><br />
                        <input type="checkbox" name="privacy" onChange={e => this.handleChangePrivacy(e)} defaultChecked={this.state.checkedStandardSell}/> <label for="standard_sell"> Ik heb kennis genomen van de privacy policy</label><br />
                        </form>
                    </Col>
                    
                </Row>
                <Row className="navigation-row">
                    <Col md={1}></Col>
                    <Col md={5} className="picoo-button-next">
                   
                  <button type="submit"  onClick={()=>this.submitForm()}>{this.state.submitClicked ?  <Spinner color="#FFF" />: <span>Bevestig inschrijving</span>} </button>
                  {this.state.Error?
                        <Row>
                            <Col sm={12}>
                                <span className="error-message">{this.state.errorMessage}</span>
                            </Col>
                        </Row>
                        :null}
                    </Col>
                
                </Row>
                
            
            
            </div>
        );
    }
};

export default SignOnMarketScreen;
