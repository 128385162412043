import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import uuid from 'react-uuid';
import Calendar from 'react-calendar';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';


class ChangeProductSettingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           name:'',
           unitPrice:0,
        };
    }

    componentDidMount(){
        console.log(this.props.eventData.eventData.images)
    }

    componentDidMount(){
        console.log(this.props.eventData.eventData)

        this.setState({
            name: this.props.eventData.eventData.name,
            unitPrice:this.props.eventData.eventData.unitPrice,
        })
    }

    planAppointment(){
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/products/'+this.props.eventData.eventData.id).update({
            name: this.state.name,
            unitPrice: parseFloat(this.state.unitPrice), 

    
        }).then((data)=>{
           

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

    }



    render() {
     
        return (
            <Row>
                <Col lg="12" className="backend-form">

                    <label>
                       Naam product:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <label>
                       Stuk prijs:
                    </label>
                    <br/>
                    <input type="number" value={this.state.unitPrice} onChange={event => this.setState({ unitPrice: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <table className="settings-table"> 
                  <tr>
                    <th>Product</th>
                    <th>Voorraad</th>
                    <th>Prijs</th>
                    <th></th>
                  </tr>

            
                   <tr>
                   <td>Naam</td>
                   <td>btw</td>
                   <td>stock</td>
                   <td >{/*<img src={CopyIcon} className="svg-icon "/> */}  Bekijk </td>
                 </tr>
          
                
                  </table>
                    
                    <Link className="back-link" to={{pathname: `/instellingen`}} >Terug</Link>
                    <Button onClick={()=> this.planAppointment()} className="button-create-appointment">Wijzig product</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> :null}<br /> <br />

                 

                </Col>

                
            </Row>



        );
    }
};

export default ChangeProductSettingsComponent;