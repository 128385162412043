import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import uuid from 'react-uuid'

import Firebase from 'firebase';



class PaymentManualBookingEndPointComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alreadyExistDates:[],
            allDates:[],
            dates:[],
            events:[],
            eventDb:[],
            writes:0,
            earlier:true


        };
    }


 componentDidMount(){

    let date = new Date(localStorage.getItem('startYear'),localStorage.getItem('startMonth'),localStorage.getItem('startDay'));




    if(date.getDay()  == 0 || date.getDay() == 3){
        this.setState({
            earlier:false,
        })
    }

    if(localStorage.getItem('bookingID')){
             

              Firebase.database().ref('/clients/-uhfwoebfwioudb/events/'+localStorage.getItem('key')).update({
                ispayed: true,
            }).then((data)=>{
                localStorage.removeItem('bookingID') 
            }).catch((error)=>{
                //error callback
           
            })
        }
       
    }



    render() {  

        
        return (
      
                <Row className="thank-you-page">
                    <Col sm={2}></Col>
                   <Col sm={8}  className="thank-you-page-container">
                       <h2 className="thanks-text">Dank voor je boeking!</h2>
                       <hr />
                       <Row>
                           <Col sm={6} className="thank-you-page-colum" >
                           <h3>Goed om te weten</h3>
                               <ul>
                               <li>Je krijgt van ons nog een bevestigingsmail</li>
                              <li>We staan voor uw klaar vanaf 15 uur. Inchecken kan tot 18 uur. Andere tijden alleen in goed overleg.</li>
                                   <li>Heb je vragen over het ontbijt? Stuur dan een <a href="mailto:info@biejanssen.nl">mail</a> of bel!</li>
                                   <li>Bekijk hier de <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2FVoorwaarden%20en%20huisregels%20B%26B%20Bie%20Janssen%20april%202021.pdf?alt=media&token=8780a6c9-734c-4b2f-b5fb-c46f2124caf6">Algemene Voorwaarden</a></li>
                                   <li>Speciale wensen? Geef ze <a href="mailto:info@biejanssen.nl">hier</a> door</li>
                               </ul>
                            </Col>
                           <Col sm={6} className="thank-you-page-colum">
                               <h3>Ons bezoeken?</h3>
                               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2513.7555937256793!2d5.762323315372197!3d50.94673285936211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0c1cf8202fa5b%3A0x9b674d3a541840d1!2sB%26B%20Bie%20Janssen!5e0!3m2!1snl!2snl!4v1614591265714!5m2!1snl!2snl" ></iframe>
                               
                            </Col>
                       </Row>
                       
                       <Row>
                           <Col sm={4}></Col>
                           <Col sm={4} className="social-icons">
                                <ul>
                                    <li><a href="https://www.facebook.com/BenBBieJanssen/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/bie_janssen/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/b-b-bie-janssen/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                           </Col>
                           <Col sm={4} className="back-link">
                            <Link className="frontend-button-dark" to={{pathname: `/home`,state: {}}} >Naar home</Link>
                           </Col>
                       </Row>
                   </Col>
                   <Col sm={3}></Col>
                   
                </Row>
                
            
            
       
        );
    }
};

export default PaymentManualBookingEndPointComponent;
