import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { TextField } from '@material-ui/core';
import Calendar from 'react-calendar';
import toast, { Toaster } from 'react-hot-toast';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';

class ChangeAppointment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            treatments: [],
            clientName: '',
            treatment: '',
            timeTreatment: 0,
            titleTreatment: '',
            date: null,
            startHour: 0,
            startMinutes: 0,
            endHour: 0,
            endMinutes: 0,
            barber: 0,
            errorMessage: '',
            uploaded: false,
            clientPhone: '',
            clientEmail: '',
            dateEnd: '',
            dateStart: '',
            resourceId: 0,
            serouceisset: false,
            roomnumbers: [{ room: 1, text: "#1", title: 'Ruime kamer' }, { room: 2, text: "#2", title: 'Luxe werk' }, { room: 3, text: "#3", title: 'Knusse kamer' }, { room: 4, text: "#4", title: 'Knusse kamer' }, { room: 5, text: "#5", title: 'Knusse kamer' }, { room: 9, text: "#9", title: 'Luxe werk kamer' }, { room: 10, text: "#10", title: 'Knusse kamer' }, { room: 11, text: "#11", title: 'Ruime kamer' }, { room: 12, text: "#12", title: 'Knusse kamer' }],
            roomnumber: 0,
            notes: '',
            isPayed: false,
            dates: [],
            resourceIdString: '',
            overlay: false,
            disabledDates: [], services: [],
            roomData: [],
            stock: 0,
            desc: '',
            resourceId: 0,
            minDates: [],
            options: [],
            facilities: [],
            images: [],
            loadingData: false,
            event: [],
            roomTypeOld: '',
            totalNights: 0,
            price: 0,
            state: new Date(),
            end: new Date(),
            persons: 0,
            amountPersons: 0,
            datesCancel: [],
            datesCancelChanges: [],
            nameResource: ''
        };
    }

    getResourceName(id) {

        let name = ''

        if (Number(id) == 1) {
            name = 'Kleine Knusse kamer'
        } else if (Number(id) == 2) {
            name = 'Knusse kamer'
        } else if (Number(id) == 3) {
            name = 'Ruime knusse kamer'
        }

        return name

    }

    dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    }

    event = [];
    startDateOld = new Date();
    endDateOld = new Date();
    priceOld = 0;
    nightsOld = 0;
    personsOld = 0;
    getEvent() {

        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events/');
        ref.on('value', snapshot => {
            this.setState({ event: [] })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                if (state.bookingsnumber == this.props.eventData.eventData.id) {
                    console.log(state)
                    this.setState({
                        roomTypeOld: this.getResourceName(state.resourceId)
                    })
                    this.event = state;

                    this.priceOld = ((state.singlePrice * state.totalNights) + (2 * state.persons * state.totalNights));
                    console.log(this.priceOld)
                    this.nightsOld = state.totalNights;
                    this.personsOld = state.persons;

                    this.startDateOld = new Date(this.event.startYear, this.event.startMonth, this.event.startDay);
                    this.endDateOld = new Date(this.event.endYear, this.event.endMonth, this.event.endDay)
                    // console.log(new Date(this.event.startYear,this.event.startMonth,this.event.startDay))
                }
            })
        })
    }

    getRooms() {
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        ref.on('value', snapshot => {
            this.setState({ treatments: [] })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    treatments: [...prevState.treatments, state]

                }))
            })
        })
    }

    ResetDateIdChange(resourceId) {
        let dates = this.dateRange(this.start, this.end);


        dates.map((date) => {

            this.state.datesCancelChanges.map((dbDate) => {

                if (date.getFullYear() == dbDate.year && date.getMonth() == dbDate.month && date.getDate() == dbDate.day) {
                    let newAmount = (Number(dbDate.amount) + 1);


                    Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + resourceId + '/dates/' + dbDate.id).update({
                        amount: newAmount

                    }).then((data) => {
                        //toast.success('De boeking is gewijzigd!');
                        console.log('boeking gewijzigd met id:' + dbDate.id)

                    }).catch((error) => {
                        //error callback
                        console.log('error ', error)
                    })
                }
            })

        })


    }

    getResourceIdDates() {

        if (this.props.eventData.eventData.resourceId == 1) {
            this.setState({
                resourceIdString: "-jjnnbcoqw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-jjnnbcoqw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });
            this.ResetDateIds("-jjnnbcoqw");
        } else if (this.props.eventData.eventData.resourceId == 2) {
            this.setState({
                resourceIdString: "-wwouodpihdnqe"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });
            this.ResetDateIds("-wwouodpihdnqe");

        } else if (this.props.eventData.eventData.resourceId == 3) {
            this.setState({
                resourceIdString: "-ljcbwenpidwdkenw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-ljcbwenpidwdkenw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });
            this.ResetDateIds("-ljcbwenpidwdkenw");


        } else if (this.props.eventData.eventData.resourceId == 4) {
            this.setState({
                resourceIdString: "-ljcbwenpidwdkenw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });

        }

    }

    componentWillMount() {

        this.getEvent();

        this.getRooms();
        setTimeout(() => {
            this.fetchData('Standaard kamer');
            console.log('hello timeout')
        }, 50);
        this.setState({
            clientName: this.props.eventData.eventData.title,
            clientEmail: this.props.eventData.eventData.email,
            clientPhone: this.props.eventData.eventData.phone,
            dateStart: this.props.eventData.eventData.dateStart,
            dateEnd: this.props.eventData.eventData.dateEnd,
            isPayed: this.props.eventData.eventData.ispayed,
            roomnumber: this.props.eventData.eventData.roomnumber
        })

        console.log(this.props.eventData.eventData)
    }







    handleRoomNumber(event) {
        console.log(event.target.value)
        this.setState({
            roomnumber: event.target.value
        })

    }

    /*handleTreatmentChange(event){
        this.setState({ treatment: event.target.value, errorMessage:''});
       
        for(var i = 0; i < this.state.treatments.length; i++){
            if(this.state.treatments[i].resourceId == event.target.value){
                
                this.setState({
                    resourceId: this.state.treatments[i].resourceId,
                    serouceisset:true,
                })
            }
        }

        setTimeout(() => {

        this.fetchData(this.state.nameResource);
    }, 100);
    }*/

    RandomId(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    planAppointment = () => {
        const {
            clientName,
            clientEmail,
            clientPhone,
            roomnumber,
            notes,
            isPayed
        } = this.state;

        let updateData = {
            title: clientName,
            email: clientEmail,
            phone: clientPhone,
            notes: notes,
            ispayed: isPayed
        };

        if (roomnumber) {
            updateData.roomnumber = roomnumber;
        }

        Firebase.database().ref('/clients/-uhfwoebfwioudb/events/' + this.props.eventData.eventData.key).update(updateData)
            .then((data) => {
                toast.success('De boeking is gewijzigd!');
            }).catch((error) => {
                console.log('error ', error);
            });
    }
    changePayment(event) {
        console.log(event.target.value)

        if (event.target.value === 'false') {
            this.setState({
                isPayed: false
            })
        } else if (event.target.value === 'true') {
            this.setState({
                isPayed: true
            })
        }
    }

    sendCancelMail() {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/createInvoiceFromOrderJesse", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ name: this.state.clientEmail, email: this.state.clientName })
        })
            .then(res => {
                toast.success('Annulerings e-mail met succes verzonden!');
            })
    }

    cancelBooking() {
        Firebase.database().ref('/clients/-uhfwoebfwioudb/events/' + this.props.eventData.eventData.key).update({
            title: this.state.clientName + ' (Geannuleerd)',
        }).then((data) => {
            toast.success('De boeking is Geannuleerd!');
            this.sendCancelMail();
            this.getResourceIdDates()

        }).catch((error) => {
            //error callback
            console.log('error ', error)
        })



    }

    fetchOtherData(name) {


        for (let i = 0; i < this.state.services.length; i++) {


            console.log('here')
            if (name === this.state.services[i].name) {
                console.log('here name')
                /*let dates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.state.services[i].id+'/dates');
                dates.on('value' , snapshot => {
                    snapshot.forEach((childSnap) => {

                    let state = childSnap.val();
                    console.log(state)
                        
                  });
                })*/


                localStorage.setItem('serviceId', this.state.services[i].id)
                console.log('match')
                this.setState({
                    roomData: this.state.services[i],
                    stock: this.state.services[i].stock,
                    desc: this.state.services[i].desc,
                    resourceId: this.state.services[i].resourceId,
                })



                let roomItem = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/dates');
                roomItem.once('value', snapshot => {
                    this.setState({ disabledDates: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();

                        console.log('stock:' + this.state.services[i].stock)

                        if (state.amount > this.state.services[i].stock - 1) {

                            this.setState(prevState => ({
                                disabledDates: [...prevState.disabledDates, new Date(state.year, state.month, state.day)],
                            }));
                            console.log("not:" + new Date(state.year, state.month, state.day))
                            console.log("not:" + state.id)
                        }


                    });

                })

                let minDates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/minDates');
                minDates.once('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        let newMinDates = { date: new Date(state.year, state.month, state.day), amount: state.amount }
                        this.setState(prevState => ({
                            minDates: [...prevState.minDates, newMinDates],
                        }));

                    });

                })

                let prices = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/prices');
                prices.once('value', snapshot => {
                    this.setState({ options: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            options: [...prevState.options, state],
                        }));
                    });
                })

                let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/facilities');
                facilities.once('value', snapshot => {
                    this.setState({ facilities: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            facilities: [...prevState.facilities, state],
                        }));
                    });
                })

                let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/images');
                images.once('value', snapshot => {
                    this.setState({ images: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            images: [...prevState.images, state],
                        }));
                    });
                })

                this.setState({
                    loadingData: true
                })


            } else {
                console.log('unmatch')
            }
        }
    }

    fetchData(name) {


        this.setState({ disabledDates: [], })
        let fetchedItems = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        fetchedItems.once('value', snapshot => {

            snapshot.forEach((childSnap) => {

                let state = childSnap.val();
                this.setState(prevState => ({
                    services: [...prevState.services, state],
                }));




            });

            this.fetchOtherData(name);




        })




    }
    ResetDateIds(resourceId) {
        let dates = this.dateRange(this.props.eventData.eventData.start, this.props.eventData.eventData.end);

        dates.map((date) => {
            this.state.datesCancel.map((dbDate) => {
                if (date.getFullYear() == dbDate.year && date.getMonth() == dbDate.month && date.getDate() == dbDate.day) {
                    console.log(this.state.resourceIdString)

                    Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + resourceId + '/dates/' + dbDate.id).update({
                        amount: dbDate.amount - 1

                    }).then((data) => {
                        //toast.success('De boeking is gewijzigd!');
                        console.log('boeking gewijzigd met id:' + dbDate.id)

                    }).catch((error) => {
                        //error callback
                        console.log('error ', error)
                    })
                }
            })

        })


    }
    getResourceIdDates() {

        if (this.props.eventData.eventData.resourceId == 1) {
            this.setState({
                resourceIdString: "-jjnnbcoqw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-jjnnbcoqw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });
            this.ResetDateIds("-jjnnbcoqw");
        } else if (this.props.eventData.eventData.resourceId == 2) {
            this.setState({
                resourceIdString: "-wwouodpihdnqe"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });
            this.ResetDateIds("-wwouodpihdnqe");

        } else if (this.props.eventData.eventData.resourceId == 3) {
            this.setState({
                resourceIdString: "-ljcbwenpidwdkenw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-ljcbwenpidwdkenw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });
            this.ResetDateIds("-ljcbwenpidwdkenw");


        } else if (this.props.eventData.eventData.resourceId == 4) {
            this.setState({
                resourceIdString: "-ljcbwenpidwdkenw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancel: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.props.eventData.eventData.start).getFullYear() == state.year && new Date(this.props.eventData.eventData.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancel: [...prevState.datesCancel, state]
                        }))
                    }
                })

            });

        }

    }

    getResourceIdDatesChange() {

        if (this.props.eventData.eventData.resourceId == 1) {
            this.setState({
                resourceIdString: "-jjnnbcoqw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-jjnnbcoqw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancelChanges: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.start).getFullYear() == state.year && new Date(this.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancelChanges: [...prevState.datesCancelChanges, state]
                        }))
                    }
                })

            });
            // this.ResetDateIds("-jjnnbcoqw");
            setTimeout(() => {

                this.ResetDateIdChange("-jjnnbcoqw");
            }, 50);
        } else if (this.props.eventData.eventData.resourceId == 2) {
            this.setState({
                resourceIdString: "-wwouodpihdnqe"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancelChanges: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.start).getFullYear() == state.year && new Date(this.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancelChanges: [...prevState.datesCancelChanges, state]
                        }))
                    }
                })

            });
            // this.ResetDateIds("-wwouodpihdnqe");
            setTimeout(() => {

                this.ResetDateIdChange("-wwouodpihdnqe");
            }, 50);
        } else if (this.props.eventData.eventData.resourceId == 3) {
            this.setState({
                resourceIdString: "-ljcbwenpidwdkenw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-ljcbwenpidwdkenw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancelChanges: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.start).getFullYear() == state.year && new Date(this.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancelChanges: [...prevState.datesCancelChanges, state]
                        }))
                    }
                })

            });
            //this.ResetDateIds("-ljcbwenpidwdkenw");
            setTimeout(() => {

                this.ResetDateIdChange("-ljcbwenpidwdkenw");
            }, 50);

        } else if (this.props.eventData.eventData.resourceId == 4) {
            this.setState({
                resourceIdString: "-ljcbwenpidwdkenw"
            })

            let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates');

            ref.on('value', snapshot => {
                this.setState({
                    datesCancelChanges: []
                })
                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();

                    if (new Date(this.state.start).getFullYear() == state.year && new Date(this.state.start).getMonth() == state.month) {
                        this.setState(prevState => ({
                            datesCancelChanges: [...prevState.datesCancelChanges, state]
                        }))
                    }
                })

            });

        }

    }

    changeBooking() {



        Firebase.database().ref('/clients/-uhfwoebfwioudb/events/' + this.props.eventData.eventData.key).update({
            chosenRoom: '',
            end: this.state.end.getFullYear() + "," + this.state.end.getMonth() + "," + this.state.end.getDay(),
            endDay: this.state.end.getDate(),
            endMonth: this.state.end.getMonth(),
            endYear: this.state.end.getFullYear(),
            persons: this.state.amountPersons,
            resourceId: this.state.resourceId,
            roomnumber: '',
            startDay: this.state.start.getDate(),
            startMonth: this.state.start.getMonth(),
            startYear: this.state.start.getFullYear(),
            totalNights: this.state.totalNights,
        }).then((data) => {
            toast.success('De boeking is Gewijzigd!');
            this.getResourceIdDates()
            this.getResourceIdDatesChange()

        }).then(() => {

        }).catch((error) => {
            //error callback
            console.log('error ', error)
        })



    }

    handleTreatmentChange(event) {
        console.log(event.target.value)

        this.setState({
            images: [], facilities: [], option: [], resourceId: 0, desc: '', stock: 0, roomData: [], resourceName: '', dates: [],
            alreadyExistDates: [],
            services: [],
            disabledDates: []
        })

        this.setState({ treatment: event.target.value, errorMessage: '' });

        for (var i = 0; i < this.state.treatments.length; i++) {
            if (this.state.treatments[i].resourceId == event.target.value) {
                console.log(this.state.treatments[i].name)
                this.setState({
                    resourceId: this.state.treatments[i].resourceId,
                    serouceisset: true,
                    nameResource: this.state.treatments[i].name,
                    id: this.state.treatments[i].id

                })

                console.log('name is:' + this.state.treatments[i].name)

                this.fetchData(this.state.treatments[i].name);


                //this.fetchOtherData(this.state.treatments[i].name);
                //this.fetchData(this.state.treatments[i].name)
            }
        }


    }

    start = new Date();
    end = new Date();
    onchangeValue(event, back) {



        let dates = this.dateRange(event[0], event[1]);
        this.setState({
            dates: this.dateRange(event[0], event[1]),
            start: event[0],
            end: event[1]
        })

        this.start = event[0];
        this.end = event[1];


        console.log('hello:' + event[0].getMonth())


        for (let i = 0; i < dates.length; i++) {
            console.log('hellpher:' + this.state.disabledDates.length)
            for (let k = 0; k < this.state.disabledDates.length; k++) {

                if (dates[i].getDate() === this.state.disabledDates[k].getDate() && dates[i].getMonth() === this.state.disabledDates[k].getMonth()) {
                    console.log('double date' + dates[i].getDay());
                    this.setState({
                        errorMessage: 'Er is een datum geselect die reeds bezet is, selecteer een andere datum.',
                        error: true
                    });
                    console.error('dezde datum is al bezet');
                    return
                } else {
                    console.log(this.state.disabledDates[k].getMonth());
                    console.log(this.state.disabledDates[k].getDate());
                    console.log(dates[i].getMonth())
                    console.log(dates[i].getDate())
                    console.log('nothing');
                    this.setState({
                        selected: event, error: false
                    })

                    this.calculateNights(event[0], event[1]);
                }
            }
        }



        /**/
    }

    calculateNights(start, stop) {

        var date1 = new Date(start);
        var date2 = new Date(stop);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24) - 1);
        console.log('here:' + numberOfNights)
        this.setState({
            totalNights: numberOfNights
        })


    }

    calculatePrice(item) {


        // this.generateBookingId();

        for (let i = 0; i < this.state.options.length; i++) {
            console.log(this.state.options[i].id)
            console.log(item)
            if (item == this.state.options[i].id) {
                this.setState({
                    amountPersons: this.state.options[i].person,
                    persons: item,
                    price: this.state.options[i].price
                })
                if (this.state.totalNights > 0 && this.state.error === false) {
                    this.setState({
                        daysSelected: true
                    })
                }
            }
        }
    }

    render() {
        const { selectedDay } = this.state;
        const FORMAT = 'dd/MM/yyyy';
        const handleChange = (event) => {
            this.setState({
                notes: event.target.value
            })
        };


        return (

            this.state.overlay ?

                <Row className="overlay-row">
                    <Col sm={3}></Col>
                    <Col className="overlay-col" sm={8}>
                        <h3>Wijzig datum/type</h3>
                        <span className="calendar-change-width">
                            <label>Kamer:</label>
                            <br />
                            <input type="text" value={this.state.roomTypeOld} disabled /><br /><br />
                            <label>Aantal personen:</label>
                            <br />
                            <input type="text" value={this.personsOld} disabled /><br /><br />


                            <label>
                                Oude datum:
                            </label>
                            <Calendar locale={"nl-NL"} value={[this.startDateOld, this.endDateOld]} returnValue={"range"} />
                            {this.state.error ?
                                <span className="error-message-room">{this.state.errorMessage}</span>
                                : null}

                            <hr />
                            <ul className="change-ul-li">
                                <li><b>Totaal:</b> <span className="align-right">€ {parseFloat(this.priceOld).toFixed(2)}</span></li>
                            </ul>
                        </span>
                        <span className="calendar-change-width">
                            <label>Kamer:</label>
                            <br />
                            <select onChange={event => this.handleTreatmentChange(event)}>
                                <option value="" disabled selected>Kamer</option>
                                {
                                    this.state.treatments.map(function (item, i) {
                                        return <option key={i} value={item.resourceId}>{item.name}</option>
                                    })
                                }
                            </select><br /><br />
                            <label>Aantal personen</label><br />
                            <ButtonGroup variant="text" color="#27718F" aria-label="text primary button group" className="select-buttons" >
                                {this.state.options.sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, i) => (
                                    <Button value={item.id} onClick={(event) => this.calculatePrice(event.target.value)}>{item.person}</Button>
                                ))}
                            </ButtonGroup>
                            <br /> <br />
                            <label>
                                Nieuwedatum:
                            </label>
                            <Calendar value={this.state.bookings} locale={"nl-NL"} value={[this.state.start, this.state.end]} selectRange={true} returnValue={"range"} onChange={(event) => this.onchangeValue(event, event)} minDate={new Date()} tileDisabled={({ date, view }) =>
                                (view === 'month') && // Block day tiles only
                                this.state.disabledDates.some(disabledDate =>
                                    date.getFullYear() === disabledDate.getFullYear() &&
                                    date.getMonth() === disabledDate.getMonth() &&
                                    date.getDate() === disabledDate.getDate()
                                )} />
                            {this.state.error ?
                                <span className="error-message-room">{this.state.errorMessage}</span>
                                : null}
                            <hr />
                            <ul className="change-ul-li">
                                <li><b>Totaal:</b> <span className="align-right">€ {parseFloat((this.state.price * this.state.totalNights) + ((2 * this.state.amountPersons) * this.state.totalNights)).toFixed(2)}</span></li>
                                <li><b>Verschil:</b> <span className="align-right">{this.priceOld > ((this.state.price * this.state.totalNights) + (2 * this.state.amountPersons * this.state.totalNights)) ? "Terug te betalen: € " + parseFloat(this.priceOld - ((this.state.price * this.state.totalNights) + (2 * this.state.amountPersons * this.state.totalNights))).toFixed(2) : "Nog te betalen: € " + parseFloat(this.priceOld - ((this.state.price * this.state.totalNights) + (2 * this.state.persons * this.state.totalNights))).toFixed(2)} </span></li>
                            </ul>

                            <Button onClick={() => this.changeBooking()} className="button-create-appointment change-app-button">Wijziging doorvoeren</Button>
                            <Button onClick={() => this.setState({ overlay: false })} className="button-create-appointment change-app-button ">Sluiten</Button>
                        </span>

                    </Col>

                </Row> :
                <Row>
                    <Toaster />
                    <Col sm={6} className="backend-form">




                        <span>
                            <label>Kamernummer:</label>
                            <br />
                            <select value={this.state.roomnumber} onChange={event => this.handleRoomNumber(event)}>
                                <option value="" disabled selected>Kamer</option>
                                {
                                    this.state.roomnumbers.map(function (item, i) {
                                        return <option key={i} value={item.room}>Kamer nummer: {item.room}</option>
                                    })
                                }
                            </select>
                            <br /> <br /></span>

                        <label>
                            Naam klant:
                        </label>
                        <br />
                        <input type="text" name="name-client" value={this.state.clientName} onChange={event => this.setState({ clientName: event.target.value, errorMessage: '' })} />
                        <br /> <br />
                        <label>
                            Email klant:
                        </label>
                        <br />
                        <input type="text" name="name-client" value={this.state.clientEmail} onChange={event => this.setState({ clientEmail: event.target.value, errorMessage: '' })} />
                        <br /> <br />
                        <label>
                            Telefoon klant:
                        </label>
                        <br />
                        <input type="text" name="name-client" value={this.state.clientPhone} onChange={event => this.setState({ clientPhone: event.target.value, errorMessage: '' })} />
                        <br /> <br />
                        <label>
                            Betaalstatus:
                        </label>
                        <br />
                        <select value={this.state.isPayed} onChange={(event) => this.changePayment(event)}>
                            <option value={false}>Niet betaald</option>
                            <option value={true}>Betaald</option>
                        </select>

                        <br /> <br />
                        {/* <label>
                        Datum inchecken:
                    </label>
                    <br/>
                    <input type="date" name="bday" onChange={event => this.setState({ dateStart: event.target.value, errorMessage:''})} value={this.state.dateStart}/>
                    <br/> <br/>
                    <label>
                        Datum uitchecken:
                    </label>
                    <br/>
                    <input type="date" name="bday" onChange={event => this.setState({ dateEnd: event.target.value, errorMessage:''})} value={this.state.dateEnd}/>
                   <br/> <br/>*/}
                        <label>
                            Notities
                        </label><br />
                        <textarea value={this.state.notes} onChange={(event) => this.setState({ notes: event.target.value })} />
                        <br /> <br />





                    </Col>
                    <Col sm={4}>
                        <Button onClick={() => this.planAppointment()} className="button-create-appointment change-app-button">Opslaan</Button> {this.state.uploaded ? <span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> : null}<br /> <br />
                        <Button onClick={() => this.setState({ overlay: true })} className="button-create-appointment change-app-button">Datum/ Type Wijzigen</Button>
                        <Button onClick={() => this.cancelBooking()} className="button-create-appointment warning-button change-app-button">Boeking annuleren</Button>

                    </Col>


                </Row>



        );
    }
};

export default ChangeAppointment;