import React from "react";
import { Container, Row, Col } from "shards-react";
import PaymentBreakfastEndPointComponent from "../../components/paymentEndpoint/PaymentBreakfastEndPoint";
import PaymentEndPointComponent from "../../components/paymentEndpoint/PaymentEndPointComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";


const PaymentBreakfastEndPointView = () => (
  <Container fluid className="main-content-container sub-page">
    <HeaderView />
      <PaymentBreakfastEndPointComponent />
      <FooterView />
    
  </Container>
);

export default PaymentBreakfastEndPointView;
