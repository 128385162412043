import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Page,Image, Text, View, Document, StyleSheet,PDFDownloadLink } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo-pixelpros.png'
import BackgroundImage from '../../assets/images/achtergrond.png'
import jsPDF from 'jspdf';


const HeadInvoice = () =>{
   return <View style={styles.page}>
        <View style={styles.section}>
        <Text>Factuur</Text>
        </View>
        <View style={styles.section}>
            <Image style={styles.image} src={Logo} />
        </View>
    </View>
}

const ToInvoice = ({data}) => {
    return <View style={styles.page}>
        <View style={styles.sectionfull}>
            <Text>{data.name}</Text>
            <Text>De Haamen 1</Text>
            <Text>6191HV, Beek</Text>
        </View>
    </View>
}


const InformationInvoice = () =>{
    return <View style={styles.pageText}>
        <View style={styles.sectionText}>
        <Text>Tel: 0610090840</Text>
        <Text>mail: joppe@pixelpros.nl</Text>
        </View>
        <View style={[styles.sectionText,styles.textRight]}>
        <Text>Onze ref: ABXENWKr</Text>
        <Text>Contactpersoon: Joppe Meijers</Text>
        <Text>Mail: joppe@pixelpros.nl</Text>
        </View>
    </View>
}

const DateInvoice = () => {
    return <View style={styles.pageTextReg}>
        <View style={styles.sectionTextReg}>
            <Text>Datum: 01-01-2020</Text>
            <Text>Betreft: Cloud opslag</Text>
        </View>
    </View>
}

const HrLineTop = () =>{
    return <View style={styles.hrLineTop}></View>
}

const HrLine = () =>{
    return <View style={styles.hrLine}></View>
}

const TableInvoice = () =>{
    return <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Product</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Type</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Period</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Price</Text> 
                </View> 
                </View> 
            <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>React-PDF</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>3 User </Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>5€</Text> 
                </View> 
            </View> 
        </View>
}

const TextInvoice = () => {
    return <View style={styles.pageTextReg}>
        <View style={styles.sectionTextReg}>
            <Text>Gelieve bovenstaand bedrag onder vermelding van het factuurnummer binnen 14 dagen na factuurdatum te betalen op IBAN-nummer: NL98 INGB 0009 543250 t.n.v. PixelPro’s V.O.F.</Text>
        </View>
    </View>
}

const FooterInvoice = () =>{
    return <View style={styles.pageFooter}>
         <View style={styles.sectionThree}>
            <Text style={styles.footerTextBlue}>een onderneming waar {"\n"}innovatie en maatwerk {"\n"}centraal staan</Text>
         </View>
         <View style={styles.sectionThree}>
            <Text style={styles.footerTextGrey}>Spechthof 7, 6181 KK, Elsloo {"\n"}+31 6 117 346 02{"\n"}info@pixelpros.nl</Text>
         </View>
         <View style={styles.sectionThree}>
         <Text style={styles.footerTextGrey}><Text style={styles.footerTextBlue}>kvk</Text> 75729474{"\n"}<Text style={styles.footerTextBlue}>iban</Text> NL98 INGB 0009 5432 50{"\n"}<Text style={styles.footerTextBlue}>btw</Text> NL860377003B01{"\n"}</Text>
         </View>
     </View>
 }
 



const MyDoc = () => (
  <Document>
  <Page size="A4" style={styles.pageFull}>

    <HeadInvoice />
    <HrLineTop/>
    <ToInvoice data={{name:'joppe'}}/>
    <InformationInvoice />
    <DateInvoice />
    <TableInvoice />
    <HrLine />
    <TextInvoice />
    <FooterInvoice />
    
  </Page>
</Document>
)




class InvoiceIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments:[],
            thisMonth:0,
            target: 5000,
            setTarget: 5000,
            data :  {
                datasets: [{
                    data: [10,10],
                    backgroundColor:["#334B5F","#EDEBEB"],
                }],
               
            
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    'Verkocht',
                    'Doel',
               
                ]
            },
        };
    }
    
    collectData(){
        
        for(let i =0; i < this.state.payments.length; i++){
            this.setState({
                thisMonth: this.state.thisMonth +this.state.payments[i].total,
                target: this.state.target - this.state.payments[i].total
            })
        }
    }

    componentDidMount() {

        let ref = Firebase.database().ref('/revenue');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            if(state.key === '072020'){                  //let payments = {name: state.title +' - ' +state.gender, id: state.id, price: state.price, amount: state.amount, btw: state.btw};
                    this.setState(prevState => ({
                        payments: [...prevState.payments, state]
                    }))  }
              
            });
            this.collectData();
        })

 




        /*let ref = Firebase.database().ref('/category');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log('firebase output:'+state)
            var newelement = {name: state.name, key: state.key};
            this.setState(prevState => ({
              
              categories: [...prevState.categories, newelement],
            }));
            console.log('firebase'+state.name);
            });
        })
  */
    }

   async TestPayment(){
fetch('https://us-central1-cashierr-pixelpros.cloudfunctions.net/user/payment', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({price: 10.50, description: 'test'})
        });
        
    }


    saveInfo() {
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;

        var newPostKey = Firebase.database().ref().child('/q-sort').push().key;
        Firebase.database().ref('/' + dateStamp + '/' + newPostKey).set({
            companyName: this.state.companyName,
            participants: this.state.participants,
            questions: this.state.questions,
            stelling: this.state.stelling,
            key: newPostKey,
            user: 1,
        })

        this.setState({
            key: newPostKey,
        })
    }

    render() {
        return (
            <Row>
                <Col lg="12">
                    <h6 className="titles-setup">Financieël overzicht</h6>
                </Col>
                <Col lg="12">
                <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.section}>
      <Text>Factuur</Text>
    </View>
   
        <Image style={styles.image} src={Logo} />
   
  </Page>
</Document>
                </Col>
                <Col lg="12">
  <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
    </PDFDownloadLink>
    <Button variant="primary" className="button-chashdesk"  onClick={() => this.TestPayment()}>Test betaling</Button>

                </Col>
                
                
                
            </Row>



        );
    }
};


// Create styles
const styles = StyleSheet.create({
    pageFull:{
        padding: 40,
    },
  page: {
    flexDirection :'row',
    backgroundColor: 'white',
    marginBottom:'-10%',
    paddingBottom:'-10%',
    width:'100%',
    position:'relative'
  },
  pageFooter:{
    flexDirection :'row',
    backgroundColor: 'white',

    width:'100%',
    position:'relative',
    bottom: 0,
    flex:1,
    justifyContent:'flex-end',
    alignItems:"flex-end"
  },
  pageText: {
    flexDirection :'row',
    backgroundColor: 'white',
    marginTop:-80
  },
  pageTextReg:{
    flexDirection :'row',
    backgroundColor: 'white',
    marginTop: 20
  },
  textRight:{
marginLeft:'40%'
  },
  sectionText:{
    fontSize: 12
  },
  sectionTextReg:{
    fontSize: 12
  },
  section: {

    width:'50%',
    textAlign:'left',
    
    
  },
  sectionThree: {

    width:'33%',
    textAlign:'left',
    
    
  },
  sectionfull:{    
    float:'left',
    width:'100%',
    textAlign:'left',
    marginTop:20,
    fontSize:12
    },
  image: {
    marginLeft:'40%',
    width:120,
    height:30
  },
  line:{
      marginTop: 10,
      borderTopWidth: 2,
      borderTopStyle: 'solid',
      borderTopColor:'black',
      flexBasis:'100%'
  }, hrLineTop:{
    padding:5,
    width:'100%',
    borderBottomWidth: 2,
    borderBottomStyle:'solid',
    borderBottomColor:'black',
    marginTop:-90
},
  hrLine:{
    marginTop:20,
      padding:5,
      width:'100%',
      borderBottomWidth: 2,
      borderBottomStyle:'solid',
      borderBottomColor:'black'
  },
  hrTop:{
      marginTop:-90
  },
  table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, marginTop:"5%" }, 
  tableRow: { margin: "auto", flexDirection: "row" }, 
  tableCol: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }, 
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  footerTextBlue:{
      fontSize: 12,
      color: "blue"
  },
  footerTextGrey:{
    fontSize: 12,
    color: "grey"
},

});

export default InvoiceIndex;