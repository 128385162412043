import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import { Link } from 'react-router-dom';



 


class ClientComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           

        };
    }


   


    render() {  

        
        return (
            <div>

                <Row >
                    <Col sm={12} className="bills-component">

                <table className="bills-table"> 
                  <tr>
                    <th>Klantnummer</th>
                    <th>Naam</th>
                    <th>Telefoonnummer</th>
                    <th>E-mail</th>
                    <th>Acties</th>
                  </tr>
                  <tr>
                      <td>001</td>
                      <td>Joppe Meijers</td>
                      <td>0610090840</td>
                      <td>joppe@pixelpros.nl</td>
                      <td><Link to={{pathname: `/klanten/details`,state: {clientData:'joppe'}}} className="table-link">Bekijk</Link></td>
                  </tr>
                       
                 </table>
                 </Col>
                 <Col sm={12} className="black-space">

 </Col>
                </Row>
                
            
            
            </div>
        );
    }
};


export default ClientComponent;
