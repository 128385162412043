import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import uuid from 'react-uuid';
import Calendar from 'react-calendar';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';


class AddProductSettingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }



    render() {
     
        return (
            <Row>
                <Col lg="12" >
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="6">
                        
                        
                    <label>
                       Naam klant:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.clientName} onChange={event => this.setState({ clientName: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    
                    
                    <Button onClick={()=> this.planAppointment()} className="button-create-appointment">Plaats boeking</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> :null}<br /> <br />

                    <Link className="back-link" to={{pathname: `/instellingen`}} >Terug</Link>

                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                </Col>

                
            </Row>



        );
    }
};

export default AddProductSettingsComponent;