import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase';
import 'firebase/auth';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./Auth";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


var config ={
    apiKey: "AIzaSyBDvvwOEc9aAmgFPYsPXH0GsFJMrC7QAzo",
    authDomain: "cashierr-pixelpros.firebaseapp.com",
    databaseURL: "https://cashierr-pixelpros.firebaseio.com",
    projectId: "cashierr-pixelpros",
    storageBucket: "cashierr-pixelpros.appspot.com",
    messagingSenderId: "878056386362",
    appId: "1:878056386362:web:a6133c8c8b9bb294be2a03"
};
firebase.initializeApp(config);


ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>

, 
document.getElementById('root'));


