import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class PayBreaffastOnlineScreenComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mail: '',
            adres: '',
            postal: '',
            city: '',
            shippingCost: 0,
            unitPrice: 0,
            amount: 0,
            phone: '',
            timeslot: 'selected',
            breakfastAmount: 0,
            shippingCostAmount: 0,
            houseNumber: '',
            total: 0,
            shippingDefault: 0,
            date: new Date(),
            eventDb: [],
            numberBooking: 0,
            bookingID: '',
            namereceiver: '',
            disabled: [],
            bookings: new Date(),
            selected: new Date(),
            ids: [],
            eventSelected: [],
            disabledDates: [],
            vacations: [],
            minDate: new Date(),
            amountKids: 0,
            distanceMessage: '',
            isDeliveryValid: true
        };
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    handleDayChange(day) {
        this.setState({ date: day.getFullYear() + '-' + (Number(day.getMonth()) + 1) + '-' + day.getDate() });
    }

    generateBookingId() {
        let lengthIs = 0;
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        return 'P' + year + month + this.state.numberBooking;
    }

    check24Hours() {
        console.log(new Date().getHours());
        let date = new Date();
        let nextDay = (date.getDate() + 1);
        if (new Date().getHours() > 14) {
            let nextDate = new Date(date.getFullYear(), date.getMonth(), nextDay);
            this.setState(prevState => ({
                vacations: [...prevState.vacations, new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())]
            }));
            console.log(nextDate);
        }
    }

    getVacations() {
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/vacationdays/');
        ref.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    vacations: [...prevState.vacations, new Date(state.year, state.month, state.day)]
                }));
                console.log(new Date(state.year, state.month, state.day));
            });
        });

        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2023, 3, 8)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2023, 3, 9)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2023, 3, 10)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2023, 3, 15)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2023, 3, 16)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2022, 11, 28)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2022, 11, 29)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2022, 11, 30)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2022, 11, 31)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2021, 11, 30)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2021, 11, 31)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2025, 0, 1)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2025, 0, 2)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2025, 0, 3)]
        }));

        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 24)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 25)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 26)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 27)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 28)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 29)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 30)]
        }));
        this.setState(prevState => ({
            vacations: [...prevState.vacations, new Date(2024, 11, 31)]
        }));
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.setState({
            vacations: []
        });

        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 2);

        this.setState({
            minDate: tomorrow
        });

        if (!window.google) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBEu0SM04QbKIDTrJR83QvcCMR2XvVjdYs&libraries=places';
            var headScript = document.getElementsByTagName('script')[0];
            headScript.parentNode.insertBefore(script, headScript);
        }

        this.getVacations();
        this.check24Hours();
        let refEvent = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/orders');
        refEvent.on('value', snapshot => {
            this.setState({
                eventDb: []
            });
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    eventDb: [...prevState.eventDb, state]
                }));
            });
            this.setState({
                numberBooking: (parseInt(this.state.eventDb.length) + 1)
            });
        });

        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/products/-iuhcnqwib');
        ref.on('value', snapshot => {
            let state = snapshot.val();
            this.setState({
                shippingCost: state.shippingCost,
                shippingDefault: state.shippingCost,
                unitPrice: state.unitPrice,
            });
        });
    }

    payOrder() {
        localStorage.setItem('name', this.state.name);
        localStorage.setItem('mail', this.state.mail);
        localStorage.setItem('date', this.state.date);
        localStorage.setItem('phone', this.state.phone);
        localStorage.setItem('adres', this.state.adres + ' ' + this.state.houseNumber);
        localStorage.setItem('postal', this.state.postal);
        localStorage.setItem('city', this.state.city);
        localStorage.setItem('bookingID', this.generateBookingId());
        localStorage.setItem('amount', parseFloat(this.state.amount));
        localStorage.setItem('shippingCost', parseFloat(this.state.shippingCost));
        localStorage.setItem('unitPrice', parseFloat(this.state.unitPrice));
        localStorage.setItem('total', (parseFloat((this.state.unitPrice * this.state.amount) + this.state.shippingCost).toFixed(2)));
        localStorage.setItem('namereceiver', this.state.namereceiver);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://app.biejanssen.nl/paymentBreak');
        xhr.setRequestHeader("Content-Type", "application/json");

        let data = {
            totalPrice: (parseFloat((this.state.unitPrice * this.state.amount) + this.state.shippingCost).toFixed(2)).toString(),
            nameClient: this.state.name,
            emailClient: this.state.mail,
        }
        xhr.send(JSON.stringify(data));

        xhr.onload = () => {
            console.log("status is:" + xhr.responseURL);
            window.location.href = xhr.responseURL;
        };
    }

    changeCity(city) {
        this.setState({
            bookingID: this.generateBookingId()
        });

        this.setState({
            city: city
        });
        if (city.toLowerCase() === 'elsloo') {
            this.setState({
                shippingCost: 0
            });
        } else {
            this.setState({
                shippingCost: this.state.shippingDefault
            });
        }
    }

    selectTime(value) {
        this.setState({ timeslot: value });
        if (Number(value) == 1) {
            localStorage.setItem('time', '9.00 - 9.30');
        } else if (Number(value) == 2) {
            localStorage.setItem('time', '9.30 - 10.00');
        } else if (Number(value) == 3) {
            localStorage.setItem('time', '10.00 - 10.30');
        } else if (Number(value) == 4) {
            localStorage.setItem('time', '10.30 - 11.00');
        }

        localStorage.setItem('name', this.state.name);
        localStorage.setItem('mail', this.state.mail);
        localStorage.setItem('date', this.state.date);
        localStorage.setItem('phone', this.state.phone);
        localStorage.setItem('adres', this.state.adres + ' ' + this.state.houseNumber);
        localStorage.setItem('postal', this.state.postal);
        localStorage.setItem('city', this.state.city);
        localStorage.setItem('bookingID', this.state.bookingID);
        localStorage.setItem('amount', this.state.amount);
        localStorage.setItem('shippingCost', this.state.shippingCost);
        localStorage.setItem('unitPrice', this.state.unitPrice);
        localStorage.setItem('namereceiver', this.state.namereceiver);
        localStorage.setItem('total', parseFloat((this.state.unitPrice * this.state.amount) + this.state.shippingCost).toFixed(2));
    }

    parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }

    onchangeValue(event) {
        console.log(event.getFullYear());
        console.log(event.getMonth());
        console.log(event.getDate());
        console.log(event.getFullYear() + '-' + event.getMonth() + '-' + event.getDate());
        let newDate = event.getFullYear() + '-' + event.getMonth() + '-' + event.getDate();
        this.setState({
            date: newDate,
            selected: event
        });
        console.log(event.getFullYear());
    }

    checkDistance(inputAddress) {
        const fixedAddress = "Raadhuisstraat 46, 6181 HB, Elsloo";  // Zorg ervoor dat dit een geldig adres is.
        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
            origins: [inputAddress],
            destinations: [fixedAddress],
            travelMode: 'DRIVING',
        }, (response, status) => {
            if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                const distance = response.rows[0].elements[0].distance.value / 1000; // afstand in kilometers
                if (distance > 10) {
                    this.setState({ distanceMessage: 'Het adres ligt buiten ons bezorggebied van 10 km.', isDeliveryValid: false });
                } else {
                    this.setState({ distanceMessage: '', isDeliveryValid: true });
                }
            } else {
                console.error('Error was: ' + status + ', defaulting distance to 11 km.');
                // Stel een standaardafstand in als de API geen geldige afstand kan ophalen
                this.setState({ distanceMessage: 'Het adres ligt buiten ons bezorggebied van 10 km.', isDeliveryValid: false });
            }
        });
    }

    validateForm() {
        const { adres, houseNumber, city } = this.state;
        if (!adres.trim() || !houseNumber.trim() || !city.trim()) {
            this.setState({ distanceMessage: 'Vul alstublieft alle adresgegevens in.' });
            return false;
        }
        return true;
    }

    handleAddressChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === 'city') {
                this.changeCity(value);
            }
            if (this.validateForm()) {
                const fullAddress = `${this.state.adres} ${this.state.houseNumber}, ${this.state.city}`;
                this.checkDistance(fullAddress);
            }
        });
    }

    render() {
        const { selectedDay } = this.state;
        const FORMAT = 'dd/MM/yyyy';
        return (
            <div className="home-page">
                <Row className="contact-row">
                    <Col sm={12} className="contact-colum">
                        <h2>Ontbijt bestellen</h2>
                        <p></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={6} className="frontend-form breakfast">
                        <form method="post" action={"https://biejanssen.nl/paymentBreak"} id="hook-form">
                            <label>Je naam:</label>
                            <br />
                            <input required type="text" name="nameClient" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Je e-mail:</label>
                            <br />
                            <input required type="text" name="emailClient" value={this.state.mail} onChange={event => this.setState({ mail: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Je telefoonnummer:</label>
                            <br />
                            <input required type="text" name="phoneClient" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Naam ontvanger:</label>
                            <br />
                            <input required type="text" name="namedeliver" value={this.state.namereceiver} onChange={event => this.setState({ namereceiver: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Lever datum:</label>
                            <br />
                            <input required hidden type="text" name="datedeliver" value={this.state.date} />
                            <Calendar locale={"nl-NL"} value={this.state.selected} selectRange={false} returnValue={"start"} onChange={(event) => this.onchangeValue(event)} minDate={this.state.minDate} tileDisabled={({ date, view }) => (view === 'month') && this.state.vacations.some(disabledDate => date.getFullYear() === disabledDate.getFullYear() && date.getMonth() === disabledDate.getMonth() && date.getDate() === disabledDate.getDate())} />
                            <br /><br />
                            <span className="half-input">
                                <label>Aflever adres:</label>
                                <br />
                                <input required type="text" name="adres" value={this.state.adres} onChange={this.handleAddressChange} />
                                <br /><br />
                            </span>
                            <span className="half-input right-input-half-mobile">
                                <label>Huisnummer:</label>
                                <br />
                                <input required type="text" name="houseNumber" value={this.state.houseNumber} onChange={this.handleAddressChange} />
                                <br /><br />
                            </span>
                            <br />
                            <label>Aflever postcode:</label>
                            <br />
                            <input required type="text" name="postal" value={this.state.postal} onChange={event => this.setState({ postal: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Aflever woonplaats:</label>
                            <br />
                            <input required type="text" name="city" value={this.state.city} onChange={this.handleAddressChange} />
                            <br />
                            {this.state.distanceMessage && <div style={{ color: 'red' }}>{this.state.distanceMessage}</div>}
                            <br />
                            <label>Aantal volwassen ontbijtjes:</label>
                            <br />
                            <input required type="number" name="amount" min="0" value={this.state.amount} onChange={event => this.setState({ amount: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Aantal kids ontbijtjes:</label>
                            <br />
                            <input required type="number" name="amountKids" min="0" value={this.state.amountKids} onChange={event => this.setState({ amountKids: event.target.value, errorMessage: '' })} />
                            <br /><br />
                            <label>Tijdslot:</label>
                            <br />
                            <select required name="time" id="cars" value={this.state.timeslot} onChange={(event) => this.selectTime(event.target.value)}>
                                <option value="selected" selected="selected" disabled="disabled">Selecteer een tijdslot</option>
                                <option value="1">9.00 uur - 9.30 uur</option>
                                <option value="2">9.30 uur - 10.00 uur</option>
                                <option value="3">10.00 uur - 10.30 uur</option>
                                <option value="4">10.30 uur - 11.00 uur</option>
                            </select>
                            <br /><br />
                            <span>
                                <ul className="total-breakfast">
                                    <li>Prijs per stuk <span>€ {parseFloat(this.state.unitPrice).toFixed(2)}</span></li>
                                    <li>Aantal <span>{this.state.amount}</span></li>
                                    <li>Prijs per kids-ontbijt <span>€ {parseFloat(6.95).toFixed(2)}</span></li>
                                    <li>Aantal <span>{this.state.amountKids}</span></li>
                                    <li>Bezorgkosten <span>€ {this.state.shippingCost}</span></li>
                                    <hr />
                                    <li>Totaal <span>€ {parseFloat(((this.state.unitPrice * this.state.amount) + (6.95 * this.state.amountKids)) + this.state.shippingCost).toFixed(2)} <input type="text" name="totalPrice" value={parseFloat(((this.state.unitPrice * this.state.amount) + (6.95 * this.state.amountKids)) + this.state.shippingCost).toFixed(2).toString()} hidden /></span></li>
                                </ul>
                            </span>
                            <input type="text" name="shippingCost" value={this.state.shippingCost} hidden />
                            <input type="text" name="singlePrice" value={this.state.unitPrice} hidden />
                            <input type="text" name="bookingId" value={this.state.bookingID} hidden />
                            {this.state.distanceMessage ? <button type="submit" className="subtmit-button disabled-button" disabled>Betaal</button> : <button type="submit" className="subtmit-button" form="hook-form">Betaal</button>}
                        </form>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
            </div>
        );
    }
};

export default PayBreaffastOnlineScreenComponent;
