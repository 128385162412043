import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import RoomOverviewComponent from "../../components/Booking/RoomOverviewComponent";
import RoomDetailComponent from "../../components/Booking/RoomDetailComponent";
import HeaderView from "../Header";
import FooterView from "../Footer";


const RoomDetailView = (props) => (

  
  <Container fluid className="main-content-container sub-page">
    <HeaderView />

    <RoomDetailComponent data={props.location.state}/>
    <FooterView/>
    
  </Container>
);

export default RoomDetailView;
