import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { DateUtils } from 'react-day-picker';
import ReactStars from "react-rating-stars-component";

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

class TestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms:[
            ],
            facility:[],
            prices:[],
            images:[],
            eventDb:[],
            startdate:'',
            startDay: '',
            startMonth:'',
            startYear:'',
            selectedDay: undefined,
            enddate: '',
            endDay:'',
            endMonth:'',
            endYear:'',
            showSearch:true,
            errorMessage:''
        };
        this.handleDayChange = this.handleDayChange.bind(this);
    }

    writeAllDates(){
        console.log('lets go')
        for(let y = 2021; y< 2025;y++){ 
            for(let m = 0; m < 11; m++){
            for(let d = 1; d< 32;d++){
                    var newPostKey = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates/').push().key;
                    Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates/'+newPostKey).set({
                        amount: 0,
                        year:  y, 
                        id: newPostKey,
                        month:m,
                        day:d
                    }).then((data)=>{
                        console.log('done')
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                }
                }
            }
    }

    componentDidMount(){


        
        let rooms = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/');
    rooms.on('value' , snapshot => {
        this.setState({rooms:[],facility:[],prices:[],images:[]})
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
     
            this.setState(prevState => ({
               rooms: [...prevState.rooms, state],
             }));
             let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+state.id+'/facilities');
                facilities.on('value' , snapshot => {
            
            snapshot.forEach((childSnap) => {
            let stateFac = childSnap.val();
            
            let facility = {roomId:state.id, name:stateFac.name}
                this.setState(prevState => ({
                    facility: [...prevState.facility, facility],
                }));
                

        });
        
        })

        

        let guests = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+state.id+'/prices');
        guests.on('value' , snapshot => {
            
            snapshot.forEach((childSnap) => {
            let statePrice = childSnap.val();
            let newPrice = {roomId: state.id, id: statePrice.id, person: statePrice.person, price: statePrice.price}

                this.setState(prevState => ({
                    prices: [...prevState.prices, newPrice],
                }));
                

        });
        
        })

        let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+state.id+'/images');
        images.on('value' , snapshot => {
            
            snapshot.forEach((childSnap) => {
            let stateImage = childSnap.val();
            console.log("room is:"+JSON.stringify(state.id));
            console.log("room is:"+JSON.stringify(stateImage.url));

                    let newImages = {id:state.id, url:stateImage.url,type:stateImage.type}
                this.setState(prevState => ({
                    images: [...prevState.images, newImages],
                }));
                

        });
        
        })
      });
      
      
    })
    }

    setStartDate(date){
        console.log("whole date:"+date);
        console.log("day date:"+ new Date(date).getDate());
        console.log("motnth date:"+new Date(date).getMonth());
        console.log("year date:"+new Date(date).getFullYear());
        this.setState({
            startdate: date,
            startDay:new Date(date).getDate(),
            startMonth:new Date(date).getMonth(),
            startYear:new Date(date).getFullYear()
        })
    }

    componentWillUnmoun(){
        this.setState({
            startdate: '',
            startDay:'',
            startMonth:'',
            startYear:''
        })
    }

    handleDayChange(day) {
        this.setState({ selectedDay: day });
     
        this.setState({
            startdate: day,
            startDay:new Date(day).getDate(),
            startMonth:new Date(day).getMonth(),
            startYear:new Date(day).getFullYear()
        })
      }

      getDatesBetweenDates =(startDate, endDate) => {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
          dates = [...dates, new Date(theDate)]
          theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, endDate]
        return dates
      }

      handleDayChangeEnd(day) {
        this.setState({ selectedDay: day });

        this.setState({
            enddate: day,
            endDay:new Date(day).getDate(),
            endMonth:new Date(day).getMonth(),
            endYear:new Date(day).getFullYear()
        })

        console.log(new Date(day).getDate() )
        if(this.getDatesBetweenDates(this.state.startdate, day).length < 3 && new Date(day).getMonth() === 7){

        this.setState({
            errorMessage:'In deze periode moet je minimaal voor 2 nachten boeken.',
            showSearch:false
        })
        }else if(this.getDatesBetweenDates(this.state.startdate, day).length < 3 && new Date(day).getMonth() === 6){
            this.setState({
                errorMessage:'In deze periode moet je minimaal voor 2 nachten boeken.',
                showSearch:false
            })
        }
        else if(this.getDatesBetweenDates(this.state.startdate, day).length < 3 && new Date(day).getMonth() === 8 && new Date(this.state.startdate).getDate() === 1){
            this.setState({
                errorMessage:'In deze periode moet je minimaal voor 2 nachten boeken.',
                showSearch:false
            })
        }
        else if(this.getDatesBetweenDates(this.state.startdate, day).length < 3 && new Date(day).getMonth() === 8 && new Date(this.state.startdate).getDate() === 2){
            this.setState({
                errorMessage:'In deze periode moet je minimaal voor 2 nachten boeken.',
                showSearch:false
            })
        }
        else if(this.getDatesBetweenDates(this.state.startdate, day).length < 3 && new Date(day).getMonth() === 8 && new Date(this.state.startdate).getDate() === 3){
            this.setState({
                errorMessage:'In deze periode moet je minimaal voor 2 nachten boeken.',
                showSearch:false
            })
        }
        else{
            this.setState({
                errorMessage:'',
                showSearch:true
            })
        }
     
        console.log(day)
      }

      parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
          return parsed;
        }
        return undefined;
      }

      formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
      }
    render() {
        const { selectedDay } = this.state;
        const FORMAT = 'dd/MM/yyyy';
        return (
           
            <div className="home-page">
            <Row className="col-sm-12 home-banner">
                         <Container>
                <Col sm={12} className="intro-text">
                    <h2>B&B BIE JANSSEN - Voel je thuis</h2>
                    <p>In de mooie oude kern van Elsloo ligt onze gezellige en sfeervolle B&B in een statig huis met veel karakter. De B&B omschrijft zich het beste als een klein hotel met de gastvrijheid van een B&B. Er zijn 8 kamers en 1 familiekamer waar je je snel thuis zult voelen. Boek nu je kamer of kijk hier voor het kameroverzicht.</p>
                   
                </Col>
       

                <Col sm={12} className="search-col">
                    <Row className="search-row">
                        <Col sm={3} className="search-col-small"><h4>Incheckdatum</h4> <DayPickerInput
      formatDate={this.formatDate}
      format={FORMAT}
      parseDate={this.parseDate}
      placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
      onDayChange={this.handleDayChange} 
    /></Col>
                        <Col sm={3} className="search-col-small between"><h4>Uitcheckdatum</h4>
                        <DayPickerInput
      formatDate={this.formatDate}
      format={FORMAT}
      parseDate={this.parseDate}
      placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
      onDayChange={(event)=>this.handleDayChangeEnd(event)} 
    />
                        </Col>
                        <Col sm={3} className="search-col-small between"><h4>Gasten</h4><select value={this.state.amountPeople} onChange={(event)=>this.setState({amountPeople: event.target.value})}><option selected="true" disabled="disabled">Aantal gasten</option>    <option value={1}>1 Persoon</option> <option value={2}>2 Personen</option><option value={3}>3 Personen</option></select></Col>
                        <Col sm={3} className="search-col-small  button-search">{this.state.showSearch ?<Link className="search-button" to={{pathname: `/zoeken`,state: {startDay:this.state.startDay, startMonth: this.state.startMonth,startYear: this.state.startYear,endDay:this.state.endDay, endMonth: this.state.endMonth,endYear: this.state.endYear, amountPersons: this.state.amountPeople,startdate: this.state.startdate,enddate: this.state.enddate}}}>Zoeken</Link>: <div className="error-home">{this.state.errorMessage}</div>}</Col>

                    </Row>
                </Col>
                </Container>
 
                
   
            </Row>
             <Container >
          <Row className="col-sm-12">
                <Col sm={12} className="about-us-intro">
                <h2>Vele extra's</h2>
                </Col>

                <Col sm={6} className="about-us-image">
                {/*<img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2Fextras%2Fvrienden-biejanssen.jpg?alt=media&token=ae2bcf8d-a1fe-4e8b-b0dc-7166162db88b"}/>*/}
                <a class="visitzl-embed" href="https://www.visitzuidlimburg.nl/embed/?center=50.9467513,5.7645221|20&tips=true&cat=2,4,5,6" target="_blank"><img src="https://www.visitzuidlimburg.nl/assets/3.0/images/banners/300x250/Bezin.png" /><script type="text/javascript" src="https://www.visitzuidlimburg.nl/assets/3.0/js/embed.js"></script></a>
                    
                </Col>
                <Col sm={6} className="about-us-text">
                    <h2>Vrienden Bie Janssen</h2>
                    <p>Ondernemen doe je samen. Daarom heeft B&B Bie Janssen super leuke kortingen kunnen regelen bij ondernemers uit ons dorp of gemeente Stein. Je koopt de Vrienden van Bie Janssen kaart voor slechts € 8,95 en je krijgt er kortingen ter waarde van ruim € 50 voor terug bij meerdere restaurants, de kledingwinkel, het pretpark, de ijssalon of de bakkerij. Vergeet zeker niet het gratis borrelplankje bij ons zelf te bestellen. Vraag de kaart nu aan via <a href="mailto:info@biejanssen.nl">mail</a> of bel: 06-25226150.</p>
                    <Link className="frontend-button-dark" to={{pathname: `/extra`}} >Bekijk alle extra's</Link>
                </Col>
                
   
        </Row>
            </Container>
            <Container fluid className="min-margin">
            <Row className="rooms-home">
                <Col sm={12}>
                <h2>Kameroverzicht</h2>
                <p className="light-text">Wat fijn dat je overweegt een verblijf in onze familiair gerunde B&B te boeken. De kamers zijn comfortabele en sfeervol ingericht en hebben allen een eigen douche en toilet. Na een heerlijk nachtje slapen in de boxspring bedden, schuif je aan voor een lekker ontbijt. Ook ’s avonds kun je genieten van een borrelhapje en drankje in onze verblijfsruimte of op ons terras. Overtuigd? Boek dan snel hieronder een kamer.</p>
                    

                </Col>
                <Container>
                    <Row >
                    {this.state.rooms.sort((a, b) => a.sort - b.sort).map((item, i)=>(
                        <div className="room-overview-home">
                            {this.state.images.filter(image => item.id == image.id && image.type == 'active').slice(0, 1).map((image)=>(
                                        <img src={image.url} />
                                    ))}
                                    <h2>{item.name}</h2>
                                    <p className="amount-people"><img alt='Het logo van Picoo' src={require('../../assets/images/icons/person-icon.png')}/>Max. {this.state.prices.sort((a, b) => (a.person > b.person) ? 1 : -1).filter(guests => item.id == guests.roomId).slice(-1).map((item,i)=>(item.person))} Personen</p>
                                   {/* <ul>
      
                                        {  this.state.facility.filter(facility => item.id == facility.roomId).slice(0, 5).map((facility,i)=>(
                                                < li><i class="fas fa-check-circle"></i> {facility.name}</li>
                                        ))}
                                            
                                        </ul>*/}
                                        <p className="home-from">Vanaf</p>
                <h3>
                    € {parseFloat(this.state.prices.filter(guests => item.id == guests.roomId).slice(0,1).map((item,i)=>(item.price))).toFixed(2)}
                </h3>
                <p className="home-from">per nacht</p>
           
                <Link className="room-button" to={{pathname: `/kamer-overzicht/details`,state: {roomName:item}}} >Toon beschikbaarheid</Link>
                        </div>

                    ))}
                    </Row>

                </Container>
                
            </Row>
            </Container>
            <Container>
            <Row >
                <Col sm={12} className="facilities-home">
                    <h2>Faciliteiten</h2>
                    <h4>Alles tot in de puntjes verzorgd</h4>
                </Col>
                <Col sm={6} className="facilities-home padding-six-left">
                    <i class="custom fa fa-bicycle"></i>
                    <h5>Fietsen </h5>
                    <p>
                    We hebben 2 elektrische fietsen te huur. Voor slechts € 25 per dag per fiets ontdek je de mooie en verrassende omgeving van Elsloo. Mochten onze fietsen al verhuurd zijn, dan bevelen we graag <a href="https://alexvanoeveren.nl/fietsverhuur/" target="_blank">Fietsspeciaalzaak Alex van Oeveren</a> bij ons in de buurt aan. <br/><br/>Voor al ons fietsend publiek hebben wij in ieder geval een eigen afgesloten fietsenstalling in de B&B. Kosteloos, maar wel op eigen risico. Het is ook mogelijk om je elektrische fiets op te laden tijdens het verblijf of tijdens het nuttigen van een drankje op ons terras.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-right">
                    <i class="custom fa fa-coffee"></i>
                    <h5>Koffie & vlaai</h5>
                    <p>
                    Geniet buiten op het terras, of binnen in de verblijfsruimte van een kop koffie/thee met een heerlijk stuk vlaai. Buiten de reguliere dranken serveren wij ook verschillende speciaalbieren. Vraag de gastheer naar de keuzes die wij in huis hebben!
                    </p>
                </Col>

       
                <Col sm={6} className="facilities-home padding-six-left">
                    <i class="custom fa fa-home"></i>
                    <h5>In-/Uitchecken</h5>
                    <p>
                    Je kunt iedere dag van de week bij ons terecht voor een overnachting. We staan vanaf 15 uur voor je klaar. Je kunt tot 18 uur inchecken (woensdag en zondag van 19 uur tot 21 uur). Andere tijden zijn alleen in goed overleg mogelijk. Het uitchecken is op de dag van vertrek om 11.00 uur. Uiteraard kun je de bagage zolang bij ons opslaan voor als je besluit om nog een dag de prachtige natuur van Elsloo in te trekken.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-right">
                    <i class="custom fa fa-binoculars"></i>
                    <h5>Vele bezienswaardigheden in de omgeving</h5>
                    <p>
                    Gelegen in Elsloo heeft B&B Bie Janssen het geluk zich in het middelpunt te bevinden van een stukje prachtige historie. Midden in het oude centrum van Elsloo vind je onze B&B, vanuit hier kun je allerlei leuke tochten starten langs verschillende bezienswaardigheden.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-left">
                    <i class="custom fa fa-heart"></i>
                    <h5>Gezelligheid</h5>
                    <p>
                    Kom je samen met de kids? Of hou je gewoon van een bordspel? In onze ontbijtruimte liggen verschillende spellen klaar waar iedere gast gebruik van mag maken!
                    </p>
                </Col>

    

            </Row>
            <Row className="about-us-home-row" >
                <Col sm={12} className="about-us-home">
                    <h2>Wat anderen ervaarden</h2>
                    <p>Hieronder tref je een aantal reviews aan van onze gasten op Google Review. Wil je nog meer reviews zien, klik dan <a target="_blank" href="https://www.google.com/travel/hotels/entity/CgsI0YHhoKWn07ObARAB/reviews?g2lb=2502548%2C2503780%2C4258168%2C4270442%2C4306835%2C4317915%2C4328159%2C4371335%2C4401769%2C4419364%2C4482438%2C4486153%2C4491350%2C4509341%2C4515404%2C4517258%2C4523593%2C4530345%2C4533881%2C4536454%2C4270859%2C4284970%2C4291517&hl=nl-NL&gl=nl&ssta=1&rp=ENGB4aClp9OzmwEQ0YHhoKWn07ObATgCQABIAcABAg&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwioyr-olYHwAhUAAAAAHQAAAAAQDQ&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0N2MwYzFjZjgyMDJmYTViOjB4OWI2NzRkM2E1NDE4NDBkMRoAEhoSFAoHCOUPEAQYEhIHCOUPEAQYExgBMgIQACoJCgU6A0VVUhoA">hier</a></p>
                </Col>
               
                    <Col sm={6}>
                    <Row>
                        
                        <Col sm={4}>
                        <h5>Toet Ontwerp</h5>
                        </Col>
                        <Col sm={8}>
                        <h3> <ReactStars
    count={5}
    value={5}
    edit={false}
    size={24}
    activeColor="#ffd700"
  /></h3>
                        </Col>
                        <Col sm={4}>

                        </Col>
                        <Col sm={8}>
                        <p>Peter, Sabrina en kids van B&B Bie Janssen dank voor de fijne ontvangst afgelopen weekend. Fijn te zien, dat met de nodige maatregelen, men in Corona tijd gewoon welkom is bij jullie. Aanrader!</p>
                        </Col>
                    </Row>
                    </Col>
                    <Col sm={6}>
                    <Row>
                        <Col sm={4}>
                        <h5>Rob Uylings</h5>
                        </Col>
                        <Col sm={8}>
                        <h3><ReactStars
    count={5}
    value={4}
    edit={false}
    size={24}
    activeColor="#ffd700"
  /></h3>
                        </Col>
                        <Col sm={4}>

                        </Col>
                        <Col sm={8}>
                        <p>Een heerlijk weekendje, mooie mensen, mooie omgeving, mooie B@B. Genoten!</p>
                        </Col>
                    </Row>
                    </Col>
                    <Col sm={6}>
                    <Row>
                        <Col sm={4}>
                        <h5>Dorien Vd Velden</h5>
                        </Col>
                        <Col sm={8}>
                        <h3><ReactStars
    count={5}
    value={4}
    edit={false}
    size={24}
    activeColor="#ffd700"
  /></h3>
                        </Col>
                        <Col sm={4}>

                        </Col>
                        <Col sm={8}>
                        <p>Bie Janssen, Peter en Sabrina, zijn fantastische gastvrije mensen. Niets was teveel, speciaal voor ons geitenkaas en gewone yoghurt geregeld. Ze hebben 2 gezellige kinderen en lieve hond. Prachtige omgeving om te fietsen of wandelen. Aanrader.</p>
                        </Col>
                    </Row>
                    </Col>
                    <Col sm={6}>
                    <Row>
                        <Col sm={4}>
                        <h5>Gerard Ville</h5>
                        </Col>
                        <Col sm={8}>
                        <h3><ReactStars
    count={5}
    value={5}
    edit={false}
    size={24}
    activeColor="#ffd700"
  /></h3>
                        </Col>
                        <Col sm={4}>

                        </Col>
                        <Col sm={8}>
                        <p>Peter en Sabrina, hartelijk bedankt voor dagen die ik heb doorgebracht bij jullie. Een b en b die vanuit jullie hart wordt gerund.Super gastvrij gezin....waar je gelijk in opgenomen wordt. Top locatie om van alles te kunnen doen in de omgeving. Bos wandeling, fietsen langs de Maas en de heuvelachtige streek.
Groet Gerard</p>
                        </Col>
                    
                    </Row>
             </Col>
                    
                    
                  
                   
               

            </Row>
            </Container>
            </div>
   


        );
    }
};

export default TestComponent;