import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import BookingCalendar from 'react-booking-calendar';




class BookingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookings : [
                new Date(2021, 10, 2),
                new Date(2021, 2, 3),
                new Date(2021, 2, 4),
                new Date(2021, 2, 8),
                new Date(2021, 2, 9),
                new Date(2021, 2, 10),
                new Date(2021, 2, 12),
              ]
        };
    }

   bookings = [
        new Date(2021, 1, 2),
        new Date(2021, 1, 3),
        new Date(2021, 1, 4),
        new Date(2021, 1, 8),
        new Date(2021, 1, 9),
        new Date(2021, 1, 10),
        new Date(2021, 1, 12),
      ];

   


    render() {  

        
        return (
            <div>

                <Row >
                    <Col md={6}></Col>
                    <Col md={6}>
                    <BookingCalendar bookings={this.bookings} clickable={true} />
                    </Col>
                
                </Row>
                
            
            
            </div>
        );
    }
};

export default BookingComponent;
