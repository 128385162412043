import React from "react";
import { Container, Row, Col } from "shards-react";
import ClientDetailComponent from "../../components/Clients/ClientDetailComponent";

import PageTitle from "../../components/common/PageTitle";



const ClientDetailView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Klanten - Details" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <ClientDetailComponent clientData={props.location.state}/>
  </Container>
);

export default ClientDetailView;
