import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button,Container } from "shards-react";


class KeyOfAelseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
       
        return (
         
            <div className="home-page extra-margin-bottom">
                 <Row className="surroundings-row">
                <Col sm={12} className="surroundings-colum">
                    <h2>De sleutel van Elsloo</h2>
                   
                    
                </Col>
            </Row>


            <Container>
            <Row className="location-row">
            <Col sm={4} className="location-colom key-aelse">
                    <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2FVrouw%20met%20krant.jpg?alt=media&token=d6e10110-d5cc-44b3-8c3e-6c7359656d6a"}/>
                </Col>
                <Col sm={8} className="location-colom">
                <h5></h5>
                    <h2>De sleutel van Elsloo </h2>
                    <p>
                    De Sleutel van Elsloo is een spannende tocht door de historische kern van Elsloo in de stijl van een Escaperoom, waarbij je vertrekt vanuit B&B Bie Janssen of Het Slimme Schaap. Tijdens deze activiteit ga je op zoek naar de code van een kluis waarin De Sleutel van Elsloo ligt. Een journalist heeft een krant samengesteld met 5 opdrachten. Los je die 5 opdrachten op dan leidt dit tot de code om de kluis te openen en zo kun je de sleutel met eigen ogen zien en vasthouden.<br/><br/>
Kun je goed tellen, speuren en puzzelen: ga dan deze uitdaging aan. Boek deze activiteit tijdens je verblijf bij ons of kom met vrienden, familie of collega´s naar Elsloo en kraak de code. De tocht is geschikt voor jong en oud, in kleine en grote groepen, maar minder geschikt als je slecht ter been bent of met kinderwagens. Ben je met een groep van meer dan 6 personen, dan adviseren we je de groep in 2 te splitsen.<br/><br/>
<br/><br/>
De kosten van de tocht zijn:
<ul>
    <li>Volwassenen € 13,50 per persoon</li>
    <li>Kinderen 6 t/m 12 jaar € 8,50 per persoon</li>
    <li>Kinderen tot 6 jaar GRATIS</li>
    <li>Op alle dagen van de week kan gestart worden</li>
    <li>Starten kan vanaf 10.00 uur, laatste starttijd 15:30 uur. De activiteit duurt ongeveer 2,5 uur.</li>
</ul>

Aanmelden voor De Sleutel van Elsloo gaat via Visit Zuid-Limburg (Tickli)?
<a target="_blank" href="https://shop.tickli.nl/event/deSleutelvanElsloo?channel=BieJanssen"> Klik hier</a>
<br/><br/>
De sleutel van Elsloo is tot stand gekomen door een samenwerking van Toeristische Werkgroep Maaskentj, gemeente Stein, Visit Zuid-Limburg en Buiten Zinnen.


                    </p>
                   {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                </Col>
                
            </Row>
            
            
            </Container>
            </div>

          

        );
    }
};

export default KeyOfAelseComponent;