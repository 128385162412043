import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import exportFromJSON from 'export-from-json'
import DeleteIcon from "../../assets/images/svg/times-solid.svg"
import InvoiceIcon from "../../assets/images/svg/invoice.svg"
import { Link } from 'react-router-dom';
import { Page, Image, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo-janssen.png'
import { Details } from '@material-ui/icons';
import Firebase from 'firebase';

const HeadInvoice = () => {
    return <View style={styles.page}>
        <View style={styles.section}>
            <Text>Factuur</Text>
        </View>
        <View style={styles.section}>
            <Image style={styles.image} src={Logo} />
        </View>
    </View>
}

const ToInvoice = ({ data }) => {
    return <View style={styles.page}>
        <View style={styles.sectionfull}>
            <Text>{data.name}</Text>
            {/*<Text>De Haamen 1</Text>
             <Text>6191HV, Beek</Text>*/}
        </View>
    </View>
}


const InformationInvoice = ({ data }) => {
    return <View style={styles.pageText}>
        <View style={styles.sectionText}>
            <Text>Tel: {data.phone}</Text>
            <Text>mail: {data.email}</Text>
        </View>
        <View style={[styles.sectionText, styles.textRight]}>
            <Text>Onze ref: {data.bookingnsnumber}</Text>
            <Text>Contactpersoon: Peter Janssen</Text>
            <Text>Mail: info@biejanssen.nl</Text>
        </View>
    </View>
}

const DateInvoice = ({ data }) => {
    return <View style={styles.pageTextReg}>
        <View style={styles.sectionTextReg}>
            <Text>Datum: {data.payDate}</Text>
            <Text>Betreft: {data.desc}</Text>
        </View>
    </View>
}

const HrLineTop = () => {
    return <View style={styles.hrLineTop}></View>
}

const HrLine = () => {
    return <View style={styles.hrLine}></View>
}

const TableInvoice = ({ data }) => {
    console.log("achten:" + data.totalNights);
    return <View style={styles.table}>
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Betreft</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Aantal personen</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Aantal nachten</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Per stuk</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Totaal</Text>
            </View>
        </View>
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Overnachting</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.persons}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.totalNights} </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>€ {parseFloat(data.singlePrice).toFixed(2)}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>€ {parseFloat((data.singlePrice * data.totalNights)).toFixed(2)}</Text>
            </View>
        </View>
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Toeristenbelasting</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.persons}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.totalNights} </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>€ {parseFloat(data.singlePrice).toFixed(2)}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>€ {parseFloat((data.singlePrice * data.totalNights)).toFixed(2)}</Text>
            </View>
        </View>
    </View>
}

const TextInvoice = () => {
    return <View style={styles.pageTextReg}>
        <View style={styles.sectionTextReg}>
            <Text>Heeft u verder nog vragen, neem dan direct contact met ons op via:</Text>
            {/* <Text>Gelieve bovenstaand bedrag onder vermelding van het factuurnummer binnen 14 dagen na factuurdatum te betalen op IBAN-nummer: NL98 INGB 0009 543250 t.n.v. PixelPro’s V.O.F.</Text>*/}
        </View>
    </View>
}

const FooterInvoice = () => {
    return <View style={styles.pageFooter}>
        <View style={styles.sectionThree}>
            <Text style={styles.footerTextBlue}>een onderneming waar {"\n"}innovatie en maatwerk {"\n"}centraal staan</Text>
        </View>
        <View style={styles.sectionThree}>
            <Text style={styles.footerTextGrey}>Spechthof 7, 6181 KK, Elsloo {"\n"}+31 6 117 346 02{"\n"}info@pixelpros.nl</Text>
        </View>
        <View style={styles.sectionThree}>
            <Text style={styles.footerTextGrey}><Text style={styles.footerTextBlue}>kvk</Text> 75729474{"\n"}<Text style={styles.footerTextBlue}>iban</Text> NL98 INGB 0009 5432 50{"\n"}<Text style={styles.footerTextBlue}>btw</Text> NL860377003B01{"\n"}</Text>
        </View>
    </View>
}




const MyDoc = (item) => (

    <Document>
        <Page size="A4" style={styles.pageFull}>

            <HeadInvoice />
            <HrLineTop />
            <ToInvoice data={{ name: item.billData.title }} />
            <InformationInvoice data={{ bookingnsnumber: item.billData.bookingsnumber, phone: item.billData.phone, email: item.billData.email }} />
            <DateInvoice data={{ payDate: item.billData.payDate, desc: 'overnachting' }} />
            <TableInvoice data={{ persons: item.billData.persons, desc: 'overnachting', totalNights: item.billData.totalNights, singlePrice: item.billData.singlePrice }} />
            <HrLine />
            <TextInvoice />
            <FooterInvoice />

        </Page>
    </Document>
)



class BillsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bills: [


            ],
            switch: true,
            orders: [],
            payments: [],
            invoices: []

        };
    }


    componentWillMount() {
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events/');
        ref.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                this.setState(prevState => ({
                    payments: [...prevState.payments, state]
                }))
            });

        })

        let orders = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/orders/');
        orders.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                this.setState(prevState => ({
                    orders: [...prevState.orders, state]
                }))
            });

        })

        let invoices = Firebase.database().ref('/clients/-uhfwoebfwioudb/invoices/');
        invoices.on('value', snapshot => {
            this.setState({ invoices: [] })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                Firebase.storage().ref(state.filePath).getDownloadURL().then((url) => {
                    console.log(url)
                    console.log(state)
                    let newInvoice = { bookingId: state.bookingId, url: url };

                    this.setState(prevState => ({
                        invoices: [...prevState.invoices, newInvoice]
                    }))


                })






            });

        })

    }

    renderPayment() {
        let paymentArray = [];
        for (let i = 0; i < this.state.payments.length; i++) {
            let newItem = { boekingsnummer: this.state.payments[i].id, naam: this.state.payments[i].title, aantalPersonen: this.state.payments[i].persons, datumIn: this.state.payments[i].startDay + "-" + (Number(this.state.payments[i].startMonth) + 1) + "-" + this.state.payments[i].startYear, email: this.state.payments[i].email, prijsPerNacht: this.state.payments[i].singlePrice, totaalNachten: this.state.payments[i].totalNights, totaal: ((Number(this.state.payments[i].singlePrice * this.state.payments[i].totalNights)) + (((Number(this.state.payments[i].totalNights) * Number(this.state.payments[i].persons)) * 2))) }

            paymentArray.push(newItem)

        }

        return paymentArray;
    }

    renderBreakfast() {
        let orderArray = [];
        for (let i = 0; i < this.state.orders.length; i++) {
            let newItem = { order: this.state.orders[i].id, naam: this.state.orders[i].name, aantalPersonen: this.state.orders[i].amount, datum: this.state.orders[i].date, email: this.state.orders[i].email, prijsPerOnbijt: this.state.orders[i].unitPrice, verzendKosten: this.state.orders[i].shippingCost, totaal: parseFloat(((Number(this.state.orders[i].unitPrice * this.state.orders[i].amount)) + (Number(this.state.orders[i].shippingCost)))).toFixed(2) }

            orderArray.push(newItem)

        }

        return orderArray;
    }

    exportBookings() {

        const data = this.renderPayment();

        const fileName = 'download'
        const exportType = 'xls'

        exportFromJSON({ data: JSON.stringify(data), fileName: 'Boekingen', exportType: exportFromJSON.types.xls })
    }

    exportBreakfast() {

        const data = this.renderBreakfast();

        const fileName = 'download'
        const exportType = 'xls'

        exportFromJSON({ data: JSON.stringify(data), fileName: 'Ontbijtjes', exportType: exportFromJSON.types.xls })
    }




    render() {


        return (
            <div>
                <Row>
                    <Col sm={4} className="switch">
                        <Button onClick={() => this.setState({ switch: true })}>Overnachtingen</Button>
                        <Button onClick={() => this.setState({ switch: false })}>Onbijtjes</Button>
                    </Col>
                </Row>
                {this.state.switch ?
                    <Row >
                        <Col sm={12} className="bills-component">
                            <Button onClick={() => this.exportBookings()}>Export Boekingen</Button>

                            <table className="bills-table">
                                <tr>
                                    <th>Boekingsnummer</th>
                                    <th>Persoon</th>
                                    <th>Is betaald</th>
                                    <th>Betaald</th>
                                    <th>acties</th>
                                </tr>
                                {this.state.payments.map((item, i) => (
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.ispayed ? <img className="icon-calendar" src={require(`../../assets/images/svg/payed.svg`)} /> : <img className="icon-calendar" src={require(`../../assets/images/svg/warning-icon.svg`)} />}</td>
                                        <td>€ {parseFloat((item.singlePrice * item.totalNights) + (2.25 * item.persons * item.totalNights)).toFixed(2)}</td>
                                        <td>    {this.state.invoices.filter(invoice => item.id == invoice.bookingId).map((invoice) => (
                                            <a href={invoice.url} target="_blank">Factuur</a>
                                        ))}<Link to={{ pathname: `/rekeningen/details`, state: { billData: item } }} className="table-link">Bekijk</Link></td>
                                    </tr>
                                ))}
                            </table>
                        </Col>
                        <Col sm={12} className="black-space">

                        </Col>
                    </Row>
                    :
                    <Row >
                        <Col sm={12} className="bills-component">
                            <Button onClick={() => this.exportBreakfast()}>Export Ontbijtjes</Button>

                            <table className="bills-table">
                                <tr>
                                    <th>Order nummer</th>
                                    <th>Persoon</th>
                                    <th>Is betaald</th>
                                    <th>Datum leveren</th>
                                    <th>Acties</th>
                                </tr>
                                {this.state.orders.map((item, i) => (
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.ispayed ? <img className="icon-calendar" src={require(`../../assets/images/svg/payed.svg`)} /> : <img className="icon-calendar" src={require(`../../assets/images/svg/warning-icon.svg`)} />}</td>
                                        <td>{item.date}</td>
                                        <td> {this.state.invoices.filter(invoice => item.id == invoice.bookingId).map((invoice) => (
                                            <a href={invoice.url} target="_blank">Factuur</a>
                                        ))}<Link to={{ pathname: `/rekeningen/details-order`, state: { billData: item } }} className="table-link">Bekijk</Link></td>
                                    </tr>
                                ))}
                            </table>
                        </Col>
                        <Col sm={12} className="black-space">

                        </Col>
                    </Row>
                }



            </div>
        );
    }
};

const styles = StyleSheet.create({
    pageFull: {
        padding: 40,
    },
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        marginBottom: '-10%',
        paddingBottom: '-10%',
        width: '100%',
        position: 'relative'
    },
    pageFooter: {
        flexDirection: 'row',
        backgroundColor: 'white',

        width: '100%',
        position: 'relative',
        bottom: 0,
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: "flex-end"
    },
    pageText: {
        flexDirection: 'row',
        backgroundColor: 'white',
        marginTop: -80
    },
    pageTextReg: {
        flexDirection: 'row',
        backgroundColor: 'white',
        marginTop: 20
    },
    textRight: {
        marginLeft: '40%'
    },
    sectionText: {
        fontSize: 12
    },
    sectionTextReg: {
        fontSize: 12
    },
    section: {

        width: '50%',
        textAlign: 'left',


    },
    sectionThree: {

        width: '33%',
        textAlign: 'left',


    },
    sectionfull: {
        float: 'left',
        width: '100%',
        textAlign: 'left',
        marginTop: 20,
        fontSize: 12
    },
    image: {
        marginLeft: '40%',
        width: 120,
        height: 30
    },
    line: {
        marginTop: 10,
        borderTopWidth: 2,
        borderTopStyle: 'solid',
        borderTopColor: 'black',
        flexBasis: '100%'
    }, hrLineTop: {
        padding: 5,
        width: '100%',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: 'black',
        marginTop: -90
    },
    hrLine: {
        marginTop: 20,
        padding: 5,
        width: '100%',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: 'black'
    },
    hrTop: {
        marginTop: -90
    },
    table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, marginTop: "5%" },
    tableRow: { margin: "auto", flexDirection: "row", textAlign: 'left', alignItems: 'left' },
    tableCol: { width: "20%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, textAlign: 'left' },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 10, textAlign: 'left', float: 'left' },
    footerTextBlue: {
        fontSize: 12,
        color: "blue"
    },
    footerTextGrey: {
        fontSize: 12,
        color: "grey"
    },

});

export default BillsComponent;
