import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import BookingComponent from "../../components/Booking/BookingComponent";
import HeaderComponent from "../../components/Header/headerComponent";


const HeaderView = (props) => (

  
    <HeaderComponent />
);

export default HeaderView;
