import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button, NavItem } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import uuid from 'react-uuid';
import Calendar from 'react-calendar';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import { ThreeSixty, TransferWithinAStation } from '@material-ui/icons';


class AddRoomComponentStepOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            desc:'',
            facilities:[],
            newFacility:'',
            newDbKey:'',
            prices:[],
            stock:0,
            newPerson:0,
            newPrice:0,
            rooms:[]
        };
    }

    componentDidMount(){

        let rooms = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/');
        rooms.on('value', snapshot => {
            this.setState({rooms:[]})
              snapshot.forEach((childSnap)=>{
                let state = childSnap.val();
                  this.setState(prevState => ({
                    rooms: [...prevState.rooms, state],
  
                  } ));
              })
          })
        this.setState({
            newDbKey:Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/').push().key,
        })

    }

    addFacility(){
        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.state.newDbKey+'/facilities/').push().key;
        let newFacility = {id:key,name: this.state.newFacility}
        this.setState(prevState =>({
            facilities: [...prevState.facilities,newFacility],
            newFacility:''
        }))
    }

    deleteFacility(id){
        let value = id;
        let arr = this.state.facilities;
        arr = arr.filter(item => item.id !== value);
        this.setState({
            facilities: arr,
            newFacility:''
        })
    }

    addPrices(){
        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+this.state.newDbKey+'/prices/').push().key;
        let newPrices = {id:key,person: parseFloat(this.state.newPerson), price: parseFloat(this.state.newPrice)}
        this.setState(prevState =>({
            prices: [...prevState.prices,newPrices],
            newPrice:0,
            newPerson:0
        }))
    }

    deletePrices(id){
        let value = id;
        let arr = this.state.prices;
        arr = arr.filter(item => item.id !== value);
        this.setState({
            prices: arr,
            newPrice:0,
            newPerson:0
        })
    }

    createRoom(){

        console.log('added')

        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.state.newDbKey).set({
            id: this.state.newDbKey,
            btw: 9,
            desc: this.state.desc,
            name:this.state.name,
            facilities: this.state.facilities,
            prices: this.state.prices,
            stock: parseFloat(this.state.stock),
            resourceId: this.state.rooms.length+1
            
        }).then((data)=>{
           
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

    }

    render() {
     
        return (
            <Row>
                <Col lg="12" className="backend-form">
                   

                    <label>
                       Naam kamer:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <label>
                      Omschrijving:
                    </label>
                    <br/>
                    <textarea className="textarea-rooms" value={this.state.desc} onChange={event => this.setState({ desc: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <label>
                       Kamer voorraad:
                    </label>
                    <br/>
                    <input type="number" name="name-client" value={this.state.stock} onChange={event => this.setState({ stock: event.target.value, errorMessage:''})}/>
                    <br/> <br/>
                    <table className="add-facilitie-table"> 
                  <tr>
                    <th>Naam</th>
                    <th>Actie</th>
                  </tr>
                  {this.state.facilities.map((facility)=>(
                   <tr>
                   <td>{facility.name}</td>
                   <td><Button onClick={()=>this.deleteFacility(facility.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="text" value={this.state.newFacility} onChange={(event)=>this.setState({newFacility: event.target.value})}/></td>
                <td><Button onClick={()=>this.addFacility()}>Voeg toe</Button></td>
            </tr>
                
                  </table><br/><br/>

                  <table className="add-facilitie-table"> 
                  <tr>
                    <th>Aantal personen</th>
                    <th>Prijs</th>
                    <th>Actie</th>
                  </tr>
                  {this.state.prices.map((price)=>(
                   <tr>
                   <td>{price.person}</td>
                   <td>{price.price}</td>
                   <td><Button onClick={()=>this.deletePrices(price.id)}>Verwijder</Button></td>

                 </tr>
            ))}

            <tr>
                <td><input type="number" value={this.state.newPerson} onChange={(event)=>this.setState({newPerson: event.target.value})}/></td>
                <td><input type="number" value={this.state.newPrice} onChange={(event)=>this.setState({newPrice: event.target.value})}/></td>
                <td><Button onClick={()=>this.addPrices()}>Voeg toe</Button></td>
            </tr>
                
                  </table><br/><br/>


                
                    
                    <Link className="back-link" to={{pathname: `/instellingen`}} >Terug</Link>
                    <Button onClick={()=> this.createRoom()} className="button-create-appointment">Kamer aanmaken!</Button><br /> <br />
                </Col>
            </Row>



        );
    }
};

export default AddRoomComponentStepOne;