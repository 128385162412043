import React from 'react';
import { Col, Row } from 'shards-react';

class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    render() {

        return (
            <div>
                <Row className="footer-section ">
                    <Col sm={3}>
                        <h3>Contact</h3>
                        <ul>
                            <li>Raadhuisstraat 46, 6181 HB Elsloo</li>
                            <li>info@biejanssen.nl</li>
                            <li>+31 6 25 22 61 50</li>
                        </ul>
                    </Col>
                    <Col sm={3}>
                        <h3>B&B BIE JANSSEN - Voel je thuis</h3>
                        <p>
                            In de mooie oude kern van Elsloo ligt onze gezellige en sfeervolle B&B in een statig huis met veel karakter. De B&B omschrijft zich het beste als een klein hotel met de gastvrijheid van een B&B. We hebben 8 kamers en 1 familiekamer waar je je snel thuis voelt.
                        </p>

                    </Col>
                    <Col sm={3}>
                        <h3>SITEMAP</h3>
                        <ul className="footer-sitemap">
                            <li><a href="../home">Home</a></li>
                            <li><a href="../over-ons">Over ons</a></li>
                            <li><a href="../extras">Extra's</a></li>
                            <li><a href="../ontbijtbox">Verrassingsontbijtbox</a></li>
                            <li><a href="../contact">Contact</a></li>
                            <li><a href="../kamer-overzicht">Boek nu</a></li>
                        </ul>

                    </Col>
                    <Col sm={3}>
                        <h3>Algemeen</h3>
                        <ul className="footer-sitemap">
                            <li><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2FVoorwaarden%20en%20huisregels%20B%26B%20Bie%20Janssen%202024.pdf?alt=media&token=9e383693-79dd-494f-bda1-f4fea6e2c822">Algemene Voorwaarden</a></li>
                            <li>KVK-nummer: 74728180</li>
                            <li>BTW-nummer: NL860006517B01</li>
                            <li>IBAN-nummer:  NL48ABNA0844726621</li>

                        </ul>
                    </Col>
                    <Col sm={11} className="bottom-line">
                        <hr />
                        <p>Copyright © 2023 Copyright B&B Bie Janssen, website door PixelPro's</p>
                    </Col>
                </Row>



            </div>
        );
    }
}

export default FooterComponent;
