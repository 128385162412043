import React from 'react';
import {Col, Row} from 'shards-react';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    

    render() {

        return (
            <div>
                <Row className="header-section desktop-menu">
                 
                    <Col sm={5} className="menu-section">
                        <a href="../../home">Home</a>
                        <a href="../../over-ons">Over ons</a>
                        <a href="../../kamer-overzicht">Kamers</a>
                        <a href="../../zakelijk">Zakelijk</a>
                    
                    </Col>
                    <Col sm={2} className="logo-section">
                    <a href="../../"><img alt='Het logo van BieJanssen' src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flogo-janssen.svg?alt=media&token=35705076-f5ed-4af3-aa5f-654093f1a416"}/></a>
                    </Col>

                    <Col sm={5} className="menu-section right-menu-header">
                    <a href="../../sleutel-van-elsloo">Sleutel van Elsloo</a>
                    <a href="../../extra">Extra's</a>
                        <a href="../../ontbijtbox">Verrassingsontbijtbox</a>
                        <a href="../../contact">Contact</a>
                     
                    </Col>

                </Row>

                <Row className="mobile-menu">
                    <Col sm={12}>
                        <img alt='het logo van Picoo voor mobiel' src={require('../../assets/images/logo-janssen-white.png')} className="mobile-logo-menu"/>
                        <button className="ham" onClick={() => this.toggleHamburger()}></button>
                        <nav className="navbar-mobile">

                            <ul>
                                <li><a className="menuLink" href="../../home">Home</a></li>
                                <li><a className="menuLink" href="../../over-ons">Over ons</a></li>
                                <li><a className="menuLink" href="../../zakelijk">Zakelijk</a></li>
                                
                                <li><a className="menuLink" href="../../extra">Extra's</a></li>
                                <li><a className="menuLink" href="../../ontbijtbox">Verrasingsontbijtbox</a></li>
                                <li><a className="menuLink" href="../../contact">Contact</a></li>
                                <li><a className="menuLink" href="../../sleutel-van-elsloo">Sleutel van Elsloo</a></li>

                                <li><a href="../../kamer-overzicht" className="pre-order-item menuLink">Kamers</a></li>
                               
                            </ul>
                        </nav>
                    </Col>


        </Row>

            </div>
        );
    }
}

export default HeaderComponent;
