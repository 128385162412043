import React from "react";
import { Container, Row, Col } from "shards-react";


import PayBreakfastComponent from "../../components/Frontend/PayBreakfastComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const PayBreakfastView = ({match,location}) => (


  

  <Container fluid  className="sub-page">
 <HeaderView />
    <PayBreakfastComponent id={location.search}/>
    <FooterView />

  
  </Container>
)

export default PayBreakfastView;
