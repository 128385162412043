import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel, Select } from '@material-ui/core';

var nameTest='';

export default function AddressForm() {

  

  return (
    
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Gegevens
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Voornaam"
            fullWidth
            autoComplete="given-name"
            onChange={(event)=> localStorage.setItem('firstname', event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Achternaam"
            fullWidth
            autoComplete="family-name"
            onChange={(event)=> localStorage.setItem('lastname', event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email adres"
            fullWidth
            onChange={(event)=> localStorage.setItem('mail', event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Telefoonnummer"
            fullWidth
            onChange={(event)=> localStorage.setItem('phone', event.target.value)}
          />
        </Grid>
         <Grid item xs={8}>
          <TextField
            required
            id="adres"
            name="adres"
            label="Adres ontvanger"
            fullWidth
            onChange={(event)=> localStorage.setItem('adresReceiver', event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            id="postal"
            name="postal"
            label="Postcode ontvanger"
            fullWidth
            onChange={(event)=> localStorage.setItem('postalReceiver', event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="city"
            name="city"
            label="Woonplaats ontvanger"
            fullWidth
            onChange={(event)=> localStorage.setItem('cityReceiver', event.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="city"
            name="city"
            label="Woonplaats ontvanger"
            fullWidth
            onChange={(event)=> localStorage.setItem('cityReceiver', event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
         <InputLabel htmlFor="age-native-simple">Tijdslot van bezorging</InputLabel>
         <Select
           native
           value={localStorage.getItem('TimeSlot')}
           onChange={(event)=>localStorage.setItem('TimeSlot',event.target.value)}
           inputProps={{
             name: 'age',
             id: 'age-native-simple',
           }}
         >
           <option aria-label="None" value="" />
           <option value={10}>Ten</option>
           <option value={20}>Twenty</option>
           <option value={30}>Thirty</option>
         </Select>

        </Grid>

        
        {/*<Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Gebruik deze gegevens als factuurgegevens"
          />
  </Grid>*/}
      </Grid>
    </React.Fragment>
  );
}