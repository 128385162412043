import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "../src/assets/chashierr.css";
import "../src/assets/desktop-css.css";
import "../src/assets/notebook-css.css";
import "../src/assets/tablet-css.css";
import "../src/assets/mobile-css.css";
import LoginScreen from "./views/LoginScreen";
import PrivateRoute from "./privateRoute";
import PayBreakfastView from "./views/Frontend/PayBreakfastView";
import { Link } from "react-router-dom";
import PayBookingfastOnlineView from "./views/Frontend/PayBookingOnlineView";
import RoomSearchView from "./views/Frontend/RoomSearchView";
import HomeView from "./views/Frontend/HomeView";
import AboutUsView from "./views/Frontend/AboutUsView";
import SurroundingsView from "./views/Frontend/SurroundingsView";
import SurpriseboxView from "./views/Frontend/SurpriseboxView";
import ContactView from "./views/Frontend/ContactView";
import RoomOverviewView from "./views/Booking/RoomOverviewView";
import RoomDetailView from "./views/Booking/RoomDetailView";
import PayBookingOnlineView from "./views/Booking/PayBookingView";
import OnlineCheckoutBreakfastScreen from "./views/OnlineCheckoutBreakfastScreen";
import PaymentBreakfastEndPointView from "./views/PaymentEndpointView/PaymentBreakfastEndpointView";
import PaymentEndPointView from "./views/PaymentEndpointView/PaymentEndPointView";
import PaymentsBookingManView from "./views/PaymentEndpointView/PaymentsBookingManView";
import BookingView from "./views/Booking/BookingView";
import PayBreakfastOnlineView from "./views/Frontend/PayBreakfastOnlineView";
import ExtraView from "./views/Frontend/ExtraView";
import RoomDetailFamilyView from "./views/Booking/RoomDetailsFamilyRoom";
import PayFamilyBookingOnlineView from "./views/Booking/PayFamilyRoomView";
import PayBookingFamfastOnlineView from "./views/Frontend/PayBookingFamView";
import PaymentEndPointFamilyView from "./views/PaymentEndpointView/PaymentsEndPointFamilyView";
import TestView from "./views/Frontend/TestView";
import KeyOfAelseView from "./views/Frontend/KeyOfAelseView";
import BussinesView from "./views/Frontend/BussinesView";

export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Switch>

      <div>
        <Link to="/booking-betaling?id=:id" />
        <Route exact path='/booking-betaling' component={PayBookingfastOnlineView} />
        <Link to="/booking-betaling-familie?id=:id" />
        <Route exact path='/booking-betaling-familie' component={PayBookingFamfastOnlineView} />
        <Link to="/ontbijt-betaling?id=:id" />
        <Route exact path='/ontbijt-betaling' component={PayBreakfastView} />
        <Route exact path='/zoeken' component={RoomSearchView} />
        <Route path="/home" component={HomeView} />
        <Route path="/test-visit" component={TestView} />
        <Route path="/over-ons" component={AboutUsView} />
        <Route path="/omgeving" component={SurroundingsView} />
        <Route path="/zakelijk" component={BussinesView} />
        <Route path="/ontbijtbox" component={SurpriseboxView} />
        <Route path="/ontbijtbox-bestellen" component={PayBreakfastOnlineView} />
        <Route path="/contact" component={ContactView} />
        <Route path="/extra" component={ExtraView} />
        <Route exact path="/kamer-overzicht" component={RoomOverviewView} />
        <Route exact path="/kamer-overzicht/details" component={RoomDetailView} />
        <Route path="/kamer-overzicht/details/familie-kamer" component={RoomDetailFamilyView} />
        <Route path="/familie-kamer" component={RoomDetailFamilyView} />
        <Route exact path="/online-afrekenen" component={PayBookingOnlineView} />
        <Route exact path="/online-afrekenen-familie" component={PayFamilyBookingOnlineView} />
        <Route exact path="/online-afrekenen-ontbijt" component={OnlineCheckoutBreakfastScreen} />
        <Route exact path="/bedankt-ontbijt" component={PaymentBreakfastEndPointView} />
        <Route exact path="/bedankt" component={PaymentEndPointView} />
        <Route exact path="/bedankt-familie" component={PaymentEndPointFamilyView} />
        <Route exact path="/bedankt-booking" component={PaymentsBookingManView} />
        <Route exact path="/boeken" component={BookingView} />
        <Route exact path="/sleutel-van-elsloo" component={KeyOfAelseView} />
        <Route exact path="/inloggen" component={LoginScreen} />

        {/*<Route exact path="/login" component={LoginScreen}/>*/}
        {routes.map((route, index) => {
          return (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
      </div>
    </Switch>
  </Router>
);
