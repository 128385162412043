import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import uuid from 'react-uuid';
import Calendar from 'react-calendar';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { DateUtils } from 'react-day-picker';

class AddAppointment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            bookings: new Date(),
            treatments: [],
            clientName: '',
            disabledDates: [],
            treatment: '',
            timeTreatment: 0,
            error: false,
            titleTreatment: '',
            date: null,
            startHour: 0,
            startMinutes: 0,
            endHour: 0,
            endMinutes: 0,
            barber: 0,
            errorMessage: '',
            uploaded: false,
            clientPhone: '',
            clientEmail: '',
            dateEnd: '',
            dateStart: '',
            serouceisset: false,
            roomnumbers: [{ room: 1, text: "#1", title: 'Ruime kamer' }, { room: 2, text: "#2", title: 'Luxe werk' }, { room: 3, text: "#3", title: 'Knusse kamer' }, { room: 4, text: "#4", title: 'Knusse kamer' }, { room: 5, text: "#5", title: 'Knusse kamer' }, { room: 9, text: "#9", title: 'Luxe werk kamer' }, { room: 10, text: "#10", title: 'Knusse kamer' }, { room: 11, text: "#11", title: 'Ruime kamer' }, { room: 12, text: "#12", title: 'Knusse kamer' }],
            chosenRoom: 0,
            dates: [],
            alreadyExistDates: [],
            services: [],
            selected: [],
            resourceName: '',
            roomData: [],
            stock: 0,
            desc: '',
            resourceId: 0,
            options: [],
            facilities: [],
            images: [],
            errorMessage: '',
            alreadyExistDates: [],
            start: new Date(),
            end: new Date(),
            totalNights: 0,
            persons: 0,
            numberBooking: 0,
            eventDb: [],
            loadingData: false,
            amountPersons: 0,
            toeristenbelasting: 0,
            type: '',
            askPayment: false,
            isAlreadyPayed: '',
            minDates: [],
            fridays: [],
            mondays: [],
            monday: false,
            defaultPrice: 0,
            selectedDay: '',
            selectedType: '',
            disabledDays: [],
            isPayed: false

        };
        this.handleDayChange = this.handleDayChange.bind(this);
    }

    handleDayChange(day) {
        this.setState({ selectedDay: day });

        this.setState({
            startdate: day,
            startDay: new Date(day).getDate(),
            startMonth: new Date(day).getMonth(),
            startYear: new Date(day).getFullYear()
        })
    }

    bookingNumber = '';

    componentWillMount() {
        let fetchedItems = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates/');
        fetchedItems.on('value', snapshot => {

            snapshot.forEach((childSnap) => {

                let state = childSnap.val();
                this.setState(prevState => ({
                    disabledDays: [...prevState.disabledDays, state],
                }));

                console.log(state);
                console.log(new Date(state.year, state.month, state.day, 1, 0, 0, 0))
                this.setState(prevState => ({
                    fridays: [...prevState.fridays, new Date(state.year, state.month, state.day, 1, 0, 0, 0)],
                    mondays: [...prevState.mondays, new Date(state.year, state.month, state.day, 1, 0, 0, 0)]
                }))


            });

        })


        for (let i = 0; i < 1; i++) {
            setTimeout(() => {
                this.fetchData('Standaard kamer');
                console.log('hello timeout')
            }, 50);
        }


        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        ref.on('value', snapshot => {
            this.setState({ treatments: [] })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    treatments: [...prevState.treatments, state]

                }))
            })
        })

        this.setState({
            toeristenbelasting: 2.25

        })

        let familyRoom = { name: 'Familiekamer', resourceId: 4, btw: 9, id: '-iedklnedwbedpw', stock: 4 }
        this.setState(prevState => ({
            treatments: [...prevState.treatments, familyRoom]
        }))





    }

    handleRoomNumber(event) {
        this.setState({
            chosenRoom: event.target.value
        })

    }

    handleType(event) {
        this.setState({
            type: event
        })

        if (event === 'biejanssen.nl') {
            this.setState({
                askPayment: true
            })
        }
    }

    handleTypeAsk(event) {
        this.setState({
            isAlreadyPayed: event
        })

        if (event === 'ja') {
            this.setState({
                isPayed: true
            })
        } else if (event === 'nee') {
            this.setState({
                isPayed: false
            })
        }
    }

    handleTreatmentChange(event) {
        console.log(event.target.value)
        let refEvent = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');


        refEvent.on('value', snapshot => {
            this.setState({
                eventDb: []
            })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                console.log(state)
                this.setState(prevState => ({
                    eventDb: [...prevState.eventDb, state]
                }))

                console.log(state)

            })
            this.setState({
                numberBooking: (parseInt(this.state.eventDb.length) + 1),
            })






            // console.log("lengte is:"+this.generateBookingId())
        });
        this.setState({
            images: [], facilities: [], option: [], resourceId: 0, desc: '', stock: 0, roomData: [], resourceName: '', dates: [],
            alreadyExistDates: [],
            services: [],
            disabledDates: []
        })

        this.setState({ treatment: event.target.value, errorMessage: '' });

        for (var i = 0; i < this.state.treatments.length; i++) {
            if (this.state.treatments[i].resourceId == event.target.value) {
                console.log(this.state.treatments[i].name)

                this.setState({
                    resourceId: this.state.treatments[i].resourceId,
                    serouceisset: true,
                    nameResource: this.state.treatments[i].name,
                    id: this.state.treatments[i].id

                })


                console.log('name is:' + this.state.treatments[i].name)

                this.fetchData(this.state.treatments[i].name);


                //this.fetchOtherData(this.state.treatments[i].name);
                //this.fetchData(this.state.treatments[i].name)
            }
        }


    }

    RandomId(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    getDatesBetweenDates = (startDate, endDate) => {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
            dates = [...dates, new Date(theDate)]
            theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, endDate]
        return dates
    }

    fetchOtherData(name) {


        for (let i = 0; i < this.state.services.length; i++) {



            if (name === this.state.services[i].name) {
                /*let dates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.state.services[i].id+'/dates');
                dates.on('value' , snapshot => {
                    snapshot.forEach((childSnap) => {

                    let state = childSnap.val();
                    console.log(state)
                        
                  });
                })*/


                localStorage.setItem('serviceId', this.state.services[i].id)
                console.log('match')
                this.setState({
                    roomData: this.state.services[i],
                    stock: this.state.services[i].stock,
                    desc: this.state.services[i].desc,
                    resourceId: this.state.services[i].resourceId,
                })



                let roomItem = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/dates');
                roomItem.on('value', snapshot => {
                    this.setState({ disabledDates: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();

                        console.log('stock:' + this.state.services[i].stock)

                        if (state.amount > this.state.services[i].stock - 1) {

                            console.log('hello dus')
                            console.log('AA' + state.month)
                            console.log('AA' + state.day)

                            this.setState(prevState => ({
                                disabledDates: [...prevState.disabledDates, new Date(state.year, state.month, state.day)],
                            }));
                            console.log("not:" + new Date(state.year, state.month, state.day))
                            console.log("not:" + state.id)
                        }


                    });

                })

                let minDates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/minDates');
                minDates.on('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        let newMinDates = { date: new Date(state.year, state.month, state.day), amount: state.amount }
                        this.setState(prevState => ({
                            minDates: [...prevState.minDates, newMinDates],
                        }));

                    });

                })

                let prices = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/prices');
                prices.on('value', snapshot => {
                    this.setState({ options: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            options: [...prevState.options, state],
                        }));
                    });
                })

                let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/facilities');
                facilities.on('value', snapshot => {
                    this.setState({ facilities: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            facilities: [...prevState.facilities, state],
                        }));
                    });
                })

                let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/images');
                images.on('value', snapshot => {
                    this.setState({ images: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            images: [...prevState.images, state],
                        }));
                    });
                })

                this.setState({
                    loadingData: true
                })


            } else {
                console.log('unmatch')
            }
        }
    }

    fetchData(name) {


        this.setState({ disabledDates: [], })
        let fetchedItems = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        fetchedItems.on('value', snapshot => {

            snapshot.forEach((childSnap) => {

                let state = childSnap.val();
                this.setState(prevState => ({
                    services: [...prevState.services, state],
                }));
                console.log("servcies is:" + state)



            });

            this.fetchOtherData(name);




        })




    }



    /*componentWillMount(){
       
    
        /* setTimeout(() => {
            this.fetchData();
            console.log('hello timeout')
          }, 1000);
    
     
    
    
    
        
    
     
        }*/

    onFocus = () => {

        if (this.state.refresh === true) {
            console.log('true new')
        }

    }


    checkDisable(arr, event) {
        console.log('start' + event[0])
        console.log('end' + event[1])
        /*
        for(let i = 0; i < arr.length; i++){
            for(let k =0; k<this.state.disabledDates.length;k++){
                if(arr[i].getDay() === this.state.disabledDates[k].getDay() && arr[i].getMonth() === this.state.disabledDates[k].getMonth() && arr[i].getFullYear() === this.state.disabledDates[k].getFullYear()){
                    console.log('double date');
                    this.setState({
                        error: true,
                        errorMessage:'er is een datum geselect die reeds bezet is, selecteer een andere datum.'
                    });
                    console.error('dezde datum is al bezet')
                }else{
                    console.log('nothing');
                    this.setState({
                        selected:event
                    })
                    this.calculateNights(event[0],event[1]);
                }
            }
        }
        */
    }




    dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    }



    onchangeValue(event, back) {
        console.log(event[0])
        console.log(event[1])


        let dates = this.dateRange(event[0], event[1]);
        this.setState({
            dates: this.dateRange(event[0], event[1]),
            start: event[0],
            end: event[1]
        })


        console.log('hello:' + event[0].getMonth())


        for (let i = 0; i < dates.length; i++) {
            console.log('hellpher:' + this.state.disabledDates.length)
            for (let k = 0; k < this.state.disabledDates.length; k++) {
                console.log(this.state.disabledDates[k].getMonth());
                console.log(this.state.disabledDates[k].getDate());
                console.log(dates[i].getMonth())
                console.log(dates[i].getDate())
                console.log('nothing');
                this.setState({
                    selected: event, error: false
                })
                console.log("helloStatE:" + this.state.selected[0])
                this.calculateNights(event[0], event[1]);

                /*

                if (dates[i].getDate() === this.state.disabledDates[k].getDate() && dates[i].getMonth() === this.state.disabledDates[k].getMonth() && dates[i].getFullYear() === this.state.disabledDates[k].getFullYear()) {
                    console.log('double date' + dates[i].getDay());
                    this.setState({
                        errorMessage: 'Er is een datum geselect die reeds bezet is, selecteer een andere datum.',
                        error: true
                    });
                    console.error('dezde datum is al bezet');
                    return
                } else {
                   
                }*/
            }
        }



        /**/
    }

    startdayCustom() {
        return new Date(2021, 1, 8);
    }

    calculateNights(start, stop) {
        var date1 = new Date(start);
        var date2 = new Date(stop);

        // Zet datums om naar UTC
        date1.setUTCHours(0, 0, 0, 0);
        date2.setUTCHours(0, 0, 0, 0);

        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24) - 1);
        this.setState({
            totalNights: numberOfNights
        });
    }



    addDays = (date, days = 1) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    dateRangeBetween = (start, end, range = []) => {
        if (start > end) return range;
        const next = this.addDays(start, 1);
        return this.dateRange(next, end, [...range, start]);
    };

    /*dateRange(startDate, endDate, steps = 1) {
         const dateArray = [];
         let currentDate = new Date(startDate);
       
         while (currentDate <= new Date(endDate)) {
           dateArray.push(new Date(currentDate));
           // Use UTC date to prevent problems with time zones and DST
           currentDate.setUTCDate(currentDate.getUTCDate() + steps);
         }
       
         return dateArray;
       }*/
    generateBookingId() {
        console.log()
        let lengthIs = 0;


        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        this.bookingNumber = 'B' + year + month + (Number(this.state.eventDb.length) + 1);





    }

    checkDate(item) {

        let count = this.state.dates.length - 1;


        console.log("item is:" + item)
        for (let i = 0; i < count; i++) {
            console.log("dates is:" + this.state.dates[i].getDate())
            if (item.day === this.state.dates[i].getDate() && item.month === this.state.dates[i].getMonth() && item.year === this.state.dates[i].getFullYear()) {
                console.log('match found');
                console.log(item.day)
                console.log(item.amount)
                console.log(item.id)

                let newAmount = item.amount + 1;
                console.log(newAmount)


                Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.id + '/dates/' + item.id).set({
                    amount: newAmount,
                    year: item.year,
                    id: item.id,
                    month: item.month,
                    day: item.day
                }).then((data) => {
                    console.log('done')
                }).catch((error) => {
                    //error callback
                    console.log('error ', error)
                })
            } else {
                //this.newItem(item)
                console.log('no match')

            }
        }
    }

    planAppointmentFamily() {

        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/tempFam/').push().key;
        Firebase.database().ref('/clients/-uhfwoebfwioudb/tempFam/' + key).set({
            key: key,
            email: this.state.clientEmail,
            end: localStorage.getItem('endYear') + ',' + localStorage.getItem('endMonth') + ',' + localStorage.getItem('endDay'),
            id: this.bookingNumber,
            bookingsnumber: this.bookingNumber,
            ispayed: false,
            phone: this.state.clientPhone,
            place: this.state.type,
            resourceId: 4,
            startYear: localStorage.getItem('startYear'),
            startMonth: localStorage.getItem('startMonth'),
            startDay: localStorage.getItem('startDay'),
            endYear: localStorage.getItem('endYear'),
            endMonth: localStorage.getItem('endMonth'),
            chosenRoom: this.state.chosenRoom,
            endDay: localStorage.getItem('endDay'),
            title: this.state.clientName,
            type: this.state.type,
            singlePrice: this.state.price,
            totalNights: this.state.totalNights,
            persons: this.state.amountPersons,


        }).then(() => {
            //success callback
            if (this.state.type === 'biejanssen.nl' && this.state.isAlreadyPayed === 'nee') {
                toast.success('De e-mail is verzonden!');
                this.setState({
                    title: '', id: 0, resourceId: 0, start: '', end: ''
                })
                var xhr = new XMLHttpRequest();

                xhr.open('POST', 'https://app.biejanssen.nl/sendMailBookingFamily');
                xhr.setRequestHeader("Content-Type", "application/json");
                let data = {
                    nameClient: this.state.clientName,
                    emailClient: this.state.clientEmail,
                    id: key
                }
                xhr.send(JSON.stringify(data));
            } else {
                Firebase.database().ref('/clients/-uhfwoebfwioudb/events/' + key).set({
                    key: key,
                    email: this.state.clientEmail,
                    end: localStorage.getItem('endYear') + ',' + localStorage.getItem('endMonth') + ',' + localStorage.getItem('endDay'),
                    id: this.bookingNumber,
                    bookingsnumber: this.bookingNumber,
                    ispayed: true,
                    phone: this.state.clientPhone,
                    place: this.state.type,
                    resourceId: 4,
                    startYear: localStorage.getItem('startYear'),
                    startMonth: localStorage.getItem('startMonth'),
                    startDay: localStorage.getItem('startDay'),
                    endYear: localStorage.getItem('endYear'),
                    endMonth: localStorage.getItem('endMonth'),
                    chosenRoom: this.state.chosenRoom,
                    endDay: localStorage.getItem('endDay'),
                    title: this.state.clientName,
                    type: this.state.type,
                    singlePrice: this.state.price,
                    totalNights: this.state.totalNights,
                    persons: this.state.amountPersons,


                })
                toast.success('De boeking is toegevoegd!');
            }
        }).catch((error) => {
            //error callback
            console.log('error ', error)
        })


    }

    planAppointment() {




        console.log("nummer is:" + this.bookingNumber)






        let newDateArray = [];

        let datesFirebase = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.id + '/dates');
        datesFirebase.once('value', snapshot => {

            this.setState({ alreadyExistDates: [] })

            snapshot.forEach((childSnap) => {

                let state = childSnap.val();
                this.setState(prevState => ({
                    alreadyExistDates: [...prevState.alreadyExistDates, state],
                }));



                this.checkDate(state);

            });


        })


        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/events/').push().key;
        Firebase.database().ref('/clients/-uhfwoebfwioudb/events/' + key).set({
            key: key,
            email: this.state.clientEmail,
            end: this.state.end.getFullYear() + ',' + this.state.end.getMonth() + ',' + this.state.end.getDate(),
            id: this.bookingNumber,
            bookingsnumber: this.bookingNumber,
            ispayed: this.state.type === 'booking.com' ? true : this.state.isPayed,
            phone: this.state.clientPhone,
            roomnumber: this.state.chosenRoom,
            place: this.state.type,
            resourceId: this.state.resourceId,//aanpaasen
            startYear: this.state.start.getFullYear(),
            startMonth: this.state.start.getMonth(),
            startDay: this.state.start.getDate(),
            endYear: this.state.end.getFullYear(),
            endMonth: this.state.end.getMonth(),
            chosenRoom: this.state.chosenRoom,
            endDay: this.state.end.getDate(),
            title: this.state.clientName,
            type: this.state.type,
            singlePrice: this.state.price,
            totalNights: this.state.totalNights,
            persons: this.state.amountPersons,


        }).then(() => {
            //success callback
            if (this.state.type === 'biejanssen.nl' && this.state.isAlreadyPayed === 'nee') {
                toast.success('De e-mail is verzonden!');
                this.setState({
                    title: '', id: 0, resourceId: 0, start: '', end: ''
                })
                var xhr = new XMLHttpRequest();

                xhr.open('POST', 'https://app.biejanssen.nl/sendMailBooking');
                xhr.setRequestHeader("Content-Type", "application/json");
                let data = {
                    nameClient: this.state.clientName,
                    emailClient: this.state.clientEmail,
                    id: key
                }
                xhr.send(JSON.stringify(data));
            } else {
                toast.success('De boeking is toegevoegd!');
            }
        }).then(() => {

            this.setState({
                amountPersons: 0,
                totalNights: 0,
                price: 0,
                clientName: '', email: '',
                end: '',
                id: '',
                bookingsnumber: '',
                ispayed: false,
                phone: '',
                roomnumber: 0,

                resourceId: 0,
                startYear: '',
                startMonth: '',
                startDay: '',
                endYear: '',
                endMonth: '',
                chosenRoom: '',
                askPayment: false,

                endDay: '',
                title: '',

                singlePrice: '',
                totalNights: '',
                persons: '',
            })
        }).catch((error) => {
            //error callback
            console.log('error ', error)
        })




    }



    sendMail() {
        console.log('here')
        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/events/').push().key;
        var xhr = new XMLHttpRequest();

        xhr.open('POST', 'https://us-central1-cashierr-pixelpros.cloudfunctions.net/user/sendMailBooking');
        xhr.setRequestHeader("Content-Type", "application/json");
        let data = {
            nameClient: this.state.name,
            emailClient: 'joppe@pixelpros.nl',
            id: 'efwefe'
        }
        xhr.send(JSON.stringify(data));
    }

    CalculatePriceDifference(month, day, price) {

        if (month == 6 && day == 4 || month == 6 && day == 5 || month == 6 && day == 6 || month == 6 && day == 7 || month == 6 && day == 11 || month == 6 && day == 12 || month == 6 && day == 13 || month == 6 && day == 14 || month == 6 && day == 18 || month == 6 && day == 19 || month == 6 && day == 20 || month == 6 && day == 21) {

            let NewPrice = Number(price) + 10;

            return NewPrice;

        }
        /*
       else if(month == 4 && day == 5||month ==4 && day == 6||month == 4 && day == 7|| month == 3 && day == 8|| month == 3 && day == 9 || month == 3 && day == 10 ||month == 3 && day == 14 ||month == 3 && day == 15 ||month == 3 && day == 16||month == 4 && day == 18 ||month == 4 && day == 19||month == 4 && day == 20||month == 4 && day == 21||month == 4 && day == 26||month == 4 && day == 27||month == 4 && day == 28||month == 4 && day == 29|| month==5 || month==6||month == 7 && day == 13||month == 7 && day == 14||month == 7 && day == 15||month == 7 && day == 16||month == 7 && day == 17||month == 7 && day == 18||month == 7 && day == 19||month == 7 && day == 20||month == 7 && day == 21||month == 7 && day == 23||month == 7 && day == 23||month == 7 && day == 24||month == 7 && day == 25||month == 7 && day == 26||month == 7 && day == 27||month == 7 && day == 13||month == 7 && day == 28||month == 7 && day == 29||month == 11 && day == 15||month == 11 && day == 16||month == 11 && day == 17 
        ||month == 8*/

        else {
            let NewPrice = Number(price);

            return NewPrice;
        }

    }

    calculatePrice(item) {


        this.generateBookingId();

        for (let i = 0; i < this.state.options.length; i++) {
            console.log(this.state.options[i].id)
            console.log(item)
            let PriceHigherSummer = this.CalculatePriceDifference(this.state.selected[0].getMonth(), this.state.selected[0].getDate(), this.state.options[i].price);


            if (item == this.state.options[i].id) {
                this.setState({
                    amountPersons: this.state.options[i].person,
                    persons: item,
                    price: PriceHigherSummer
                })
                if (this.state.totalNights > 0 && this.state.error === false) {
                    console.log(this.state.selected[0], this.state.selected[1])
                    this.setState({
                        daysSelected: true
                    })
                }
            }
        }
    }

    changeType(event) {
        console.log(event)
        localStorage.setItem('bookingID', this.generateBookingId())
        localStorage.setItem('roomdataTitle', 'Familie kamer');
        localStorage.setItem('roomdataId', '-iwkbenw');

        localStorage.setItem('guests', this.state.amountPersons);
        localStorage.setItem('resourceId', 4);


        if (event === 'Vrijdag-weekend') {
            this.disableFridays();
            this.setState({
                price: 449,
                defaultPrice: 449,
                selectedType: "Vrijdag-weekend",
                uitcheckdag: 'Maandag',
                totalNights: 3
            })
            localStorage.setItem('totalNights', 3);
            localStorage.setItem('roomdataPrice', 449);
        } else if (event === 'Maandag-midweek' || event === 'Maandag-week') {
            this.disableMondays();
            if (event === 'Maandag-midweek') {
                this.setState({
                    price: 599,
                    defaultPrice: 599,
                    uitcheckdag: 'Vrijdag',
                    selectedType: "Maandag-midweek",
                    totalNights: 4
                })
                localStorage.setItem('totalNights', 4);
                localStorage.setItem('roomdataPrice', 599);
            } else if (event === 'Maandag-week') {
                this.setState({
                    price: 799,
                    defaultPrice: 799,
                    uitcheckdag: 'Maandag',
                    selectedType: "Maandag-week",
                    totalNights: 7
                })
                localStorage.setItem('totalNights', 7);
                localStorage.setItem('roomdataPrice', 799);
            }
        }
    }

    disableFridays() {

        let disabledDays = this.getFridays(new Date(), 400)

        for (let i = 0; i < disabledDays.length; i++) {
            this.setState(prevState => ({
                fridays: [...prevState.fridays, disabledDays[i]],
                monday: false,
                showCalendar: true
            }))
        }



    }

    disableMondays() {
        let disabledDays = this.getMondays(new Date(), 400)
        for (let i = 0; i < disabledDays.length; i++) {
            this.setState(prevState => ({
                mondays: [...prevState.mondays, disabledDays[i]],
                monday: true,
                showCalendar: true
            }))
        }
    }

    getFridays(date, days) {
        var f, newDate;

        f = [];
        if (days > 0) {
            if (date.getDay() != 5) {
                f.push(new Date(date));
            }
            newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            return f.concat(this.getFridays(newDate, days - 1));
        }
        return f;
    }



    getMondays(date, days) {
        var f, newDate;

        f = [];
        if (days > 0) {
            if (date.getDay() != 1) {
                f.push(new Date(date));
            }
            newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            return f.concat(this.getMondays(newDate, days - 1));
        }
        return f;
    }

    changeAmountPeople(event) {
        if (event === '4') {
            this.setState({
                price: this.state.defaultPrice,
                amountPersons: 4
            })
        } else if (event === '5') {
            this.setState({
                price: this.state.defaultPrice + (10 * this.state.totalNights),
                amountPersons: 5

            })
            localStorage.setItem('guests', 5);
            localStorage.setItem('roomdataPrice', this.state.defaultPrice + (10 * this.state.totalNights));
        }
        else if (event === '6') {
            this.setState({
                price: this.state.defaultPrice + (20 * this.state.totalNights),
                amountPersons: 6
            })
            localStorage.setItem('guests', 6);
            localStorage.setItem('roomdataPrice', this.state.defaultPrice + (20 * this.state.totalNights));
        }
        else if (event === '1') {
            this.setState({
                price: this.state.defaultPrice + (10 * this.state.totalNights),
                amountPersons: 1

            })
            localStorage.setItem('guests', 1);
            localStorage.setItem('roomdataPrice', this.state.defaultPrice + (10 * this.state.totalNights));
        }
        else if (event === '2') {
            this.setState({
                price: this.state.defaultPrice + (10 * this.state.totalNights),
                amountPersons: 2

            })
            localStorage.setItem('guests', 2);
            localStorage.setItem('roomdataPrice', this.state.defaultPrice + (10 * this.state.totalNights));
        }
        else if (event === '3') {
            this.setState({
                price: this.state.defaultPrice + (10 * this.state.totalNights),
                amountPersons: 3

            })
            localStorage.setItem('guests', 3);
            localStorage.setItem('roomdataPrice', this.state.defaultPrice + (10 * this.state.totalNights));
        }
    }

    handleDayClick(day) {
        this.setState({
            selectedDay: day,
        });

        if (this.state.selectedType === 'Maandag-midweek') {
            let calcLastDay = day.getDate() + 4;



            localStorage.setItem('fullStartDate', day);
            localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
            localStorage.setItem('startYear', day.getFullYear());
            localStorage.setItem('startMonth', day.getMonth());
            localStorage.setItem('startDay', day.getDate())
            localStorage.setItem('endYear', day.getFullYear());
            localStorage.setItem('endMonth', day.getMonth());
            localStorage.setItem('endDay', calcLastDay)

        } else if (this.state.selectedType === 'Maandag-week') {
            let calcLastDay = day.getDate() + 7;



            localStorage.setItem('fullStartDate', day);
            localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
            localStorage.setItem('startYear', day.getFullYear());
            localStorage.setItem('startMonth', day.getMonth());
            localStorage.setItem('startDay', day.getDate())
            localStorage.setItem('endYear', day.getFullYear());
            localStorage.setItem('endMonth', day.getMonth());
            localStorage.setItem('endDay', calcLastDay)

        } else if (this.state.selectedType === 'Vrijdag-weekend') {
            let calcLastDay = day.getDate() + 3;



            localStorage.setItem('fullStartDate', day);
            localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
            localStorage.setItem('startYear', day.getFullYear());
            localStorage.setItem('startMonth', day.getMonth());
            localStorage.setItem('startDay', day.getDate())
            localStorage.setItem('endYear', day.getFullYear());
            localStorage.setItem('endMonth', day.getMonth());
            localStorage.setItem('endDay', calcLastDay)

        }


    }

    render() {
        const { selectedDay } = this.state;
        const FORMAT = 'dd/MM/yyyy';
        return (
            <Row>
                <Toaster />
                <Col lg="6" className="backend-form">



                    <label>Kamer:</label>
                    <br />
                    <select onChange={event => this.handleTreatmentChange(event)}>
                        <option value="" disabled selected>Kamer</option>
                        {
                            this.state.treatments.map(function (item, i) {
                                return <option key={i} value={item.resourceId}>{item.name}</option>
                            })
                        }
                    </select>
                    <br /> <br />
                    {this.state.loadingData ?
                        <div>
                            {this.state.serouceisset ?
                                <span>
                                    <label>Kamernummer:</label>
                                    <br />
                                    <select required onChange={event => this.handleRoomNumber(event)}>
                                        <option value="" disabled selected>Kamer</option>
                                        {
                                            this.state.roomnumbers.map(function (item, i) {
                                                return <option key={i} value={item.room}>Kamer nummer: {item.room}</option>
                                            })
                                        }
                                    </select>
                                    <br /> <br /></span>
                                : null}
                            <label>Klant komt van:</label>
                            <br />
                            <select required onChange={event => this.handleType(event.target.value)}>
                                <option value="" disabled selected>Type</option>
                                <option value="biejanssen.nl">BieJanssen.nl</option>
                                <option value="booking.com">Booking.com</option>
                                <option value="bedandbreakfast.nl">Bedandbreakfast.nl</option>
                            </select><br /><br />
                            {this.state.askPayment ?
                                <span>
                                    <label>Heeft de klant al betaald:</label>
                                    <br />
                                    <select required onChange={event => this.handleTypeAsk(event.target.value)}>
                                        <option value="" disabled selected>Selecteer optie</option>
                                        <option value="ja">Ja</option>
                                        <option value="nee">Nee</option>
                                    </select><br /><br /></span> : null}
                            <label>
                                Naam klant:
                            </label>
                            <br />
                            <input required type="text" name="name-client" value={this.state.clientName} onChange={event => this.setState({ clientName: event.target.value, errorMessage: '' })} />
                            <br /> <br />
                            <label>
                                Email klant:
                            </label>
                            <br />
                            <input required type="text" name="name-client" value={this.state.clientEmail} onChange={event => this.setState({ clientEmail: event.target.value, errorMessage: '' })} />
                            <br /> <br />
                            <label>
                                Telefoon klant:
                            </label>
                            <br />
                            <input type="text" name="name-client" value={this.state.clientPhone} onChange={event => this.setState({ clientPhone: event.target.value, errorMessage: '' })} />
                            <br /> <br />
                            {this.state.nameResource === 'Familiekamer'
                                ?
                                <span>
                                    <label>Type boeking</label><br />
                                    <select onChange={(event) => this.changeType(event.target.value)}><option selected="selected" disabled="disabled">Selecteer een variant</option><option value="Maandag-midweek">Maandag - midweek</option> <option value="Maandag-week">Maandag - hele week</option><option value="Vrijdag-weekend">Vrijdag - Weekend</option></select>

                                    <br /><br /> <label>Aantal personen:</label><br />
                                    <select required onChange={(event) => this.changeAmountPeople(event.target.value)}><option selected="selected" disabled="disabled">Selecteer aantal personen</option><option value="1">1</option><option value="2">2</option><option value="3">3</option>   <option value="4">4</option> <option value="5">5</option><option value="6">6</option></select>
                                    <br /><br /> <label>Aankomst datum</label><br />

                                    <DayPicker
                                        selectedDays={this.state.selectedDay}
                                        onDayClick={(event) => this.handleDayClick(event)}
                                        formatDate={this.formatDate}
                                        format={FORMAT}
                                        parseDate={this.parseDate}

                                        onDayChange={(event) => this.handleDayChangeEnd(event)}
                                    /><br /><br />


                                </span>
                                :
                                <span>
                                    <label>
                                        Datum:
                                    </label>
                                    <Calendar locale={"nl-NL"} value={[this.state.selected[0], this.state.selected[1]]} selectRange={true} returnValue={"range"} onChange={(event) => this.onchangeValue(event, event)} minDate={new Date()} /* tileDisabled={({date, view}) =>
                    (view === 'month') && // Block day tiles only
                    this.state.disabledDates.some(disabledDate =>
                      date.getFullYear() === disabledDate.getFullYear() &&
                      date.getMonth() === disabledDate.getMonth() &&
                      date.getDate() === disabledDate.getDate()
                    )}*/ />
                                    {this.state.error ?
                                        <span className="error-message-room">{this.state.errorMessage}</span>
                                        : null}
                                    <br /> <br />
                                    <label>Aantal personen</label><br />
                                    <ButtonGroup variant="text" color="#27718F" aria-label="text primary button group" className="select-buttons" >
                                        {this.state.options.sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, i) => (
                                            <Button value={item.id} onClick={(event) => this.calculatePrice(event.target.value)}>{item.person}</Button>
                                        ))}
                                    </ButtonGroup>
                                    <br /> <br />


                                </span>

                            }




                            <Link className="back-link" to={{ pathname: `/agenda` }} >Terug</Link>
                            {this.state.nameResource === 'Familiekamer'
                                ?
                                <span><Button onClick={() => this.planAppointmentFamily()} className="button-create-appointment">Plaats boeking</Button> {this.state.uploaded ? <span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> : null}<br /> <br /></span>
                                :
                                <span> <Button onClick={() => this.planAppointment()} className="button-create-appointment">Plaats boeking (gewoon)</Button> {this.state.uploaded ? <span className="post-it-update"> <i class="fas fa-check"></i>  Afspraak is aangemaakt.</span> : null}<br /> <br /></span>
                            }
                        </div>

                        : null}

                </Col>
                <Col sm={6}>
                    <table className="overview-order">
                        <tr>
                            <td><span className="total-fat">Boekingsnummer</span></td>
                            <td>{this.bookingNumber}</td>
                        </tr>
                        <tr>
                            <td><span className="total-fat">Kamer</span></td>
                            <td>{this.state.nameResource}</td>
                        </tr>
                        <tr>
                            <td><span className="total-fat">Omschrijving</span></td>
                            <td>Overnachting</td>

                        </tr>
                        <tr>
                            <td><span className="total-fat">Uw naam</span></td>
                            <td>{this.state.clientName}</td>
                        </tr>
                        <tr>
                            <td><span className="total-fat">Uw e-mail</span></td>
                            <td>{this.state.clientEmail}</td>
                        </tr>

                        <tr>
                            <td><span className="total-fat">Aantal personen</span></td>
                            <td>{this.state.amountPersons}</td>
                        </tr>
                        <tr>
                            <td><span className="total-fat">{this.state.nameResource === 'Familiekamer'
                                ? <span>Prijs</span> : <span>Prijs per nacht</span>}</span></td>
                            <td>€ {parseFloat(this.state.price).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td><span className="total-fat">Aantal nachten</span></td>
                            <td>{this.state.totalNights}</td>
                        </tr>
                        <tr>
                            <td><span className="total-fat">Toeristen belasting</span></td>
                            <td>€ {parseFloat((this.state.toeristenbelasting * this.state.amountPersons) * this.state.totalNights).toFixed(2)}</td>
                        </tr>
                        {this.state.nameResource === 'Familiekamer'
                            ?
                            <tr>
                                <td><span className="total-fat">Schoonmaakkosten</span></td>
                                <td>€ 40.00</td>
                            </tr>
                            : null}


                        <tr>
                            <td className="total-backend-order"><span className="total-oder">Totaal</span></td>
                            {this.state.nameResource === 'Familiekamer'
                                ?
                                <td className="total-backend-order"><span className="total-oder">€ {parseFloat(((this.state.toeristenbelasting * this.state.amountPersons) * this.state.totalNights) + (this.state.price) + 40).toFixed(2)}</span></td>
                                :
                                <td className="total-backend-order"><span className="total-oder">€ {parseFloat(((this.state.toeristenbelasting * this.state.amountPersons) * this.state.totalNights) + (this.state.totalNights * this.state.price)).toFixed(2)}</span></td>}
                        </tr>



                    </table>

                </Col>



            </Row>



        );
    }
};

export default AddAppointment;