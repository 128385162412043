import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ChangeAppointment from "../../components/calendar/changeAppointment";


const ChangeAppointmentView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Agenda - Aanpassen" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <ChangeAppointment eventData={props.location.state}/>
    
  </Container>
);

export default ChangeAppointmentView;
