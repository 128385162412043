import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';

import DeleteIcon from "../../assets/images/svg/times-solid.svg"
import InvoiceIcon from "../../assets/images/svg/invoice.svg"
import { Link } from 'react-router-dom';






class BillDetailOrderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
      };
    }

    componentDidMount(){
        console.log(this.props.billData.billData);


    }


   


    render() {  

        
        return (
            <div>

                <Row >
                    <Col sm={4} className="bill-detail-list">
                        <ul>
                            <li>Ordernummer:<span className="bill-detail-list-element">{this.props.billData.billData.id}</span></li>
                            <li>Naam:<span className="bill-detail-list-element">{this.props.billData.billData.name}</span></li>
                            <li>Afleveradres:<span className="bill-detail-list-element">{this.props.billData.billData.adres}, {this.props.billData.billData.zip} ,{this.props.billData.billData.city}</span></li>
                            
                            <li>Totaal:<span className="bill-detail-list-element"> € {this.props.billData.billData.totalPrice}</span></li>
                        </ul>
                    </Col>
                    <Col sm={8}></Col>
                <Col sm={12} className="bills-component">

<table className="bills-table"> 
  <tr>
    <th>Naam aanvrager</th>
    <th>Aantal onbijtjes</th>
    <th>Betaald</th>
    <th>Totaal</th>
  </tr>

    <tr>
      <td>{this.props.billData.billData.name}</td>
      <td>{this.props.billData.billData.amount}</td>
      <td>{this.props.billData.billData.ispayed ?<img className="icon-calendar" src={require(`../../assets/images/svg/payed.svg`)}/>:<img className="icon-calendar" src={require(`../../assets/images/svg/warning-icon.svg`)}/>}</td>
      <td>€ {this.props.billData.billData.totalPrice}</td>
  </tr>
                   
 </table>
 </Col>

 <Col sm={12}>
 <Link className="back-link" to={{pathname: `/rekeningen`}} >Terug</Link>
 </Col>
 <Col sm={12} className="black-space">

 </Col>
                </Row>
                
            
            
            </div>
        );
    }
};

export default BillDetailOrderComponent;
