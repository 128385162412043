import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Loader from 'react-loader-spinner';

class ContactComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mail: '',
            shortDesc: '',
            phone: '',
            succesMessage: false,
            loader: false,
        };
    }

    resetMessage() {
        this.setState({ succesMessage: false });
        return;
    }

    sendForm() {
        this.setState({ loader: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'localhost:3000');
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT");
        fetch("https://biejanssen.nl/ContactForm", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ name: this.state.name, email: this.state.mail, phone: this.state.phone, desc: this.state.shortDesc })
        })
            .then(res => {
                if (res.status === 201) {
                    this.setState({
                        name: '',
                        mail: '',
                        phone: '',
                        shortDesc: '',
                        succesMessage: true, loader: false
                    });

                    setTimeout(() => this.resetMessage(), 3000);
                }
            });
    }

    render() {
        return (
            <div className="home-page">
                <Row className="contact-row">
                    <Col sm={12} className="contact-colum">
                        <h2>Heb je een vraag?</h2>
                        <p>Uiteraard kun je ons op verschillende manier bereiken. Het snelst is het om even te bellen naar: <a href="tel:0625226150">06 25226150</a></p>
                    </Col>
                </Row>
                <Container>
                    <Row className="contact-row-details">
                        <Col sm={8} className="contact-colom-details">
                            <img src="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FB%26B%20Bie%20Janssen%202024-29-min-min.jpg?alt=media&token=d491f3c9-8307-45e5-a494-c41a6bdfd7d9" alt="Contact" style={{ width: '100%', height: 'auto', marginBottom: '20px' }} />
                        </Col>
                        <Col sm={4} className="contact-colom-details-form">
                            <h2>Contact</h2>
                            <p>
                                Heb je een vraag in het algemeen of over een reservering? Neem dan contact met ons op via het formulier. Ook kun je ons direct bereiken via onderstaande gegevens. Wij doen er alles aan om je vraag z.s.m. te beantwoorden!
                            </p>
                            <h4>Adresgegevens</h4>
                            <ul>
                                <li>Raadhuisstraat 46, 6181 HB Elsloo</li>
                                <li><b>Email:</b> <a href="mailto:info@biejanssen.nl">info@biejanssen.nl</a></li>
                                <li><b>Telefoon:</b> 06 25226150</li>
                            </ul>

                        </Col>
                        <Col sm={12} className="contact-colom-details-form">
                            <h2>Stel je vraag</h2>
                            <form>
                                <input required type="text" placeholder="Naam" name="name" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage: '' })} />
                                <input type="text" placeholder="E-mail" name="email" value={this.state.mail} onChange={event => this.setState({ mail: event.target.value, errorMessage: '' })} /><br />
                                <input required type="text" placeholder="Telefoon" name="phone" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage: '' })} />
                                <textarea name="desc" placeholder="Uw bericht" value={this.state.shortDesc} onChange={event => this.setState({ shortDesc: event.target.value, errorMessage: '' })} />
                                {this.state.loader ?
                                    <button onClick={() => this.sendForm()} type="submit" className="frontend-button-dark" form="hook-form"><Loader type="ThreeDots" color="white" height={10} width={50} timeout={40000} /></button>
                                    :
                                    <button onClick={() => this.sendForm()} type="submit" className="frontend-button-dark" form="hook-form">Verstuur</button>
                                }
                            </form></Col>
                    </Row>
                    {this.state.succesMessage ?
                        <Row className="succes-send-form">
                            <Col sm={12}>
                                <h5>Bedankt voor je bericht!</h5>
                                <p>Je bericht is succesvol verstuurd, wij nemen zo snel mogelijk contact met je op!</p>
                            </Col>
                        </Row> : null}
                </Container>
                <Row>
                    <Col sm={12} className="map-integration">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5027.511187451359!2d5.760134632869449!3d50.94673285936211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0c1cf8202fa5b%3A0x9b674d3a541840d1!2sB%26B%20Bie%20Janssen!5e0!3m2!1snl!2snl!4v1614586884418!5m2!1snl!2snl" allowFullScreen></iframe>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default ContactComponent;
