import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import ChashdeskMethod from "../components/cashdesk/chooseMethod";



const ChooseMethod = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Kassa" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <ChashdeskMethod paymentData={props.location.state}/>
    
  </Container>
);

export default ChooseMethod;
