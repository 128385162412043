import React, { Component, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link, Redirect  } from 'react-router-dom';
import Firebase from 'firebase';
import ChooseMethod from '../../views/ChooseMethod';


class ChashdeskMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                paymentData:[],
                transactionSet: false,
                employee:'',
                price:0,
                treatments:[],
                paymentMethod:Boolean,
                isSaleSet:'',
                typeSale:'',
                regularPrice:0,
        };
    }

    componentWillMount(){

        this.setState({
            paymentData: this.props.paymentData,
            employee: this.props.paymentData.employee,
            treatments: this.props.paymentData.treatments,
            price: this.props.paymentData.price,
            isSaleSet: this.props.paymentData.isSaleSet, 
            typeSale: this.props.paymentData.typeSale, 
            regularPrice: this.props.paymentData.regularPrice
        })
     console.log("price:"+this.props.paymentData.price);
     console.log("Treatments:"+ this.props.paymentData.treatments);
     console.log("isSaleSet:"+ this.props.paymentData.isSaleSet);
    }

    redirectActive(){
        this.setState({
            transactionSet: true
        })
    }

    addMethodToPaymentData(method){
        console.log("methode is:" +method)
        this.setState({
            paymentMethod: method,
        },
       this.redirectActive
        )
    }

   
    render() {
        if(this.state.transactionSet){
            return <Redirect to={{pathname: `/transactie-afronden`,state: {employee:this.state.employee, treatments: this.state.treatments, price: this.state.price, paymentMethod: this.state.paymentMethod,  isSaleSet: this.state.isSaleSet, typeSale: this.state.typeSale, regularPrice:this.state.regularPrice}}} />
        }

        return (
            <div>
            <Row>
                <Col lg={2}></Col>
                <Col lg={8} className="paymentmethod-chaskdesk">
                       
                        <h2>Hoe wilt u graag afrekenen?</h2>
                        <p>rem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed sollicitudin tortor. Maecenas viverra vitae nisi at maximus. Donec id porta libero. Pellentesque cursus rhoncus nibh sit amet lacinia. Phasellus auctor vel tortor eget consequat. Nulla consectetur consequat ex laoreet scelerisque. Vestibulum dignissim vitae urna a congue.</p>
                        <Row>
                            <Col md="6" className="paymentmethod-buttons">
                            <Button variant="primary" className="button-chashdesk"  onClick={() => this.addMethodToPaymentData(true)}>Contact betalen</Button>
                            </Col>
                            <Col md="6" className="paymentmethod-buttons">
                            <Button variant="primary" className="button-chashdesk"  onClick={() => this.addMethodToPaymentData(false)}>Betalen met pin</Button>
                            </Col>
                        </Row>

                </Col>
            </Row>

            </div>

        );
    }
};

export default ChashdeskMethod;