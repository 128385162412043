import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

const products = [
  { name: 'Product 1', desc: 'A nice thing', price: '€ 9.99' },
  { name: 'Product 2', desc: 'Another thing', price: '€ 3.45' },
  { name: 'Product 3', desc: 'Something else', price: '€ 6.51' },
  { name: 'Product 4', desc: 'Best thing of all', price: '€ 14.11' },
  { name: 'Verzendkosten', desc: '', price: 'Free' },
];
const addresses = [localStorage.getItem('address'), localStorage.getItem('zip'), localStorage.getItem('city'), localStorage.getItem('country')];


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Besteloverzicht
      </Typography>
      <List disablePadding>
        
          <ListItem className={classes.listItem}>
            <ListItemText primary={localStorage.getItem('roomdataTitle')}  />
            <Typography variant="body1" >{localStorage.getItem('totalNights')} nachten, {localStorage.getItem('guests').toString()} personen</Typography>
            <Typography variant="body2">€ {parseFloat(localStorage.getItem('roomdataPrice')*localStorage.getItem('totalNights')).toFixed(2)}</Typography>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary={"+ Toeristenbelasting"}  />
            <Typography variant="body1">{localStorage.getItem('totalNights')} nachten, {localStorage.getItem('guests').toString()} personen</Typography>
            <Typography variant="body2" className="room-checkout">€ {(2*localStorage.getItem('guests')*localStorage.getItem('totalNights')).toFixed(2)}</Typography>
          </ListItem>

          <hr />
  
        <ListItem className={classes.listItem}>
          <ListItemText primary="Totaal" />
          <Typography variant="subtitle1" className={classes.total}>
            € {parseFloat((localStorage.getItem('roomdataPrice')*localStorage.getItem('totalNights'))+(2*localStorage.getItem('guests')*localStorage.getItem('totalNights'))).toFixed(2)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Factuurgegevens
          </Typography>
          <Typography gutterBottom>{localStorage.getItem('firstname')} {localStorage.getItem('lastname')}</Typography>
          <Typography gutterBottom>{localStorage.getItem('mail')}</Typography>
          <Typography gutterBottom>{localStorage.getItem('phone')}</Typography>
          <Typography gutterBottom>{localStorage.getItem('city')} {localStorage.getItem('country')}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Betaalmethoden
          </Typography>
          <Typography gutterBottom>{localStorage.getItem('betaalmethode')}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
