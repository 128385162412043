import React from "react";
import { Container, Row, Col } from "shards-react";
import SignOnMarketScreen from "../../components/specificComponents/marktBeekRegistratie";

const RegistrationView = () => (
  <Container fluid className="main-content-container px-4">
      <SignOnMarketScreen />
    
  </Container>
);

export default RegistrationView;
