import React from "react";
import { Container, Row, Col } from "shards-react";
import BillsComponent from "../../components/Bills/BillsComponent";
import ClientComponent from "../../components/Clients/ClientComponent";

import PageTitle from "../../components/common/PageTitle";



const ClientView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Klanten" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <ClientComponent />
  </Container>
);

export default ClientView;
