import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const CustomEvent = ({event}) =>{
    var backgroundColor = '';
    var icon = '';

    console.log('event is:'+event.resourceId);
    if(event.place === 'biejanssen.nl'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#27718F';
    }else if(event.place === 'booking.com'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#A560E0';
    }else if(event.place === 'airbnb.com'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#7AA898';
    }else if(event.place === 'bedandbreakfast.nl'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#E06060';
    }else if(event.place === ''){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#E06060';
    }
   
    return(

        <span className="custom-event" style={{backgroundColor:backgroundColor}}>
         <img className="icon-calendar" src={require(`../../assets/images/svg/${icon}.svg`)}/><br/><br/>
         <br/>         
         <strong>{event.title}</strong><br/><br/>
        <strong>nr:</strong> {event.roomnumber}
    

                
   
         
            

        </span>
    )
}

class DashboardIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments:[],
            thisMonth:0,
            target: 5000,
            setTarget: 5000,
            newBookings:0,
            breakfeast:0,
            nights:0,
            eventDb: [
                {title: "boeking", id: 1,resourceId: 1,start: new Date(2021,1,4,8,0,0),end: new Date(2021,1,5,13,0,0)},
                {title: "boeking", id: 1,resourceId: 1,start: new Date(2021,1,4,8,0,0),end: new Date(2021,1,5,13,0,0)}
               ],
               resourceMap:[
              
               ],
            day:'',
            month:'',
            data :  {
                datasets: [{
                    data: [10,10],
                    backgroundColor:["#334B5F","#EDEBEB"],
                }],
               
            
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    'Verkocht',
                    'Doel',
               
                ]
            },
        };
    }

    getDay(){
        var d = new Date();
        var day = d.getDate();
        var month = d.getMonth();

        if(month === 0){
            this.setState({
                day: day,
                month:"Januari"
            })
        }else if(month === 1){
            this.setState({
                day: day,
                month:"Februari"
            })
        }else if(month === 2){
            this.setState({
                day: day,
                month:"Maart"
            })
        }
        else if(month === 3){
            this.setState({
                day: day,
                month:"April"
            })
        }
        else if(month === 4){
            this.setState({
                day: day,
                month:"Mei"
            })
        }
        else if(month === 5){
            this.setState({
                day: day,
                month:"Juni"
            })
        }
        else if(month === 6){
            this.setState({
                day: day,
                month:"Juli"
            })
        }
        else if(month === 7){
            this.setState({
                day: day,
                month:"Augustus"
            })
        }
        else if(month === 8){
            this.setState({
                day: day,
                month:"September"
            })
        }
        else if(month === 9){
            this.setState({
                day: day,
                month:"Oktober"
            })
        }
        else if(month === 10){
            this.setState({
                day: day,
                month:"November"
            })
        }
        else if(month === 11){
            this.setState({
                day: day,
                month:"December"
            })
        }

    }

    getEvents(){
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');
        
        ref.on('value' , snapshot => {
            this.setState({
                eventDb:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("month is:" +new Date(state.startYear,state.startMonth,state.startDay));
            
                let newEvents = {email:state.email,phone:state.phone,roomnumber:state.roomnumber,title: state.title, id: state.id, resourceId: Number(state.resourceId),start: new Date(state.startYear,state.startMonth,state.startDay,15,0,0),end: new Date(state.endYear,state.endMonth,state.endDay,17,0,0), place: state.place, ispayed: state.ispayed};
                console.log(newEvents);
                this.setState(prevState => ({
                   eventDb: [...prevState.eventDb, newEvents]
                }))  

                if(new Date(state.startYear,state.startMonth,state.startDay)=== new Date()){
                   this.setState({
                    nights:this.state.nights +1
                   })
                }
        })});

    }

    getEmployees(){
    
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
                let employee = {resourceTitle: state.name, resourceId: state.resourceId};
                this.setState(prevState => ({
                    resourceMap: [...prevState.resourceMap, employee]
                }))  

        })});
    }



    componentDidMount() {
        this.getDay();

        this.getEmployees();

        this.getEvents();
    }

    onEventDrop = (data) => {
        console.log(data);
      };

    onEventResize = (data) => {
        const { start, end } = data;
    
        this.setState((state) => {
          state.events[0].start = start;
          state.events[0].end = end;
          return { events: state.events };
        });
      };


    

    render() {
        let today = new Date;
        let localizer = momentLocalizer(moment);
        return (
            <div>
            <Row className="col-sm-12 row-dashboard">
                
                    <Col  className="dashboard-col">
                        <img alt='Het logo van Picoo' src={require('../../assets/images/icons/overnachtingen.svg')}/>
                        <h4>{this.state.nights}</h4>
                        <p>Overnachtingen</p>
                    </Col>
                    <Col className="dashboard-col">
                        <img alt='Het logo van Picoo' src={require('../../assets/images/icons/boekingen.svg')}/>
                        <h4>{this.state.newBookings}</h4>
                        <p>Boekingen vandaag</p>
                    </Col>
                    <Col className="dashboard-col">
                        <img alt='Het logo van Picoo' src={require('../../assets/images/icons/breakfast.svg')}/>
                        <h4>{this.state.breakfeast}</h4>
                        <p>Ontbijtjes</p>
                    </Col>
                    <Col className="dashboard-col ">
                        <p className="day">{this.state.day}</p>
                        <p className="month">{this.state.month}</p>
                    </Col>
                  
              

                
            </Row>
            <Row className="col-sm-12 row-dashboard">
                <Col className="calendar-dashboard" >
                <Calendar
                showMultiDayTimes
                localizer={this.localizer}
                defaultDate={moment().toDate()}
                defaultView="day"
                events={this.state.eventDb}
                localizer={localizer}
                onEventDrop={this.onEventDrop}
                onEventResize={this.onEventResize}
                resizable
                min={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 6)}
                max={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 23)}
                resources={this.state.resourceMap}
                style={{ height: "100vh" }}
                views={['day','work_week']}
                timeslots={1}
                step={60}
                resources={this.state.resourceMap}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                culture={moment.locale('nl')}
                messages={{today:'Vandaag',previous:'<', next:'>',month:'Maand',week:'Week',day:'dag'}}
                components={{
                    event: CustomEvent
                  }}
        />
        </Col>
   
            </Row>
            </div>



        );
    }
};

export default DashboardIndex;