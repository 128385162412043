import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import AboutUsComponent from "../../components/Frontend/AboutUsComponent";
import ContactComponent from "../../components/Frontend/ContactComponent";
import HomeComponent from "../../components/Frontend/HomeComponent";
import PayBookingComponent from "../../components/Frontend/PayBookingComponent";
import PayBookingFamilyComponent from "../../components/Frontend/PayBookingFamMan";
import PayBreaffastOnlineScreenComponent from "../../components/Frontend/PayBreaffastOnlineScreenComponent";
import PayBreakfastComponent from "../../components/Frontend/PayBreakfastComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const PayBookingFamfastOnlineView = ({match,location}) => (

  
  <Container fluid  className="sub-page" >
 <HeaderView />
    <PayBookingFamilyComponent id={location.search}/>
    <FooterView />

  
  </Container>
);

export default PayBookingFamfastOnlineView;
