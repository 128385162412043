import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button,Container } from "shards-react";


class SurroundingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
       
        return (
            <div className="home-page">
                <Row className="surroundings-row">
                <Col sm={12} className="surroundings-colum">
                    
                    <h2>Omgeving</h2>
                    <p>In en rond Elsloo is genoeg te zien en te beleven. Voor zowel jong als oud. Zo kun je prachtige wandelingen maken, fietsen op de bekende knopenpuntenroute of maak een uitstapje naar de Maasvallei. Winkelen doe je in steden zoals Maastricht, Sittard, Genk en/of Aken. Allemaal binnen een half uurtje bereikbaar. Maar ook ouders met kinderen komen aan hun trekken. Breng een bezoekje aan het Steinderbos, Gaia Zoo of de Valkenier. Cultuur snuiven doe je in de vele musea die Limburg rijk is en breng vooral een bezoek aan het Streekmuseum van Elsloo op loopafstand van onze B&B in de nostalgische kern van Elsloo.</p>
                    
                </Col>
            </Row>
            <Container>
            <Row >
                <Col sm={12} className="surroundings-home">
                    <h2>Wat is er te beleven?</h2>
                    <h5>Alles tot de puntjes verzorgd</h5>
                </Col>
            </Row>

           
            <Row className="location-row">
            <Col sm={6} className="location-colom">
                    <img src={require('../../assets/images/frontend/fietsroutes-1.jpg')}/>
                </Col>
                <Col sm={6} className="location-colom">
                    <h5>Prachtige routes</h5>
                    <h2>Wandelroutes</h2>
                    <p>
                    B&B Bie Janssen is gelegen in de oude kern van Elsloo. Vanuit onze B&B kun je verschillende routes nemen. Zo kun je ervoor kiezen om de prachtige natuur in te trekken, of de Belgische grens over te steken. Wij delen graag onze ervaring over de mooie plekken die Zuid-Limburg te bieden heeft. Wanneer je vraagt voor een leuke route kunnen wij je waarschijnlijk verrassen met een route die je niet op iedere website terug vindt maar op de meest unieke plekken brengt, kortom, een geweldige locatie met veel mogelijkheden voor fietsend-/ wandelend publiek.
                    </p>
                   {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                </Col>
                
            </Row>
            <Row className="location-row desktop">
            <Col sm={6} className="location-colom">
                    <h5>Voor het gezin</h5>
                    <h2>Kids</h2>
                    <p>
                    Bezoek je met het gezin onze B&B dan is het een mogelijkheid om er een leuke dag voor iedereen van te maken. Op nog geen 15 minuten rijden bevindt zich het Steinerbos. Een buitenspeeltuin in combinatie met een buiten-/binnenzwembad voor jong tot oud. Wanneer de zon volop schijnt is het heerlijk om op de ligweide uit te rusten terwijl de kinderen zich amuseren in het zwembad. Meer informatie over deze accommodatie vindt je terug op de website.
                    </p>
                  {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                </Col>
            <Col sm={6} className="location-colom">
                    <img src={require('../../assets/images/frontend/steinerbos.jpg')}/>
                </Col>
                
                
            </Row>
            <Row className="location-row mobile">
           
            <Col sm={6} className="location-colom">
                    <img src={require('../../assets/images/frontend/steinerbos.jpg')}/>
                </Col>
                <Col sm={6} className="location-colom">
                    <h5>Voor het gezin</h5>
                    <h2>Kids</h2>
                    <p>
                    Bezoek je met het gezin onze B&B dan is het een mogelijkheid om er een leuke dag voor iedereen van te maken. Op nog geen 15 minuten rijden bevindt zich het Steinerbos. Een buitenspeeltuin in combinatie met een buiten-/binnenzwembad voor jong tot oud. Wanneer de zon volop schijnt is het heerlijk om op de ligweide uit te rusten terwijl de kinderen zich amuseren in het zwembad. Meer informatie over deze accommodatie vindt je terug op de website.
                    </p>
                   {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                </Col>
                
                
            </Row>
            <Row className="location-row">
            <Col sm={6} className="location-colom last">
                    <img src={require('../../assets/images/frontend/streekmuseum.jpg')}/>
                </Col>
                <Col sm={6} className="location-colom">
                    <h5>Een prachtige historie</h5>
                    <h2>Cultuur</h2>
                    <p>
                    Buiten de vele routes om te wandelen en fietsen in Elsloo, zijn er ook vele culturele activiteiten op loopafstand van de B&B. Zo kun je een bezoek brengen aan het prachtige kasteel van Elsloo of een van de andere verschillende musea in de buurt over de tweede wereldoorlog of het streekmuseum Elsloo. Je leert zo de omgeving nog beter kennen tijdens je bezoek aan B&B Bie Janssen. In de naastgelegen dorpen bevinden zich nog meer culturele activiteiten, benieuwd of er iets voor jou bij zit? Vraag het ons!
                    </p>
                   {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                </Col>
                
            </Row>
            </Container>
            </div>



        );
    }
};

export default SurroundingsComponent;