import React, { Component, useEffect } from 'react';
import ReactDOM, { render } from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddAppointment from './addAppointment';
import ChangeAppointment from './changeAppointment';

moment.locale('nl');
const localizer = momentLocalizer(moment);

const CustomEvent = ({event}) =>{
    var backgroundColor = '';
    var icon = '';

    console.log('event is:'+JSON.stringify(event));
    if(event.place === 'biejanssen.nl'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#27718F';
    }else if(event.place === 'booking.com'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#A560E0';
    }else if(event.place === 'airbnb.com'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#7AA898';
    }else if(event.place === 'bedandbreakfast.nl'){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#E06060';
    }
    else if(event.place == null || event.place == undefined){
        if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
        backgroundColor = '#27718F';
    }
   
    return(

        <span className="custom-event" style={{backgroundColor:backgroundColor}}>
         {/*<img className="icon-calendar" src={require(`../../assets/images/svg/${icon}.svg`)}/><br/><br/>*/}
         <br/>         
         <strong>{event.title}</strong><br/><br/>
        <strong>nr:</strong> {event.roomnumber}<br/><br/>
        <Link className="room-price-button" to={{pathname: `/agenda/aanpassen`,state: {eventData:event}}} >Wijzig</Link>

    

                
   
         
            

        </span>
    )
}


class CalendarIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           eventDb: [
            {title: "boeking", id: 1,resourceId: 1,start: new Date(2021,1,4,8,0,0),end: new Date(2021,1,5,13,0,0)},
            {title: "boeking", id: 1,resourceId: 1,start: new Date(2021,1,4,8,0,0),end: new Date(2021,1,5,13,0,0)}
           ],
           resourceMap:[],
           planAppointment: false,
           changeAppointment: false,
           eventChange: [],
           selectedDate:null,
           ViewDate:new Date(),
   
        };

        this.handlePlanAppointment = this.handlePlanAppointment.bind(this);
        this.changePlanAppointment = this.handleChangeAppointment.bind(this);
    }

    handlePlanAppointment(){
        console.log('lukt');
        this.setState({
            planAppointment: false
        });
    }

    handleChangeAppointment(){

        this.setState({
            changeAppointment: false,
        });

    
    }

    getEvents(){
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');
        
        ref.on('value' , snapshot => {
            this.setState({
                eventDb:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("month is:" +state);
            
  
                let newEvents = {email:state.email,phone:state.phone,roomnumber:state.roomnumber,title: state.title, id: state.id, resourceId: Number(state.resourceId),start: new Date(state.startYear,state.startMonth,state.startDay,15,0,0),end: new Date(state.endYear,state.endMonth,state.endDay,17,0,0), place: state.place, ispayed: state.ispayed, key: state.key};
        
                this.setState(prevState => ({
                   eventDb: [...prevState.eventDb, newEvents]
                }))  

             
        })});

    }

    getEmployees(){
    
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
                let employee = {resourceTitle: state.name, resourceId: state.resourceId};
                this.setState(prevState => ({
                    resourceMap: [...prevState.resourceMap, employee]
                }))  
        })});
    }

    onEventResize = (data) => {
        const { start, end } = data;
    
        this.setState((state) => {
          state.events[0].start = start;
          state.events[0].end = end;
          return { events: state.events };
        });
      };
    
      onEventDrop = (data) => {
        console.log(data);
      };

    componentWillMount() {
        this.getEmployees();
        this.getEvents();
let familyRoom = {resourceTitle:'Familiekamer',resourceId:4}
        this.setState(prevState =>({
            resourceMap:[...prevState.resourceMap,familyRoom]
        }))
    }

    deletedEvent(pEvent){
        const r = window.confirm("Weet je zeker dat je de afspraak wilt verwijderen?");
        if(r === true){
            var ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');
            ref.orderByChild('id').equalTo(pEvent.id).on('child_added', (snapshot) => {
                snapshot.ref.remove();
            });

            this.setState({
                eventDb: this.state.eventDb.filter(item => item.id !== pEvent.id)
            })
        }
    }

    changeEvent(event){
this.setState({
    eventChange: event,
    changeAppointment: true
})
    }




    saveInfo() {
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;

        var newPostKey = Firebase.database().ref().child('/q-sort').push().key;
        Firebase.database().ref('/' + dateStamp + '/' + newPostKey).set({
            companyName: this.state.companyName,
            participants: this.state.participants,
            questions: this.state.questions,
            stelling: this.state.stelling,
            key: newPostKey,
            user: 1,
        })

        this.setState({
            key: newPostKey,
        })
    }

    changeCalendarDate(date){
        console.log(new Date(date))
        this.setState({
            ViewDate: date
        })

    }


    render() {
        let today = new Date;
        let localizer = momentLocalizer(moment);
        return (
            <div>
             {this.state.changeAppointment ?    
            <ChangeAppointment handleChangeAppointment={this.handleChangeAppointment} eventData={{object:this.state.eventChange}}/>:null}
            {this.state.planAppointment ?    
            <AddAppointment handlePlanAppointment={this.handlePlanAppointment}/>:null}
            <Row>
                <Col lg="9" >


                  <div className="legenda" style={{backgroundColor:'#A560E0'}}>Booking.com</div>
                  
                  <div className="legenda" style={{backgroundColor:'#27718F'}}>Biejanssen.nl</div>
                  <div className="legenda-icon"><img src={require('../../assets/images/svg/warning-icon.svg')}/> Rekening open</div>
                  <div className="legenda-icon"><img src={require('../../assets/images/svg/payed.svg')}/> Betaald</div>
                </Col>
                <Col lg="3">
                <Link className="button-add-appointment" to={{pathname: `/agenda/toevoegen`}} >Voeg toe</Link>

                </Col>
                <Col lg="12" className="calendar-dashboard">
                    <input type="date" onChange={(event)=> this.changeCalendarDate(event.target.value)} />
                <Calendar
                showMultiDayTimes
                localizer={this.localizer}
                defaultDate={new Date()}
                date={this.state.ViewDate}
                defaultView="day"
                events={this.state.eventDb}
                localizer={localizer}
                onEventDrop={this.onEventDrop}
                onEventResize={this.onEventResize}
                resizable
                min={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 9)}
                max={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 23)}
                resources={this.state.resourceMap}
                style={{ height: "100vh" }}
                views={['day','work_week']}
                timeslots={1}
                
                /*onSelectEvent={event => this.props.navigation.navi}*/
                step={60}
                resources={this.state.resourceMap}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                culture={moment.locale('nl')}
                messages={{today:'Vandaag',previous:'<', next:'>',month:'Maand',week:'Week',day:'dag'}}
                components={{
                    event: CustomEvent
                  }}
        />
               
                </Col>

                
            </Row>

            </div>

        );
    }
};

export default CalendarIndex;