import React, {useCallback, useContext} from "react";
import { Container, Row, Col } from "shards-react";
import Firebase from 'firebase';
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import Login from "../components/authentication/login";
import SignInCom from "../components/authentication/signin";

import desktopImage from '../assets/images/achtergrond.svg';
import mobileImage from '../assets/images/achtergrond.svg';


const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
const SignInScreen = (props) => (
  <Container fluid className="main-content-container px-4 modal-background" >
      {/* 
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen" subtitle="Q-sort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    */}

    <SignInCom />
    
  </Container>
);

export default SignInScreen;
