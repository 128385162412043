import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import AboutUsComponent from "../../components/Frontend/AboutUsComponent";
import BussinesComponent from "../../components/Frontend/bussinesComponent";
import HomeComponent from "../../components/Frontend/HomeComponent";
import SurpriseboxComponent from "../../components/Frontend/SurpriseboxComponent";
import SurroundingsComponent from "../../components/Frontend/SurroundingsComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const BussinesView = (props) => (

  
  <Container fluid  className="sub-page">
 <HeaderView />
    <BussinesComponent />
    <FooterView />

  
  </Container>
);

export default BussinesView;
