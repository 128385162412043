import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import uuid from 'react-uuid'

import Firebase from 'firebase';



class PaymentEndPointFamilyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alreadyExistDates:[],
            allDates:[],
            dates:[],
            events:[],
            eventDb:[],
            writes:0,
            earlier:true


        };
    }

    getDaysArray(start, end){
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };

    addDays = (date, days = 1) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      };
      
      dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = new Date(startDate);
      
        while (currentDate <= new Date(endDate)) {
          dateArray.push(new Date(currentDate));
          // Use UTC date to prevent problems with time zones and DST
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }
      
        return dateArray;
      }


      
      checkDate(item){

        let count = this.state.dates.length-1;


        for(let i = 0; i < count; i++){
            if(item.day === this.state.dates[i].getDate() && item.month===this.state.dates[i].getMonth()&& item.year===this.state.dates[i].getFullYear()){
  

                let newAmount = item.amount+1;
               
               
             
                Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+localStorage.getItem('serviceId')+'/dates/'+item.id).set({
                    amount: newAmount,
                    year:  item.year,
                    id: item.id,
                    month: item.month,
                    day: item.day
                }).then((data)=>{
                    console.log('done')
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }else{
                //this.newItem(item)
                

  
                var newPostKey = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/'+localStorage.getItem('serviceId')+'/dates/').push().key;
               /*Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+localStorage.getItem('serviceId')+'/dates/'+newPostKey).set({
                    amount: 1,
                    year:  this.state.dates[i].getFullYear(), 
                    id: newPostKey,
                    month:this.state.dates[i].getMonth(),
                    day:this.state.dates[i].getDate()
                }).then((data)=>{
                    console.log('done')
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })*/
            
            }}
        
       



        


      }


   /*  writeAllDates(){
    for(let y = 2021; y< 2026;y++){
        for(let m = 0; m < 12; m++){
        for(let d = 1; d< 32;d++){
                var newPostKey = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates/').push().key;
                Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/-wwouodpihdnqe/dates/'+newPostKey).set({
                    amount: 0,
                    year:  y, 
                    id: newPostKey,
                    month:m,
                    day:d
                }).then((data)=>{
                    console.log('done')
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }
            }
        }
    }*/

    generateBookingId(){
        let lengthIs = 0;

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        return 'B'+year+month+this.state.numberBooking
    }

 componentDidMount(){


    let date = new Date(localStorage.getItem('startYear'),Number(localStorage.getItem('startMonth'))-1,localStorage.getItem('startDay'));



        if(date.getDay()  == 0 || date.getDay() == 5){
            this.setState({
                earlier:false,
            })
        }

    console.log('here');

    
   let dates =  this.dateRange(localStorage.getItem('fullStartDate'),localStorage.getItem('fullEndDate'));

    this.setState({
        dates: this.dateRange(localStorage.getItem('fullStartDate'),localStorage.getItem('fullEndDate'))
    })
        let newDateArray =[];
    if(localStorage.getItem('bookingID')){
            let datesFirebase = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+localStorage.getItem('roomdataId')+'/dates');
           datesFirebase.once('value' , snapshot => {

            this.setState({alreadyExistDates:[]})
             
                snapshot.forEach((childSnap) => {
    
                let state = childSnap.val();

                this.setState(prevState => ({
                    alreadyExistDates: [...prevState.alreadyExistDates, state],
                  }));

        

                this.checkDate(state);
              
              });

            
            })


    console.log('here 2');
      

           // var bookingsId = 'B'+ toString(year) + toString(month) + toString(numberAmount);
    
        var newPostKey = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/events').push().key;
              Firebase.database().ref('/clients/-uhfwoebfwioudb/events/'+newPostKey).set({
                key: newPostKey,
                email:localStorage.getItem('mailClient'),
                end: localStorage.getItem('endYear')+','+localStorage.getItem('endMonth')+','+localStorage.getItem('endDay'),
                id: localStorage.getItem('bookingID'),
                bookingsnumber: localStorage.getItem('bookingID'),//aanpaasen
                ispayed: true,
                phone:localStorage.getItem('phoneClient'),
                place:'biejanssen.nl',
                resourceId:Number(localStorage.getItem('resourceId')),//aanpaasen
                startYear: localStorage.getItem('startYear'),
                startMonth:localStorage.getItem('startMonth'),
                startDay:localStorage.getItem('startDay'),
                endYear: localStorage.getItem('endYear'),
                endMonth:localStorage.getItem('endMonth'),
                endDay:localStorage.getItem('endDay'),
                title: localStorage.getItem('nameClient'),
                type:'biejanssen.nl',
                singlePrice: parseFloat(localStorage.getItem('roomdataPrice')),
                totalNights: parseFloat(localStorage.getItem('totalNights')),
                persons:localStorage.getItem('guests')
                
            }).then((data)=>{
                localStorage.removeItem('bookingID') 
            }).catch((error)=>{
                //error callback
           
            })
        }
       
    }




    render() {  

        
        return (
      
                <Row className="thank-you-page">
                    <Col sm={2}></Col>
                   <Col sm={8}  className="thank-you-page-container">
                       <h2 className="thanks-text">Dank voor je boeking!</h2>
                       <hr />
                       <Row>
                           <Col sm={6} className="thank-you-page-colum" >
                               <h3>Goed om te weten</h3>
                               <ul>
                               <li>Je krijgt van ons nog een bevestigingsmail</li>
                                <li>We staan voor uw klaar vanaf 15 uur. Inchecken kan tot 18 uur. Andere tijden alleen in goed overleg.</li>
                                   <li>Heb je vragen over het ontbijt? Stuur dan een <a href="mailto:info@biejanssen.nl">mail</a> of bel!</li>
                                   <li>Bekijk hier de <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2FVoorwaarden%20en%20huisregels%20B%26B%20Bie%20Janssen%20april%202021.pdf?alt=media&token=8780a6c9-734c-4b2f-b5fb-c46f2124caf6">Algemene Voorwaarden</a></li>
                                   <li>Speciale wensen? Geef ze <a href="mailto:info@biejanssen.nl">hier</a> door</li>
                               </ul>
                            </Col>
                           <Col sm={6} className="thank-you-page-colum">
                               <h3>Routebeschrijving</h3>
                               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2513.7555937256793!2d5.762323315372197!3d50.94673285936211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0c1cf8202fa5b%3A0x9b674d3a541840d1!2sB%26B%20Bie%20Janssen!5e0!3m2!1snl!2snl!4v1614591265714!5m2!1snl!2snl" ></iframe>
                               
                            </Col>
                       </Row>
                       
                       <Row>
                           <Col sm={4}></Col>
                           <Col sm={4} className="social-icons">
                                <ul>
                                    <li><a href="https://www.facebook.com/BenBBieJanssen/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/bie_janssen/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/b-b-bie-janssen/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                           </Col>
                           <Col sm={4} className="back-link">
                            <Link className="frontend-button-dark" to={{pathname: `/home`,state: {}}} >Naar home</Link>
                           </Col>
                       </Row>
                   </Col>
                   <Col sm={3}></Col>
                   
                </Row>
                
            
            
       
        );
    }
};

export default PaymentEndPointFamilyComponent;
