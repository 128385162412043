

export default function() {
  return [
    /*{
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Fdashboard-icon.svg?alt=media&token=679c512a-47ad-4d6e-96f9-5c740e5e3f4c',
      htmlAfter: ""
    },*/
    {
      title: "Agenda",
      htmlBefore: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Fcalendar-icon.svg?alt=media&token=c313ef17-d5bf-4457-aae9-35f0ace46715",
      to: "/agenda",
    },
    {
      title: "Kassa",
      htmlBefore: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Fkassa-icon.svg?alt=media&token=437f0000-435e-42eb-a009-01a6f5c8aa7c",
      to: {pathname:"/kassa",state:{done:false}},
    },
    {
      title: "Rekeningen",
      htmlBefore: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Frekeningen-icon.svg?alt=media&token=28d44023-034b-465a-802c-ecd4bde57ad6",
      to: {pathname:"/rekeningen"},
    },
    {
      title: "Vakanties",
      htmlBefore: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Fvakantie.svg?alt=media&token=a0f0649e-5e5d-40ba-a6fc-2d08163bbe0a",
      to: {pathname:"/vakantie"},
    },
   /* {
      title: "Financiën",
      htmlBefore: "financien-icon",
      to: {pathname:"/financien",state:{done:false}},
    },
    {
      title: "Klanten",
      htmlBefore: "clients",
      to: {pathname:"/klanten",state:{done:false}},
    },*/
    {
      title: "Instellingen",
      htmlBefore: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Fclients.svg?alt=media&token=21143d43-5ec2-436a-9e71-132be553f328",
      to: {pathname:"/instellingen"},
    },
    /*{
      title: "Klanten",
      htmlBefore: '<i class="material-icons">calendar_today</i>',
      to: {pathname:"/kassa",state:{done:false}},
    },*/
   
   /* Pas in fase 2
    {
      title: "Rapporten",
      htmlBefore: '<i class="material-icons">insert_chart_outlined</i>',
      to: "/tables",
    },
  */
    
  ];
}
