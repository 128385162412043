import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';
import BookingCalendar from 'react-booking-calendar';
import { Link } from 'react-router-dom';
import PropagateLoader from "react-spinners/ClipLoader";
import Firebase from 'firebase';


class RoomOverviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms: [
            ],
            facility: [],
            prices: [],
            images: [],
            eventDb: [],
            loading: true
        };
    }

    componentWillMount() {


        window.scrollTo(0, 0)
        let rooms = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/');
        rooms.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                this.setState(prevState => ({
                    rooms: [...prevState.rooms, state],
                }));
                let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/facilities');
                facilities.on('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let stateFac = childSnap.val();

                        let facility = { roomId: state.id, name: stateFac.name }
                        this.setState(prevState => ({
                            facility: [...prevState.facility, facility],
                        }));


                    });

                })

                let guests = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/prices');
                guests.on('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let statePrice = childSnap.val();
                        let newPrice = { roomId: state.id, id: statePrice.id, person: statePrice.person, price: statePrice.price }

                        this.setState(prevState => ({
                            prices: [...prevState.prices, newPrice],
                        }));


                    });

                })

                let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/images');
                images.on('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let stateImage = childSnap.val();
                        console.log("room is:" + JSON.stringify(state.id));
                        console.log("room is:" + JSON.stringify(stateImage.url));

                        let newImages = { id: state.id, url: stateImage.url, type: stateImage.type }
                        this.setState(prevState => ({
                            images: [...prevState.images, newImages],
                        }));


                    });
                    this.setState({
                        loading: false
                    })

                })
            });


        })

    }


    render() {


        return (
            <div className="home-page">
                <Row className="room-overview-row">
                    <Col sm={12} className="contact-colum">
                        <h2>Ons kameroverzicht</h2>
                        <p>De sfeervolle kamers zijn rustig gelegen in het achterste gedeelte van ons huis. Verdeeld over 2 etages zijn er 8 kamers van verschillende groottes met elk een eigen badkamer. De kamers zijn landelijk en sfeervol ingericht en je hebt de beschikking over een luxe boxspring van 1.60 meter bij 2.00 meter. Handdoeken krijg je van ons en ook doucheschuim is voorhanden. Je bed is uiteraard al opgemaakt. Een föhn hebben we voor je bij de receptie. Tevens hebben we een fijne grote familiekamer voor 4-6 personen.Heb je een vraag over de mogelijkheden of over een kamer? Bel dan naar: <a href="tel:0625226150">06 25226150</a></p>

                    </Col>
                </Row>
                {this.state.loading ?
                    <Col sm={12} className="spinner-col">
                        <PropagateLoader />
                    </Col>
                    :
                    <div className="room-overview">

                        {this.state.rooms.sort((a, b) => a.sort - b.sort).map((item, i) => (
                            <Row className="room-row">
                                <Col md={4} className="room-image">
                                    <Link to={{ pathname: `/kamer-overzicht/details`, state: { roomName: item } }} >{this.state.images.filter(image => item.id == image.id && image.type == 'active').slice(0, 1).map((image) => (
                                        <img src={image.url} />
                                    ))}</Link>

                                </Col>
                                <Col md={8} className="room-information">
                                    <Row>
                                        <Col sm={7} className="border-right">
                                            <h2>{item.name}</h2>
                                            <p className="amount-people"><img alt='Het logo van Picoo' src={require('../../assets/images/icons/person-icon.png')} />Max. {this.state.prices.sort((a, b) => (a.person > b.person) ? 1 : -1).filter(guests => item.id == guests.roomId).slice(-1).map((item, i) => (item.person))} Personen</p>
                                            <ul>

                                                {this.state.facility.filter(facility => item.id == facility.roomId).map((facility, i) => (
                                                    < li><i class="fas fa-check-circle"></i> {facility.name}</li>
                                                ))}

                                            </ul>

                                            <p className="room-information-desc">
                                                {item.desc.slice(0, 200)} <Link to={{ pathname: `/kamer-overzicht/details`, state: { roomName: item } }} >Lees meer</Link>
                                            </p>
                                        </Col>


                                        <Col sm={5} className="room-price room-prices">
                                            <p>Vanaf</p>
                                            {this.state.prices.filter(price => item.id === price.roomId).sort((a, b) => a.person - b.person).map((price, index) => (
                                                <div key={index} className="price-item">

                                                    <h3>€ {price.price.toFixed(2)}</h3>
                                                    <p>{price.person} p.p.n.</p>
                                                </div>
                                            ))}

                                            <Link className="room-button" to={{ pathname: `/kamer-overzicht/details`, state: { roomName: item } }} >Toon beschikbaarheid</Link>
                                        </Col>
                                    </Row>


                                </Col>

                            </Row>
                        ))}

                        <Row className="room-row">
                            <Col md={4} className="room-image">
                                <Link to={{ pathname: `/kamer-overzicht/details/familie-kamer`, state: { roomName: { test: 'hello' } } }} ><img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FFamiliekamer%202%20personen-min-min.jpg?alt=media&token=32fa99d7-3b9a-4899-bb49-ca2058f84af5"} /> </Link>

                            </Col>
                            <Col md={8} className="room-information">
                                <Row>
                                    <Col sm={7} className="border-right">
                                        <h2>Familiekamer</h2>
                                        <p className="amount-people"><img alt='Het logo van Picoo' src={require('../../assets/images/icons/person-icon.png')} />Max. 6 Personen</p>
                                        { /* <ul>
      
       {  this.state.facility.filter(facility => item.id == facility.roomId).map((facility,i)=>(
            < li><i class="fas fa-check-circle"></i> {facility.name}</li>
       ))}
          
       </ul>*/}

                                        <p className="room-information-desc">
                                            Op de 3e etage is de gezellige familiekamer gelegen, waar je met 4-6 personen kan overnachten. De familiekamer bestaat uit een kleine gezellige woonkamer, 3 slaapkamers, een badkamer met douche, wastafel en toilet en op de gang ligt nog een separaat toilet.
                                            <Link to={{ pathname: `/kamer-overzicht/details/familie-kamer`, state: { roomName: 'hello' } }} > Lees meer</Link>
                                        </p>
                                    </Col>


                                    <Col sm={5} className="room-price">
                                        <p>Vanaf</p>
                                        <h3>
                                            € 449,00
                                        </h3>
                                        <p>Voor 4 personen per weekend</p>

                                        <Link className="room-button" to={{ pathname: `/kamer-overzicht/details/familie-kamer`, state: { test: 'hello' } }} >Toon beschikbaarheid</Link>
                                    </Col>
                                </Row>


                            </Col>

                        </Row>




                    </div>
                }
            </div>
        );
    }
};

export default RoomOverviewComponent;
