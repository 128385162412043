import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";


class ExtraComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {

        return (

            <div className="home-page extra-margin-bottom">
                <Row className="surroundings-row">
                    <Col sm={12} className="surroundings-colum">
                        <h2>Extra's</h2>


                    </Col>
                </Row>


                <Container>
                    <Row className="location-row ">


                        <Col sm={6} className="location-colom ">
                            <h5></h5>
                            <h2>Lange afstandswandeling</h2>
                            <p>
                                Hou je van wandelen? Dan mag de Lange afstandswandeling zeker niet op je lijstje ontbreken. Deze grensoverschrijdende tocht van 137 km verbindt de 11 gemeenten in het RivierPark Maasvallei en brengt je langs de mooiste landschappen en gezellige dorpjes. 1 van die gezellige dorpjes is Elsloo en daar ligt onze B&B op slechts 1,5 kilometer van de route. Haal- en brengservice is eventueel mogelijk. Ook verzorgen we graag een lunchpakket voor je, als je weer op pad gaat. Neem gerust contact op om de mogelijkheden te bespreken.<br /><br />
                                De lange afstandswandeling werd in april 2021 verkozen als 'Beste wandelroute van de Benelux'. Meer informatie over de lange afstandswandeling vind je op <a target="_blank" href="https://www.rivierparkmaasvallei.eu/nl/langeafstandswandeling">de website van Rivierpark Maasvallei</a>
                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>
                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flange-afstandswandeling.jpg?alt=media&token=f1333651-10b7-4532-86cb-1df5b8755f64"} />
                        </Col>


                    </Row>
                    <Row className="location-row">
                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FSteinpas.jpg?alt=media&token=54dcfdd6-9821-4d63-9c6b-36e499e7bb8e"} />
                        </Col>
                        <Col sm={6} className="location-colom">
                            <h5></h5>
                            <h2>SteinPas cadeau</h2>
                            <p>
                                B&B Bie Janssen geeft je bij minimaal 2 overnachtingen de SteinPas met een saldo van € 5 cadeau. Je kunt deze besteden bij ruim 70 ondernemers in de gemeente Stein zoals restaurants en (kleding)winkels. Vraag naar de kaart bij de receptie, mocht je deze niet ontvangen hebben bij incheck. Mocht je hem bij uitchecken nog niet besteed hebben, dan kan je het saldo uitgeven aan een bierpakketje, honing of jammetjes in ons winkeltje.
                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>

                    </Row>
                    <Row className="location-row desktop">
                        <Col sm={6} className="location-colom lunch-to-go">
                            <h5></h5>
                            <h2>Fietsen te huur</h2>
                            <p>
                                Wij hebben bij onze B&B vier elektrische fietsen te huur. Wil je dus de mooie verrassende omgeving van Elsloo verkennen? Geef dit dan op tijd aan, zodat we de fietsen voor je kunnen reserveren. Wil je ook de kids mee achterop meenemen, dan is dat geen probleem want we hebben een fietsstoel en/of een zitje voor achterop.
                                <br /><br />
                                Fietsen huren voor een dag €27,50 per fiets<br />
                                Fietsstoel huren voor de kids €5 per fiets<br />
                                Borg voor de fiets is €50 per fiets. Dit dient contant en gepast te worden betaald.


                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>
                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2Fextras%2FFietsen.jpg?alt=media&token=c8ef8df7-3985-4a17-b22e-5deaf6dd23e8"} />
                        </Col>


                    </Row>
                    <Row className="location-row mobile">

                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2Fextras%2FB%26B%20Bie%20Janssen-65.jpg?alt=media&token=cfc89eb5-4672-4d45-bdb4-8452241d79e7"} />
                        </Col>
                        <Col sm={6} className="location-colom lunch-to-go">
                            <h5></h5>
                            <h2>Verwenpakket en/of bloemen op je kamer</h2>
                            <p>
                                Bij aankomst in onze B&B staat een heerlijk verwenpakket op je kamer klaar.
                                Dit verwenpakket bestaat uit een fles wijn (de kleur is naar keuze) of twee speciaalbiertjes. Natuurlijk hoort hier ook nog iets hartigs om te knabbelen bij. Dit voor slechts €10,50
                                <br /> <br />
                                Wil je je kamergenoot verrassen?<br /><br />
                                Dan zorgen wij dat er een mooi plukboeketje op de kamer staat met eventueel een kaartje. Dit voor slechts €12,50
                                <br /><br />
                                Bestellen kan door ons te <a href="mailto:info@biejanssen.nl">mailen</a> of te bellen: 06-25226150

                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>


                    </Row>
                    <Row className="location-row">
                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2FWandelen-min.jpg?alt=media&token=c5506811-0388-477a-8db3-af9a30c0d513"} />
                        </Col>
                        <Col sm={6} className="location-colom">
                            <h5></h5>
                            <h2>Wandelen in en rondom Elsloo</h2>
                            <p>
                                Vanuit onze B&B kun je prachtig wandelen. Je kunt vanuit hier letterlijk alle kanten op. Bij de ingang van de B&B vind je verschillende routes die je naar en langs mooie plekjes brengen. Vooral de Gedichtenroute en de Natuurroute kunnen we zeer aanbevelen.




                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>

                    </Row>



                    <Row className="location-row desktop">
                        <Col sm={6} className="location-colom">
                            <h5></h5>
                            <h2>Vissen</h2>
                            <p>
                                Wat is nou nog ontspannender dan vanuit onze B&B met je vishengel over je schouder naar de visvijver bij het kasteel te kunnen lopen. Laten wij nu ook dagvergunningen verkopen om dit mogelijk te maken. Een dagvergunning kost slechts €5 en hiervoor kan je wel een paar uurtjes ontspannen aan de waterkant. Jaar- en dagvergunningen zijn sowieso voor iedereen te koop in onze B&B.

                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>
                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2Fextras%2FVissen.JPG?alt=media&token=5f522d20-424d-4d27-9efc-1ea19d4d1f86"} />
                        </Col>


                    </Row>
                    <Row className="location-row mobile">

                        <Col sm={6} className="location-colom">
                            <img src={"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/images%2Fextras%2FVissen.JPG?alt=media&token=5f522d20-424d-4d27-9efc-1ea19d4d1f86"} />
                        </Col>
                        <Col sm={6} className="location-colom">
                            <h5></h5>
                            <h2>Vissen</h2>
                            <p>
                                Wat is nou nog ontspannener dan vanuit onze B&B met je vishengel over je schouder naar de visvijver bij het kasteel te kunnen lopen. Laten wij nu ook dagvergunningen verkopen om dit mogelijk te maken. Een dagvergunning kost slechts €5 en hiervoor kan je wel een paar uurtjes ontspannen aan de waterkant. Jaar- en dagvergunningen zijn sowieso voor iedereen te koop in onze B&B.
                            </p>
                            {/* <Link className="frontend-button-dark" to={{pathname: `/kamer-overzicht/details`}} >Toon beschikbaarheid</Link>*/}
                        </Col>


                    </Row>


                </Container>
            </div>



        );
    }
};

export default ExtraComponent;