import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import BookingComponent from "../../components/Booking/BookingComponent";


const BookingView = (props) => (

  
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Boeken" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <BookingComponent />
    
  </Container>
);

export default BookingView;
