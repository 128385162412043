import React from "react";
import { Container, Row, Col } from "shards-react";
import BillsComponent from "../../components/Bills/BillsComponent";

import PageTitle from "../../components/common/PageTitle";



const BillsView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Rekeningen" subtitle="Cashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <BillsComponent />
  </Container>
);

export default BillsView;
