import React, { Component, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link, Redirect } from 'react-router-dom';
import Firebase from 'firebase';
import ChooseMethod from '../../views/ChooseMethod';


class ChashdeskTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentData:[],
            treatments:[],
            employee:'',
            price:0,
            totalChange:0,
            transactionSet: false,
            paymentMethod:Boolean,
            isSaleSet:'',
            typeSale:'',
            regularPrice:0,
        };
    }

    componentWillMount(){
        this.setState({
            employee: this.props.paymentData.employee,
            price: this.props.paymentData.price,
            treatments: this.props.paymentData.treatments,
            paymentMethod: this.props.paymentData.paymentMethod,
            isSaleSet: this.props.paymentData.isSaleSet, 
            typeSale: this.props.paymentData.typeSale, 
            regularPrice: this.props.paymentData.regularPrice
        })

        console.log("isSaleSet:"+ this.props.paymentData.isSaleSet);
     
        
    }

    calculateChange(e){
        var changeReceived = e.target.value;
        var totalPrice = this.state.price;

        this.setState({
            totalChange: changeReceived - totalPrice,
        })
    }

    transactionDone(){
        this.setState({
            transactionSet: true
        })
    }

    render() {
        if(this.state.transactionSet){
            return <Redirect to={{pathname: `/transactie-klaar`,state: {employee:this.state.employee, treatments: this.state.treatments, price: this.state.price, paymentMethod: this.state.paymentMethod, isSaleSet: this.state.isSaleSet, typeSale: this.state.typeSale, regularPrice:this.state.regularPrice}}} />
        }
        return (
            <div>
            <Row>
                <Col lg={2}></Col>
                {this.state.method ?
                <Col lg={8} className="paymentmethod-chaskdesk">
                       
                        <h2>Contact afrekenen</h2>
                        <h3>Totaal te voldoen</h3>
                        <p>€ {this.state.price.toFixed(2)}</p>
                        <Row>
                            <Col md="3">
                                <span>Ontvangen:</span>
                            </Col>
                            <Col md="3">
                                <span><input type="number"  name="appt" onChange={event => this.calculateChange(event)} /></span>
                            </Col>
                            <Col md="3">
                                <span>- {this.state.price.toFixed(2)}</span>
                            </Col>
                            <Col md="3">
                                <span>= € {this.state.totalChange.toFixed(2)} wisselgeld</span>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="3"></Col>
                            <Col md="6" className="paymentmethod-buttons mt-10">
                            <Button variant="primary" className="button-chashdesk"  onClick={() => this.transactionDone()}>Transactie voldoen</Button>
                            </Col>
                        </Row>

                </Col>
                :
                <Col lg={8} className="paymentmethod-chaskdesk">
                       
                        <h2>Afrekenen met de pin</h2>
                        <h3>Totaal te voldoen</h3>
                        <p>€ {this.state.price.toFixed(2)}</p>
                        <Row>
                            <Col md="3"></Col>
                            <Col md="6" className="paymentmethod-buttons mt-10">
                            <Button variant="primary" className="button-chashdesk"  onClick={() => this.transactionDone()}>Transactie voldoen</Button>
                            </Col>
                        </Row>

                </Col>
                }
            </Row>

            </div>

        );
    }
};

export default ChashdeskTransaction;