import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';

import DeleteIcon from "../../assets/images/svg/times-solid.svg"
import InvoiceIcon from "../../assets/images/svg/invoice.svg"
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';





class BillDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            email:'',
            phone:'',
            priceNight:'',
            amountNights:'',
            amountPeople:'',
            total: '',
            orderId:''
      };
    }

    componentDidMount(){
        console.log(this.props.billData.billData.bookingsnumber);
        this.setState({
            name:this.props.billData.billData.title,
            email:'joppe@pixelpros.nl',
            phone:this.props.billData.billData.phone,
            priceNight:this.props.billData.billData.singlePrice,
            amountNights: this.props.billData.billData.totalNights,
            amountPeople: this.props.billData.billData.persons,
        
            orderId:this.props.billData.billData.bookingsnumber

        })
//this.ResendConformationMail()

    }

    ResendConformationMail(){
        let headers = new Headers();

        let totalPrice = parseFloat((this.state.priceNight*this.state.amountNights)+(2*this.state.amountPeople*this.state.amountNights)).toFixed(2).toString();

        console.log(totalPrice);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
   // headers.append('Origin','http://localhost:3000');
       headers.append('Origin','https://biejassen.nl/')
     headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://biejanssen.nl/RegenerateCompletion", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                name: this.props.billData.billData.title,
                email: this.props.billData.billData.email,
                phone:this.props.billData.billData.phone,
                priceNight: this.props.billData.billData.singlePrice,
                amountNights: this.props.billData.billData.totalNights,
                amountPeople:  this.props.billData.billData.persons,
                total: totalPrice,
                orderId: this.props.billData.billData.bookingsnumber
            })
          })
          .then(res => {
            toast.success('De bevestiging is opnieuw verstuurd!');
          })
    
    }


   


    render() {  

        
        return (
            <div>
       <Toaster />
                <Row >
                    <Col sm={4} className="bill-detail-list">
                        <ul>
                            <li>Boekingsnummer:<span className="bill-detail-list-element">{this.props.billData.billData.id}</span></li>
                            <li>Naam:<span className="bill-detail-list-element">{this.props.billData.billData.title}</span></li>
                            
                            <li>Totaal:<span className="bill-detail-list-element"> € {parseFloat((this.props.billData.billData.singlePrice*this.props.billData.billData.totalNights)+(2*this.props.billData.billData.persons*this.props.billData.billData.totalNights)).toFixed(2)}</span></li>
                        </ul>
                    </Col>
                    <Col sm={4}>
                    <Button onClick={()=> this.ResendConformationMail()} className="button-create-appointment aling-right">Bevestiging versturen</Button> 
                    </Col>
                    
                <Col sm={12} className="bills-component">

<table className="bills-table"> 
  <tr>
    <th>Aantal personen</th>
    <th>Aantal nachten</th>
    <th>Betaald</th>
    <th>Totaal</th>
  </tr>

    <tr>
      <td>{this.props.billData.billData.persons}</td>
      <td>{this.props.billData.billData.totalNights}</td>
      <td>{this.props.billData.billData.ispayed ?<img className="icon-calendar" src={require(`../../assets/images/svg/payed.svg`)}/>:<img className="icon-calendar" src={require(`../../assets/images/svg/warning-icon.svg`)}/>}</td>
      <td>€ {parseFloat((this.props.billData.billData.singlePrice*this.props.billData.billData.totalNights)+(2*this.props.billData.billData.persons*this.props.billData.billData.totalNights)).toFixed(2)}</td>
  </tr>
                   
 </table>
 </Col>

 <Col sm={12}>
 <Link className="back-link" to={{pathname: `/rekeningen`}} >Terug</Link>
 </Col>
 <Col sm={12} className="black-space">

 </Col>
                </Row>
                
            
            
            </div>
        );
    }
};

export default BillDetailComponent;
