import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function PaymentForm() {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log("betaalmethode is:"+event.target.value);
    localStorage.setItem('betaalmethode', event.target.value)
  };




  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Selecteer betaalmethode
      </Typography>
      <Grid container spacing={3}>
      <FormControl component="fieldset" className="issuer-form">
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
    
        <FormControlLabel value="iDeal" control={<Radio />} label={<span className="betaalmethode"><img alt='Het logo van Picoo' src={require('../../assets/images/Mollie Icons/Payment methods/ideal.svg')}/><p>iDEAL</p> </span>} />
        <FormControlLabel value="GiroPay" control={<Radio />} label={<span className="betaalmethode"><img alt='Het logo van Picoo' src={require('../../assets/images/Mollie Icons/Payment methods/giropay.svg')}/><p>GiroPay</p> </span>} />
        <FormControlLabel value="SoFort" control={<Radio />} label={<span className="betaalmethode"><img alt='Het logo van Picoo' src={require('../../assets/images/Mollie Icons/Payment methods/sofort.svg')}/><p>SoFort</p> </span>} />
      </RadioGroup>
    </FormControl>
        
      </Grid>
    </React.Fragment>
  );
}