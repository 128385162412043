import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import AboutUsComponent from "../../components/Frontend/AboutUsComponent";
import ContactComponent from "../../components/Frontend/ContactComponent";
import HomeComponent from "../../components/Frontend/HomeComponent";
import PayBreaffastOnlineScreenComponent from "../../components/Frontend/PayBreaffastOnlineScreenComponent";
import PayBreakfastComponent from "../../components/Frontend/PayBreakfastComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const PayBreakfastOnlineView = (props) => (

  
  <Container fluid  className="sub-page">
 <HeaderView />
    <PayBreaffastOnlineScreenComponent eventData={props.location.state}/>
    <FooterView />

  
  </Container>
);

export default PayBreakfastOnlineView;
