import React from "react";
import { Container, Row, Col } from "shards-react";
import PaymentBreakfastEndPointComponent from "../../components/paymentEndpoint/PaymentBreakfastEndPoint";
import PaymentEndPointComponent from "../../components/paymentEndpoint/PaymentEndPointComponent";
import PaymentManualBookingEndPointComponent from "../../components/paymentEndpoint/PaymentManualBookingEndPoint";
import FooterView from "../Footer";
import HeaderView from "../Header";


const PaymentsBookingManView = () => (
  <Container fluid className="main-content-container sub-page">
    <HeaderView />
      <PaymentManualBookingEndPointComponent />
      <FooterView />
    
  </Container>
);

export default PaymentsBookingManView;
