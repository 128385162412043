import React, { Component, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link, Redirect } from 'react-router-dom';
import Firebase from 'firebase';
import ChooseMethod from '../../views/ChooseMethod';


class ChashdeskCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentData:[],
            treatments:[],
            employee:'',
            price:0,
            totalChange:0,
            transactionError: false,
            transactionSucces:false,
            paymentMethod:Boolean,
            isSaleSet:'',
            typeSale:'',
            regularPrice:0,
        };
    }

    componentWillMount(){
        this.setState({
            employee: this.props.paymentData.employee,
            price: this.props.paymentData.price,
            treatments: this.props.paymentData.treatments,
            paymentMethod: this.props.paymentData.paymentMethod,

            regularPrice: this.props.paymentData.regularPrice
        })

        console.log("treatments:"+ this.props.paymentData.treatments);
        console.log("treatments state:"+ this.state.treatments);
        console.log("priceTotal:"+ this.props.paymentData.regularPrice);
    
    }

    writeDataToDb(){
       
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        console.log("Treatment: "+JSON.stringify(this.props.paymentData.treatments))
        
        var newPostKey = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/cashdesk/').push().key;
          Firebase.database().ref('/clients/-uhfwoebfwioudb/content/cashdesk/'+newPostKey).set({
            payId: newPostKey,
            paymentData: dateStamp,
            paymentMethod: this.state.paymentMethod,
            totalPrice: this.state.price,
           
            regularPrice: this.state.regularPrice
        }).then(()=>{
            this.setState({transactionSucces:true})
        })

        //this.setState({transactionSucces:true});
    }



    render() {

        if(this.state.transactionError){
            return <Redirect to={{pathname: `/kies-betaalmethode`,state: {employee:this.state.employee, treatments: this.state.treatments, price: this.state.price}}} />
        }
        if(this.state.transactionSucces){
            return <Redirect to={{pathname: `/kassa`,state: {done:true}}} />
        }



        return (
            <div>
            <Row>
                <Col lg={2}></Col>

                <Col lg={8} className="paymentmethod-chaskdesk">
                       
                        <h2>Is de transactie gelukt?</h2>
                        <p>rem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed sollicitudin tortor. Maecenas viverra vitae nisi at maximus. Donec id porta libero. Pellentesque cursus rhoncus nibh sit amet lacinia. Phasellus auctor vel tortor eget consequat. Nulla consectetur consequat ex laoreet scelerisque. Vestibulum dignissim vitae urna a congue.</p>
                        <Row>

                            <Col md="6" className="paymentmethod-buttons mt-10">
                            <Button variant="primary" className="button-chashdesk"  onClick={() => this.setState({transactionError:true})}>Nee</Button>
                            </Col>
                            <Col md="6" className="paymentmethod-buttons mt-10">
                            <Button variant="primary" className="button-chashdesk"  onClick={() => this.writeDataToDb()}>Ja</Button>
                            </Col>
                        </Row>

                </Col>
                
            </Row>

            </div>

        );
    }
};

export default ChashdeskCheck;