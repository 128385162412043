import React, { Component, useEffect } from 'react';

import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';

import toast, { Toaster } from 'react-hot-toast';



class VacationIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services:[],
            totalNights:0,
            bookings : new Date(),
            selected:[],
            ids:[],eventSelected:[],
            vacations:[]
   
        };

       
    }
    getVacations(){
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/vacationdays/');
        
        ref.on('value' , snapshot => {
            this.setState({
                vacations:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
        
                
                this.setState(prevState => ({
                    vacations: [...prevState.vacations, state]
                }))  

             
        })});

    }

   

    getEvents(){
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/');
        
        ref.on('value' , snapshot => {
            this.setState({
                services:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
        
                let newService ={id: state.id}
                this.setState(prevState => ({
                    services: [...prevState.services, newService]
                }))  

             
        })});

    }

    publishVacation(){
        let dates = this.dateRange(this.state.eventSelected[0], this.state.eventSelected[1]);
       

        this.state.services.map((item)=>(


        
      Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+item.id+'/dates/').on('value' , snapshot => {
               this.setState({
                   dates:[]
               })
               snapshot.forEach((childSnap) => {
               let state = childSnap.val();
           
                   let newService ={resourceId: item.id, id:state.id,year:state.year,amount:state.amount,day:state.day,month:state.month }
                  // console.log(newService);
                  // console.log(dates.length)
                  //console.log(date.getDate())
                   dates.map((date)=>(
                       
                        date.getDate() === newService.day && date.getMonth() === newService.month && date.getFullYear() === newService.year ? 
                        
                        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+newService.resourceId+'/dates/'+newService.id).update({
                            amount: 5,

                            
                        
            
                    
                        }).then((data)=>{
                            if(newService.amount <5){
                                var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/vacations/').push().key;
                                Firebase.database().ref('/clients/-uhfwoebfwioudb/content/vacations/'+key).set({
                                    resourceId: newService.resourceId,
                                    dateId: newService.id,
                                    oldAmountBeforeVacation: newService.amount 
                                })
                            }
                        }).then(()=>{
                           
                        }).catch((error)=>{
                            //error callback
                            console.log('error ' , error)
                        })
                       
                        
                        : 
                        null
                   ))
                
                    })})
                ))

                dates.map((date)=>{
                  
                    var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates/').push().key;
                    Firebase.database().ref('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates/'+key).set({
                        amount: 5,
                        month: date.getMonth(),
                        id:key,
                        year:date.getFullYear(),
                        day:   date.getDate(),
                        
                    })
                })
                dates.map((date)=>{
                  
                    var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/vacationdays/').push().key;
                    Firebase.database().ref('/clients/-uhfwoebfwioudb/vacationdays/'+key).set({
                        month: date.getMonth(),
                        id:key,
                        year:date.getFullYear(),
                        day:   date.getDate(),
                        date: date
                    })
                })


                

       setTimeout(() => {
        toast.success('De vakantie is geplant!');
    }, 3000);
       
    }
    onchangeValue(event,back){

        this.setState({
            eventSelected:event
        })

        console.log(this.state.eventSelected)
    }

    calculateNights(start,stop){
        var date1 = new Date(start);
        var date2 = new Date(stop);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24)-1); 
        this.setState({
            totalNights:numberOfNights
        })
    
        
    }

    

    componentWillMount() {
     
        this.getEvents();
        this.getVacations();

    }
    dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = new Date(startDate);
      
        while (currentDate <= new Date(endDate)) {
          dateArray.push(new Date(currentDate));
          // Use UTC date to prevent problems with time zones and DST
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }
      
        return dateArray;
      }
      
    


    render() {

        return (
            <div>
                                <Toaster />
                <Row>
                    <Col sm={6} className="vactions-col">
                        <h3>Vakantie plannen</h3>
                        <div>
                            <label>Datum(s)</label><br/>
                            <Calendar value={this.state.bookings} locale={"nl-NL"} value={[this.state.eventSelected[0],this.state.eventSelected[1]]} selectRange={true} returnValue={"range"} onChange={(event)=> this.onchangeValue(event,event)} minDate={new Date()}  />
                         <br/>
                          <Button onClick={()=> this.publishVacation()} className="button-create-appointment">Bevestig Vakantie</Button> 
                        </div>
                        
                    </Col>
                    <Col sm={6} className="vactions-col">
                        <h3>Vakantie dagen</h3>
                        <ul>
                            {this.state.vacations.map((vaction)=>(
                                <li>{vaction.day}-{Number(vaction.month)+1}-{vaction.year}</li>
                            ))}
                        </ul>

                    </Col>
                </Row>
            

            </div>

        );
    }
};

export default VacationIndex;