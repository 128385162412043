import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';


class qSortSorting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agrees:[],
            neutrals: [],
            disagrees:[],
            questions:this.props.qsortdata.questions,
            questionsbackup:this.props.qsortdata.questions,
            key: this.props.qsortdata.key,
            currentnumber:0,
            showQuestions: true,
            stelling:''
        };
      }

      resetCards(){
          this.setState({
              currentnumber: 0,
              questions: this.state.questionsbackup,
              showQuestions: true,
              agrees: [],
              neutrals: [],
              disagrees:[]
          })
      }

      setDisagree(questionId){
         

        var newelement = questionId;
        this.setState(prevState => ({
            disagrees: [...prevState.disagrees, newelement],
            currentnumber: this.state.currentnumber +1,
          }));
          console.log(this.state.currentnumber);
          if(this.state.currentnumber +1 >= this.state.questions.length){
            this.setState({
                showQuestions: false
            })
        }else{
            console.log('nope');
        }

        console.log("output:"+this.state.disagrees);
      }

      setNeutral(questionId){

        var newelement = questionId;
        this.setState(prevState => ({
            neutrals: [...prevState.neutrals, newelement],
            currentnumber: this.state.currentnumber +1,
          }));
          console.log(this.state.currentnumber);
          if(this.state.currentnumber +1 >= this.state.questions.length){
            this.setState({
                showQuestions: false
            })
        }else{
            console.log('nope');
        }
      }

      setAgree(questionId){
       
        var newelement = questionId;
        this.setState(prevState => ({
            agrees: [...prevState.agrees, newelement],
            currentnumber: this.state.currentnumber +1,
          }));

        if(this.state.currentnumber +1 >= this.state.questions.length){
            this.setState({
                showQuestions: false
            })
        }else{
            console.log('nope');
        }
      }

      componentDidMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        let ref = Firebase.database().ref('/' +dateStamp+'/'+this.state.key);
        ref.on('value' , snapshot => {

            let state = snapshot.val();

            this.setState({
                
                stelling: state.stelling
            })
            
            
          
        })
  
      }


      
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <Row>                    
              
                        <Col lg="2"></Col>
                        <Col lg="9" className="question-block">
                            {this.state.showQuestions ? 
                            <Row>

                                <Col lg="12" className="block-middle">
                                    <h5>Vraag {this.state.currentnumber}/ {this.state.questions.length}</h5>
                                </Col>
                                <Col lg="12" className="block-middle-stelling">
                                    <h5>{this.state.stelling}</h5>
                                </Col>
                                <Col lg="12" className="block-middle-text-question">
                                    <p>{this.state.questions[this.state.currentnumber].vraag}</p>
                                </Col>
                                <Col sm="4">
                                            <Button variant="primary" className="button-sorting" onClick={() => this.setDisagree(this.state.questions[this.state.currentnumber])} ><i class="fas fa-chevron-down"></i> Mee oneens</Button>
                                        </Col>
                                        <Col sm="4">
                                            <Button variant="primary" className="button-sorting" onClick={() => this.setNeutral(this.state.questions[this.state.currentnumber])}><i class="fas fa-arrows-alt"></i> Neutraal</Button>
                                        </Col>
                                        <Col sm="4">
                                            <Button variant="primary" className="button-sorting" onClick={() => this.setAgree(this.state.questions[this.state.currentnumber])}><i class="fas fa-chevron-up"></i> Mee eens</Button>
                                        </Col>
                                </Row>
                                :
                                <Row>
                                <Col lg="12" className="block-middle">
                                    <h5>Klaar!</h5>
                                </Col>
                                <Col lg="12" className="block-middle-text">
                                    <p>Je hebt alle vragen verdeeld in 3 categorieën!</p>
                                    <Link className="link-setup" to={{pathname: `/q-sort`,state: {disagrees: this.state.disagrees, neutrals:this.state.neutrals, agrees: this.state.agrees, questions:this.state.questionsbackup, key: this.state.key}}} >Ga verder</Link>
                        
                                </Col>

                                </Row>
                            }
                         
                            </Col>
                        
                         
                            <Col lg="2"></Col>

                            <Col lg="3" className="sorting-block disagrees-table">
                                <h5>Mee oneens</h5>
                                {
                                    this.state.disagrees.map((disagree, i) => (
                                        <li key={i}>{disagree.vraag} </li>
                                ))
                                }

                            </Col>
                    
                            <Col lg="3" className="sorting-block naturals-table">
                                <h5>Neutraal</h5>
                                {
                                    this.state.neutrals.map((neutral, i) => (
                                        <li key={i}>{neutral.vraag}</li>
                                ))}
                            </Col>
                          
                            <Col lg="3"className="sorting-block agrees-table">
                                <h5>Mee eens</h5>
                                {
                                    this.state.agrees.map((agree, i) => (
                                        <li key={i}>{agree.vraag }</li>
                                ))}
                            </Col>

                            <Col sm="4"></Col>
          <Col sm="4" className="qsortdone marginbottom">
      <Button variant="primary" className="button-restart " onClick={()=> this.resetCards()}>Kaartjes herstellen</Button>
     
      </Col>

          </Row>
 

          
        );
    }
};

export default qSortSorting;
