import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import FooterView from '../../views/Footer';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        PixelPro's
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },

  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Gegevens', 'Details', 'Betalen'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <PaymentForm />;
    case 2:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState("");

  console.log(props.data.roomdata);
  console.log(props.data.totalNights);
  console.log(formData)

  localStorage.setItem('roomdata', props.data.roomdata);
  // localStorage.setItem('roomdataTitle',props.data.roomdata.title);
  //  localStorage.setItem('roomdataPrice',props.data.roomdata.price);
  localStorage.setItem('priceSingle', props.data.roomdata.price);
  localStorage.setItem('totalNights', props.data.totalNights);



  var price = (parseFloat((localStorage.getItem('roomdataPrice') * localStorage.getItem('totalNights')) + (2.25 * localStorage.getItem('guests') * localStorage.getItem('totalNights'))).toFixed(2)).toString();
  console.log("prijs is:" + price)


  let name = localStorage.getItem('firstname');
  const handleNext = () => {
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === steps.length) {

      /* const headers = new Headers();
 headers.append('Content-Type', 'application/json');
 headers.append('content-length', '63');
 headers.append('Access-Control-Allow-Origin','*');
 
 const body = `{
     "totalPrice": "10.50",
   "orderId":"Dit is een test"
 }`;
 
 const init = {
 
   method: 'POST',
   headers,
   body,
 
 };
 
 fetch('https://us-central1-cashierr-pixelpros.cloudfunctions.net/payment', init)
 .then((response) => {
   return response.json(); // or .text() or .blob() ...
 })
 .then((text) => {
   console.log(text);
   // text is the response body
 })
 .catch((e) => {
   // error in e.message
 }); */

      /*fetch('https://app.biejanssen.nl/payment', {
          mode: 'no-cors'
  ,        method: 'POST',
          headers: { 'Content-Type': 'application/json'},body: JSON.stringify({orderId: localStorage.getItem('bookingID'),
          betaalmethode: localStorage.getItem('betaalmethode').toString().toLocaleLowerCase(),
          totalPrice: price,
          nameClient: name,
          emailClient: localStorage.getItem('mail')})
        });*/
      //console.log('plaats boeking');
      var xhr = new XMLHttpRequest();

      xhr.open('POST', 'https://cashierr.nl/payment');
      xhr.setRequestHeader("Content-Type", "application/json");

      let data = {
        orderId: localStorage.getItem('bookingID'),
        betaalmethode: localStorage.getItem('betaalmethode').toString().toLocaleLowerCase(),
        totalPrice: price,
        nameClient: name,
        emailClient: localStorage.getItem('mail'),
      }
      xhr.send(JSON.stringify(data));

      xhr.onload = () => {
        console.log("status is:" + xhr.responseURL);
        window.open = xhr.responseURL;

      };
      console.log("url is:" + xhr.responseURL)
      xhr.onreadystatechange = function () {
        console.log("status 2 is:" + xhr.response);
        //window.location.href=xhr.responseURL;
      }

      /* var xhr = new XMLHttpRequest();
xhr.addEventListener('load', function(e) {
 var response = e.target.responseText;
 console.log(response);
});
xhr.addEventListener('error', function(e) {
 console.error('Request errored with status', e.target.status);
});
xhr.open('POST', 'https://cashierr.nl/payment');
xhr.setRequestHeader('Content-Type','application/json');
xhr.setRequestHeader('Access-Control-Allow-Origin','*');

var body = '';
body += '{\n';
body += '    "totalPrice": "10.50",\n';
body += '  "orderId":"Dit is een test"\n';
body += '}';
xhr.send(body);

*/

      /*const headers = new Headers();
      headers.append( 'Access-Control-Allow-Origin','*')
      headers.append('Content-Type', 'application/json');
      headers.append('content-length', '63');
      headers.append('authorization', 'Bearer: test_REMvdSAeC93s2hMPAnetx3p594rgr7');
      
      
      const body = `{
          "amount": {"currency":"EUR", "value":"1000.00"},
        "description":"Dit is een test",
        "redirectUrl": "https://cashierr.nl/bedankt"
      }`;
      
      const init = {
        method: 'POST',
        headers,
        body,
      };
      
      fetch('https://api.mollie.com/v2/payments', init)
      .then((response) => {
        return response.json(); // or .text() or .blob() ...
      })
      .then((text) => {
        console.log("text is:"+text)
        // text is the response body
      })
      .catch((e) => {
        // error in e.message
      });*/
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment >

      <main className="checkout-layout ">
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Afrekenen
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <form>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Bedankt voor je boeking.
                  </Typography>
                  <Typography variant="subtitle1">
                    Je wordt doorgestuurd naar het afrekenen, momentje..
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Terug
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Plaats boeking' : 'Volgende'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </form>
        </Paper>
        <Copyright />

      </main>
      <FooterView />
    </React.Fragment>

  );
}