import React, { Component, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { isWidthUp } from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';

class CashdeskIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            mail:'',
            adres:'',
            postal:'',
            city:'',
            shippingCost:0,
            unitPrice: 0,
            amount:0,
            phone:'',
            timeslot:[],
            breakfastAmount:0,
            shippingCostAmount:0,
            total:0    ,
            shippingDefault:0,
            date: new Date(),
            eventDb:[],
            numberBooking:0,
            bookingsId:'',
            houseNumber:'',
            namereceiver:''
        };
    }

    generateBookingId(){
        let lengthIs = 0;

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        return 'P'+year+month+this.state.numberBooking
    }

    getOrderNumber(){
        let refEvent = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/orders');
        
        refEvent.on('value' , snapshot => {
            this.setState({
                eventDb:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
                this.setState(prevState => ({
                   eventDb: [...prevState.eventDb, state]
                }))  
    
        })

        this.setState({
            numberBooking:(parseInt(this.state.eventDb.length)+1)
        })
    });
    }

    componentWillMount(){

       
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/products/-iuhcnqwib');
        ref.on('value' , snapshot =>{
            let state = snapshot.val();

            this.setState({
                shippingCost: state.shippingCost,
                shippingDefault: state.shippingCost,
                unitPrice: state.unitPrice,
            })


        });

          
    }

    getTime(value){
        if(Number(value) == 1 ){
            return "9.00 uur - 9.30 uur"
        }else if(Number(value) == 2 ){
            return "9.30 uur - 10.00 uur"
        }
        else if(Number(value) == 3 ){
            return "10.00 uur - 10.30 uur"
        }
        else if(Number(value) == 4 ){
            return "10.30 uur - 11.00 uur"
        }
    }

    payOrder(){

        localStorage.setItem('name',this.state.name);
        localStorage.setItem('mail',this.state.mail);
        localStorage.setItem('date',this.state.date);
        localStorage.setItem('phone',this.state.phone);
        localStorage.setItem('adres',this.state.adres+' '+this.state.houseNumber);
        localStorage.setItem('postal',this.state.postal);
        localStorage.setItem('city',this.state.city);
        localStorage.setItem('bookingID', this.state.bookingsId)
        localStorage.setItem('amount',parseFloat(this.state.amount));
        localStorage.setItem('shippingCost',parseFloat(this.state.shippingCost));
        localStorage.setItem('unitPrice',parseFloat(this.state.unitPrice));
        localStorage.setItem('total',(parseFloat((this.state.unitPrice *this.state.amount) +this.state.shippingCost).toFixed(2)));
        

        

        var key = Firebase.database().ref().child('/clients/-uhfwoebfwioudb/content/temp-payments/').push().key;
        Firebase.database().ref('/clients/-uhfwoebfwioudb/content/temp-payments/'+key).set({
            key: key,
            email: this.state.mail,
            date: this.state.date,
            isPayed: false,
            amount: this.state.amount,
            phone: this.state.phone,
            place:'biejanssen.nl',
            unitPrice: this.state.unitPrice,
            shippingCost: this.state.shippingCost,
            namereceiver: this.state.namereceiver,
            time: localStorage.getItem('time'),
            totalPrice: (parseFloat((this.state.unitPrice *this.state.amount) +this.state.shippingCost).toFixed(2)),
            zip: this.state.postal,
            adres: this.state.adres+' '+this.state.houseNumber,
            city: this.state.city,
            bookingsnumber: this.state.bookingsId,
            id:this.state.bookingsId,
            name: this.state.name,

               
        }).then((dataRes)=>{
            toast.success('De e-mail is verzonden!');

            let timeslot = this.getTime(this.state.timeslot);

          var xhr = new XMLHttpRequest();
   
          xhr.open('POST', 'https://app.biejanssen.nl/sendMail');
          xhr.setRequestHeader("Content-Type", "application/json");
          let data ={
              nameClient: this.state.name,
              emailClient: this.state.mail,
              id: key,
              amount: this.state.amount,
              adress: this.state.adres+' '+this.state.houseNumber,
              city: this.state.city,

            date: this.state.date,
            time: timeslot
          }
         xhr.send(JSON.stringify(data));

         console.log('mail send');
         this.setState({
            name:'',
            mail:'',
            adres:'',
            houseNumber:'',
            namereceiver:'',
            postal:'',
            city:'',
            shippingCost:0,
            unitPrice: 0,
            amount:0,
            phone:'',
            breakfastAmount:0,
            shippingCostAmount:0,
            total:0    ,

         })

        }).catch((error)=>{
            toast.success('De e-mail is niet verzonden, probeer het opnieuw');
            //error callback
            console.log('error ' , error)
        })

    }

    changeCity(city){
        this.getOrderNumber()

        this.setState({
            city: city,
            bookingsId: this.generateBookingId()
        })

 console.log(this.state.bookingsId)
        if(city === 'Elsloo' || city === 'elslo'){
            this.setState({
                shippingCost:0
            })
        }else{
            this.setState({
                shippingCost:this.state.shippingDefault
            })
        }
    }

    selectTime(value){
         let refEvent = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/orders');
        
        refEvent.on('value' , snapshot => {
            this.setState({
                eventDb:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
                this.setState(prevState => ({
                   eventDb: [...prevState.eventDb, state]
                }))  
    
        })
        this.setState({
            numberBooking:(parseInt(this.state.eventDb.length)+1)
        })
    });
        this.setState({timeslot: value});
        if(Number(value) == 1){
            localStorage.setItem('time','9.00 - 9.30');
        }else if(Number(value) == 2){
            localStorage.setItem('time','9.30 - 10.00');
        }
        else if(Number(value) == 3){
            localStorage.setItem('time','10.00 - 10.30');
        }
        else if(Number(value) == 4){
            localStorage.setItem('time','10.30 - 11.00');
        }
    }

    render() {
       
        return (


            <Row className=""> 
            <Toaster />
                <Col sm={12} className="backend-form" >
                    <label>
                       Uw naam:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <label>
                       Uw e-mail:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.mail} onChange={event => this.setState({ mail: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <label>
                       Uw telefoonnummer:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <label>
                      Naam ontvanger:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.namereceiver} onChange={event => this.setState({ namereceiver: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <label>
                       Lever datum:
                    </label>
                    <br/>
                    <input type="date" name="name-client" value={this.state.date} onChange={event => this.setState({ date: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <span className="half-input">
                    <label>
                       Aflever adres:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.adres} onChange={event => this.setState({ adres: event.target.value, errorMessage:''})}/>
                    <br/><br/></span>
                    <span className="half-input right-input-half">
                    <label>
                       Huisnummer:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.houseNumber} onChange={event => this.setState({ houseNumber: event.target.value, errorMessage:''})}/>
                    <br/><br/></span><br/>
                    <label>
                       Aflever postcode:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.postal} onChange={event => this.setState({ postal: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <label>
                       Aflever woonplaats:
                    </label>
                    <br/>
                    <input type="text" name="name-client" value={this.state.city} onChange={(event) => this.changeCity(event.target.value)}/>
                    <br/><br/>
                    <label>
                       Aantal ontbijtjes:
                    </label>
                    <br/>
                    <input type="number" name="name-client" value={this.state.amount} onChange={event => this.setState({ amount: event.target.value, errorMessage:''})}/>
                    <br/><br/>
                    <label>
                       Tijdslot:
                    </label>
                    <br/>
                    <select name="cars" id="cars" onChange={(event) => this.selectTime(event.target.value)}>
                        <option value="1">9.00 uur - 9.30 uur</option>
                        <option value="2">9.30 uur - 10.00 uur</option>
                        <option value="3">10.00 uur - 10.30 uur</option>
                        <option value="4">10.30 uur - 11.00 uur</option>
                    </select>
                    <br/><br/>

                    <span >
                        <ul className="total-breakfast">
                            <li>Prijs per stuk <span>€ {parseFloat(this.state.unitPrice).toFixed(2)}</span></li>
                            <li>Aantal <span>{this.state.amount}</span></li>
                            <li>Bezorgkosten <span>€ {this.state.shippingCost}</span></li>
                            <hr/>
                            <li>Totaal <span>€ {parseFloat((this.state.unitPrice *this.state.amount) +this.state.shippingCost).toFixed(2)}</span></li>
                        </ul>
                    </span>
                    <Button onClick={()=>this.payOrder()} >Verzend bestelling!</Button>
                </Col>

            </Row>




        );
    }
};

export default CashdeskIndex;