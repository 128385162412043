import React from "react";
import { Container, Row, Col } from "shards-react";

import FooterComponent from "../../components/Footer/FooterComponent";


const FooterView = (props) => (

  
    <FooterComponent />
);

export default FooterView;
