import React from "react";
import { Container, Row, Col } from "shards-react";
import SignOnHUScreen from "../../components/specificComponents/huStudentenRegistratie";

const RegistrationViewHU = () => (
  <Container fluid className="main-content-container px-4">
      <SignOnHUScreen />
    
  </Container>
);

export default RegistrationViewHU;
