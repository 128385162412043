import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import AboutUsComponent from "../../components/Frontend/AboutUsComponent";
import ExtraComponent from "../../components/Frontend/ExtraComponent";
import HomeComponent from "../../components/Frontend/HomeComponent";
import KeyOfAelseComponent from "../../components/Frontend/KeyOfAelseComponent";
import SurroundingsComponent from "../../components/Frontend/SurroundingsComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const KeyOfAelseView = (props) => (

  
  <Container fluid  className="sub-page">
 <HeaderView />
    <KeyOfAelseComponent />
    <FooterView />

  
  </Container>
);

export default KeyOfAelseView;
