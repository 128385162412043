import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import HomeComponent from "../../components/Frontend/HomeComponent";
import TestComponent from "../../components/Frontend/TestComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const TestView = (props) => (

  
  <Container fluid >
 <HeaderView />
    <TestComponent />
    <FooterView />

  
  </Container>
);

export default TestView;
