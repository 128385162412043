import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button,Container } from "shards-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const images = [
    { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FAV7I1749-min.jpg?alt=media&token=f24d7d8d-83e3-496f-b666-6467184984ff"},
    { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FAV7I1752-min.jpg?alt=media&token=62dc7cd9-81a5-415d-b39a-079df79b7405"},
    
    { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FAV7I1775-min.jpg?alt=media&token=10819b13-d86a-4dd4-9b0d-ae73deaa8b0e"},
    { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FFoto%20terras-min.jpg?alt=media&token=ecc6d182-5f56-4960-8c22-8cab46700982"},
    { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FIMG_3229-min.JPG?alt=media&token=f38f2a2d-61f5-488f-bd8e-6bf250292a0b"},
   {url:"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FIMG_0086-min.jpeg?alt=media&token=520d8715-477a-49a1-80d5-3391b7e80d8a"},
   {url:"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FTuinkamer%20-8%20Achter%20naar%20voor-min.jpeg?alt=media&token=21ebc9c1-ebbd-47fb-8e97-6b4e96511be1"},
   {url:"https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/BieJanssen%2Flocatie%2FAV7I1762-min.jpeg?alt=media&token=cd0cd7e3-cfc0-4578-b301-cb30e7ab63a0"}
   
  ];
  
class AboutUsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
       
        return (
            <div className="home-page">
            <Row className="col-sm-12 about-us-banner">
                <Col sm={6} className="about-us-image-page">
                    
                </Col>
                <Col sm={6} className="about-us-text-page">
                <h2>Over ons</h2>
                    <p>Welkom bij B&B Bie Janssen.<br/>
                    Wij zijn de familie Janssen die vanuit onze droom, deze mooie B&B zijn gestart. Onze familiair gerunde B&B beschrijft zich als een klein hotel met de gastvrijheid van een B&B. Ons gezin bestaat uit Peter, Sabrina en onze kids Joep en Sanne en niet te vergeten onze Labradoodle Sien. Voel je thuis is ons motto.<br/><br/>
Nadat Peter in 2018 door een reorganisatie zijn baan verloor na 22 jaar in loondienst te hebben gewerkt, kwam al gauw zijn droom weer boven drijven: een eigen B&B beginnen. Het was Sabrina die hem het bekende zetje gaf om zijn droom na te jagen. Met geen ervaring in de horeca maar met een hoop enthousiasme, doorzettingsvermogen en gevoel voor gastvrijheid startten we op 1 juli 2019 onze B&B in de oude kern van Elsloo.<br/><br/>
Sabrina maakte in 2017 de overgang vanuit het bankwezen naar een baan in de kinderopvang, waar ze inmiddels is doorgegroeid naar een functie als leidinggevende. Verder helpt ze zoveel als mogelijk in de B&B. Joep en Sanne helpen papa en mama ook met klusjes in de B&B en maken regelmatig een praatje met de gasten. Onze lieve Sien is een allemansvriend en zij tovert bij veel gasten een lach op het gezicht door haar enthousiasme.<br/><br/>
Onze gastvrije, gezellige en sfeervolle B&B met 8 kamers en 1 familiekamer ligt in een statig huis met veel karakter. Hier voelden wij ons gelijk thuis en dit gevoel willen wij graag met jullie als gast delen.<br/><br/>
Dus heb je behoefte aan even wat tijd voor jezelf of samen met vrienden of familie? Dan zijn wij de aangewezen plek, om jullie te laten ervaren hoe gastvrij en mooi ons Limburg is.

                    
                    </p>

                    <Link className="frontend-button-light" to={{pathname: `/kamer-overzicht`}} >Bekijk kamers</Link>
                    
                </Col>
                
   
            </Row>
            {/*<Container>
            <Row >
                <Col sm={12} className="facilities-home">
                    <h2>Faciliteiten</h2>
                    <h4>Alles tot in de puntjes verzorgd</h4>
                </Col>
                <Col sm={6} className="facilities-home padding-six-left">
                    <i class="custom fa fa-bicycle"></i>
                    <h5>Fietsen </h5>
                    <p>
                    We hebben 2 fietsen te huur. Voor slechts € 25 per dag ontdek je de mooie en verrassende omgeving van Elsloo. Mochten onze fietsen al verhuurd zijn, dan bevelen we graag <a href="https://alexvanoeveren.nl/fietsverhuur/" target="_blank">Fietsspeciaalzaak Alex van Oeveren</a> bij ons in de buurt aan. <br/><br/>Voor al ons fietsend publiek hebben wij in ieder geval een eigen afgesloten fietsenstalling in de B&B. Kosteloos, maar wel op eigen risico. Het is ook mogelijk om je elektrische fiets op te laden tijdens het verblijf of tijdens het nuttigen van een drankje op ons terras.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-right">
                    <i class="custom fa fa-car"></i>
                    <h5>Parkeren voor de deur</h5>
                    <p>
                    Wanneer je onze B&B bezoekt in Elsloo kun je voor de deur gratis parkeren. Wel zo makkelijk als je ons bezoekt voor een zakenreis of wanneer je vanuit onze B&B je wandel-/ fietstocht start met bagage.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-left">
                    <i class="custom fa fa-home"></i>
                    <h5>In-/Uitchecken</h5>
                    <p>
                    Je kunt iedere dag van de week bij ons terecht voor een overnachting. We staan vanaf 15 uur voor je klaar. Je kunt tot 18 uur inchecken (woensdag en zondag van 19 uur tot 21 uur). Andere tijden zijn alleen in goed overleg mogelijk. Het uitchecken is op de dag van vertrek om 11.00 uur. Uiteraard kun je de bagage zolang bij ons opslaan voor als je besluit om nog een dag de prachtige natuur van Elsloo in te trekken.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-right">
                    <i class="custom fa fa-binoculars"></i>
                    <h5>Vele bezienswaardigheden in de omgeving</h5>
                    <p>
                    Gelegen in Elsloo heeft B&B Bie Janssen het geluk zich in het middelpunt te bevinden van een stukje prachtige historie. Midden in het oude centrum van Elsloo vindt je onze B&B, vanuit hier kun je allerlei leuke tochten starten langs verschillende bezienswaardigheden.
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-left">
                    <i class="custom fa fa-heart"></i>
                    <h5>Gezelligheid</h5>
                    <p>
                    Kom je samen met de kids? Of hou je gewoon van een bordspel? In onze ontbijtruimte liggen verschillende spellen klaar waar iedere gast gebruik van mag maken!
                    </p>
                </Col>
                <Col sm={6} className="facilities-home padding-six-right">
                    <i class="custom fa fa-coffee"></i>
                    <h5>Koffie & vlaai</h5>
                    <p>
                    Geniet buiten op het terras, of binnen in de verblijfsruimte van een kop koffie/thee met een heerlijk stuk vlaai. Buiten de reguliere dranken serveren wij ook verschillende speciaalbieren. Vraag de gastheer naar de keuzes die wij in huis hebben!
                    </p>
                </Col>

            </Row>
            </Container>*/}
            <Container>
            <Row className="location-row">
                <Col sm={6} className="location-colom">
                    <h5>Alles over</h5>
                    <h2>De locatie</h2>
                    <p>
                    Je vindt onze B&B terug in het oude kern van Elsloo. Als je vanuit de grote weg de oude kern in rijdt, fietst of loopt dan zie je ons pand aan de linkerzijde. Het is te herkennen aan de blauwe borden met ons logo erop. Wist je trouwens dat Elsloo in 2020 uitgeroepen is tot één van de mooiste dorpen van Nederland? Je loopt vanuit onze B&B zo de oude kern in en het prachtige bos. <br/><br/>
                    De sfeervolle kamers zijn rustig gelegen in het achterste gedeelte van ons huis. Verdeeld over 2 etages zijn er 8 kamers met elk een eigen badkamer. De familiekamer met een klein woonkamertje ligt op de 3e etage. De kamers zijn landelijk en sfeervol ingericht en je hebt de beschikking over een luxe boxspring van 1.60 meter bij 2.00 meter. Handdoeken en beddengoed krijg je uiteraard van ons en ook doucheschuim is voorhanden. Je bed is uiteraard al opgemaakt bij aankomst. Een föhn hebben we voor je bij de receptie. Onze B&B is minder toegankelijk voor mindervalide gasten. <br/><br/>
                    Het ontbijt kun je nuttigen in onze sfeervolle verblijfsruimtes. Bij goed weer kan je ook gebruik maken van ons terras. In de avonduren staan de verblijfsruimtes ook ter beschikking voor het nuttigen van een drankje en een borrelhapje. Ook een kopje koffie of thee maken we graag voor je.<br/><br/>
Het ontbijt is op werkdagen van 7.00 uur tot 09.00 uur. In het weekend van 8.00 uur tot 10.00 uur. Andere tijden zijn op aanvraag mogelijk.

                    </p>
                </Col>
                <Col sm={6} className="location-colom">
                <Carousel autoPlay={true} infiniteLoop={true}>
                    {images.map((image)=>(
                        <div>
                        <img src={image.url} />
                       
                        </div>
                    ))}
             
               
            </Carousel>


                   {/* <iframe src="https://www.google.com/maps/embed?pb=!4v1562910290296!6m8!1m7!1sCAoSLEFGMVFpcE1aV1JOZWRTTEZjYXlmVWNLblJneXRpbGdLeWhFYS1GVkhPUy1x!2m2!1d50.946773238958!2d5.7645108766404!3f200!4f0!5f0.7820865974627469" frameBorder="0"  allowFullScreen></iframe>*/}
                </Col>
            </Row>
           
           {/*} <Row className="about-us-home-row" >
                <Col sm={12} className="about-us-home">
                    <h2>Wat anderen ervaarden</h2>
                    <p>Hieronder tref je een aantal reviews aan van onze gasten op Google Review. Wil je nog meer reviews zien, klik dan <a target="_blank" href="https://www.google.com/travel/hotels/entity/CgsI0YHhoKWn07ObARAB/reviews?g2lb=2502548%2C2503780%2C4258168%2C4270442%2C4306835%2C4317915%2C4328159%2C4371335%2C4401769%2C4419364%2C4482438%2C4486153%2C4491350%2C4509341%2C4515404%2C4517258%2C4523593%2C4530345%2C4533881%2C4536454%2C4270859%2C4284970%2C4291517&hl=nl-NL&gl=nl&ssta=1&rp=ENGB4aClp9OzmwEQ0YHhoKWn07ObATgCQABIAcABAg&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwioyr-olYHwAhUAAAAAHQAAAAAQDQ&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0N2MwYzFjZjgyMDJmYTViOjB4OWI2NzRkM2E1NDE4NDBkMRoAEhoSFAoHCOUPEAQYEhIHCOUPEAQYExgBMgIQACoJCgU6A0VVUhoA">hier</a></p>
                </Col>
                <Col sm={6} className="review-block">
                <h3>5/5</h3>
                    <p>Peter, Sabrina en kids van B&B Bie Janssen dank voor de fijne ontvangst afgelopen weekend. Fijn te zien, dat met de nodige maatregelen, men in Corona tijd gewoon welkom is bij jullie. Aanrader!</p>
                    
                  
                    <h5>Toet Ontwerp</h5>
                </Col>
                <Col sm={6} className="review-block">
                <h3>4/5</h3>
                  <p>Een heerlijk weekendje, mooie mensen, mooie omgeving, mooie B@B. Genoten!</p>
                   
                    <h5>Rob Uylings</h5>
                </Col>
                <Col sm={6} className="review-block">
                <h3>4/5</h3>
                  <p>Bie Janssen, Peter en Sabrina, zijn fantastische gastvrije mensen. Niets was teveel, speciaal voor ons geitenkaas en gewone yoghurt geregeld. Ze hebben 2 gezellige kinderen en lieve hond. Prachtige omgeving om te fietsen of wandelen. Aanrader.</p>
                   
                    <h5>Dorien Vd Velden</h5>
                </Col>
                <Col sm={6} className="review-block">
                <h3>5/5</h3>
                  <p>Peter en Sabrina, hartelijk bedankt voor dagen die ik heb doorgebracht bij jullie. Een b en b die vanuit jullie hart wordt gerund.Super gastvrij gezin....waar je gelijk in opgenomen wordt. Top locatie om van alles te kunnen doen in de omgeving. Bos wandeling, fietsen langs de Maas en de heuvelachtige streek.
Groet Gerard</p>
                   
                    <h5>Gerard Ville</h5>
                </Col>
        </Row>*/}
            </Container>

            
            

            <Row className="social-media-row">
                <Col sm={12} className="social-media-colum">
                    <h2>Volg ons op social media</h2>
                    <p>B&B Bie Janssen is ook actief op social media. Via deze kanalen houden wij je op de hoogte over allerlei leuke evenementen of gebeurtenissen rondom Elsloo en omstreken. <br/>Ook vindt je hier het laatste nieuws over de B&B terug. Benieuwd? Volg ons dan en blijf op de hoogte! Of schrijf je in voor onze nieuwsbrief.</p>
                    <ul>
                        <li><a href="https://www.facebook.com/BenBBieJanssen/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/bie_janssen/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/b-b-bie-janssen/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                    </ul>
                </Col>
            </Row>
            

            </div>



        );
    }
};

export default AboutUsComponent;