import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button,Container } from "shards-react";


class SurpriseboxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
       
        return (
            <div className="home-page">
                 <Row className="surroundings-row">
                <Col sm={12} className="surroundings-colum">
                    <h2>Verrassingsontbijt</h2>
                    <p>Naast het runnen van de B&B bezorgen we ook graag  een ontbijtje thuis of op de werkvloer. Wil je iemand verrassen of een hart onder de riem steken? Heeft iemand iets te vieren? Verras diegene dan met een heerlijk ontbijtje.
<br/><br/>
Wij bezorgen het ontbijt graag voor je tussen 9.00 en 11.00 uur. Andere tijden zijn in overleg mogelijk. Graag minimaal 24-uur van te voren bestellen.
</p>
                    
                </Col>
            </Row>
            <Container>
           
            <Row className="location-row last">
            <Col sm={6} className="location-colom">
                    <img src={require('../../assets/images/frontend/Foto-ontbijt1-min.jpg')}/>
                </Col>
                <Col sm={6} className="surprise-colom">
           
                    <h2>Het Verrassingsontbijt</h2>
                    <p>Het ontbijt bestaat uit:</p>
                    <ul>
                        <li>Flesje bubbels per bestelling</li>
                        <li>Verse bruine en witte broodjes en croissant</li>
                        <li>Diverse soorten vleesbeleg en zoetigheden</li>
                        <li>Yoghurt</li>
                        <li>Gekookt ei</li>
                        <li>Jus d’orange</li>
                    </ul>
                    <p>
                        Dit alles geserveerd in een mooie box. Kosten: € 13,95 per persoon. Dit is inclusief gratis bezorgen in Elsloo. Daarbuiten komt er € 3,95 aan bezorgkosten per bestelling bij.  Betaling doe je gewoon direct online! Heb je vragen? Stuur een mail naar info@biejanssen.nl of een appje naar 06-25226150.
                        <br/><br/>
                        Zolang de voorraad strekt.
                    </p>
                    <Link  className="frontend-button-dark" to={{pathname: `/ontbijtbox-bestellen`, state:{eventDate: 'test'}}}>Bestel direct!</Link>

                 
                    
                </Col>                
            </Row>
            </Container>
            </div>



        );
    }
};

export default SurpriseboxComponent;