import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import 'react-activity/dist/react-activity.css';
import 'react-activity/dist/react-activity.css';
import BookingCalendar from 'react-booking-calendar';
import { Link } from 'react-router-dom';
import PropagateLoader from "react-spinners/ClipLoader";
import Firebase from 'firebase';
import { Redirect } from 'react-router';
import { set } from 'date-fns';


class RoomSearchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms: [
            ],
            facility: [],
            prices: [],
            images: [],
            eventDb: [],
            datesAv: [],
            roomsAvaible: false,
            day: '',
            month: '',
            year: '',
            numberBooking: 0,
            totalNights: 0,
            bookingId: '', loading: true,
            addedRooms: [],
            avaidebleRooms: [],
            noRooms: false
        };
    }

    componentWillUnmount() {
        this.setState({
            day: '',
            month: '',
            year: ''
        })
    }
    dates = []

    getDatesBetweenDates = (startDate, endDate) => {
        let dates = []

        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
            dates = [...dates, new Date(theDate)]
            theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, endDate]

        return dates
    }
    fetchEvents() {
        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');

        ref.on('value', snapshot => {
            this.setState({
                eventDb: []
            })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    eventDb: [...prevState.eventDb, state]
                }))

            })

            let id = this.generateBookingId(Number(this.state.eventDb.length) + 1);
            console.log(id)

            this.setState({
                bookingId: id
            })


            console.log("lengte is:" + (Number(this.state.eventDb.length) + 1))
        });
    }

    myDates = [];
    pastDate = 0;
    componentWillMount() {
        this.setState({
            rooms: [],
            addedRooms: [],
            avaidebleRooms: []
        })
        this.myDates = this.getDatesBetweenDates(new Date(this.props.data.startYear, this.props.data.startMonth, this.props.data.startDay), new Date(this.props.data.endYear, this.props.data.endMonth, this.props.data.endDay))
        console.log('dates are' + this.myDates)

        console.log(this.props.data.startMonth)
        console.log(new Date(this.props.data.startYear, this.props.data.startMonth, this.props.data.startDay));
        var today = new Date();
        today.setDate(today.getDate() - 1);
        if (this.checkPast(new Date(this.props.data.startYear, this.props.data.startMonth, this.props.data.startDay), today) === true || this.checkPast(new Date(this.props.data.endYear, this.props.data.endMonth, this.props.data.endDay), today) === true) {
            console.log('verleden')
        } else {


            for (let i = 0; i < 2; i++) {
                this.fetchEvents();
            }




            let rooms = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/');
            rooms.once('value', snapshot => {

                this.setState({
                    rooms: []
                })

                snapshot.forEach((childSnap) => {
                    let state = childSnap.val();


                    console.log("runner" + state.id)

                    let dates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/dates');
                    dates.on('value', snapshot => {


                        snapshot.forEach((childSnap) => {
                            let date = childSnap.val();



                            for (let i = 0; i < this.myDates.length; i++) {


                                if (Number(date.day) === new Date(this.myDates[i]).getDate() && (Number(date.month)) == this.props.data.startMonth && Number(date.year) == this.props.data.startYear && (Number(date.amount) + 1) < Number(state.stock)) {
                                    console.log('months are:' + (Number(date.month) + 1))
                                    console.log('Day is:' + Number(date.day))
                                    console.log(new Date(this.myDates[i]).getDate())
                                    console.log(Number(date.amount))
                                    console.log('state id is:' + state.name)
                                    console.log("voorraad:" + state.stock)
                                    console.log("hoeveelheid:" + date.amount)
                                    console.log(Number(date.month) + 1)
                                    console.log(this.props.data.startMonth)



                                    this.setState(prevState => ({

                                        rooms: [...prevState.rooms, state],

                                    }));



                                    let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/facilities');
                                    facilities.on('value', snapshot => {


                                        snapshot.forEach((childSnap) => {
                                            let stateFac = childSnap.val();

                                            let facility = { roomId: state.id, name: stateFac.name }
                                            console.log(this.state.facility)
                                            // for(let i =0; i< this.state.facility.length; i++){

                                            //  if(this.state.facility[i].name != facility.name && this.state.facility[i].id != facility.id){
                                            this.setState(prevState => ({
                                                facility: [...prevState.facility, facility],

                                            }));

                                            // }
                                            //}


                                        });

                                    })

                                    let guests = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/prices');
                                    guests.on('value', snapshot => {

                                        snapshot.forEach((childSnap) => {
                                            let statePrice = childSnap.val();

                                            let newPrice = { roomId: state.id, id: statePrice.id, person: statePrice.person, price: statePrice.price }

                                            this.setState(prevState => ({
                                                prices: [...prevState.prices, newPrice],
                                            }));


                                        });

                                    })

                                    let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + state.id + '/images');
                                    images.on('value', snapshot => {

                                        snapshot.forEach((childSnap) => {
                                            let stateImage = childSnap.val();


                                            let newImages = { id: state.id, url: stateImage.url, type: stateImage.type }
                                            this.setState(prevState => ({
                                                images: [...prevState.images, newImages],
                                            }));


                                        });

                                    })

                                } else {


                                }
                            }

                        });

                    })




                });



            })


            // this.onchangeValue(new Date(this.props.data.startYear,this.props.data.startMonth,this.props.data.startDay),new Date(this.props.data.endYear,this.props.data.endMonth,this.props.data.endDay))
            this.calculateNights(new Date(this.props.data.startYear, this.props.data.startMonth, this.props.data.startDay), new Date(this.props.data.endYear, this.props.data.endMonth, this.props.data.endDay))


            setTimeout(() => {
                console.log('rooms are:' + JSON.stringify(this.state.rooms))

                console.log(this.myDates.length)

                for (let i = 0; i < this.state.rooms.length; i++) {
                    let appearance = this.getOccurrence(this.state.rooms, this.state.rooms[i].id);
                    console.log('appearance is: ' + appearance)
                    if (appearance < this.myDates.length) {
                        console.log('kleiner');
                        console.log(appearance)


                    } else if (appearance == this.myDates.length) {
                        console.log('groter');
                        console.log('naam kamer:' + this.state.rooms[i])
                        let filtered = this.state.rooms.filter(person => person.id == this.state.rooms[i].id);
                        var unique = filtered.filter((v, i, a) => a.indexOf(v) === i.id);

                        console.log('unique' + JSON.stringify(unique));
                        console.log('filter' + this.state.facility.filter(person => person.roomId == this.state.rooms[i].id))

                        this.setState(prevState => ({
                            addedRooms: [...prevState.addedRooms, this.state.rooms[i]],

                        }))


                    }
                }
            }, 3000);
        }


        setTimeout(() => {
            if (this.state.addedRooms.length < 1) {
                this.setState({
                    noRooms: true
                })
            } else {

                let uniqueArray = Array.from(new Set(this.state.addedRooms.map(a => a.id)))
                    .map(id => {
                        return this.state.addedRooms.find(a => a.id === id)
                    })

                console.log('array:' + uniqueArray)

                this.setState({
                    avaidebleRooms: uniqueArray,
                    loading: false, roomsAvaible: true
                })

                console.log('unique is:' + this.state.avaidebleRooms)

            }

        }, 5000);



    }
    onlyUnique(value, index, self) {
        return self.indexOf(value.id) === index.id;
    }

    getOccurrence(array, value) {
        var count = 0;
        array.forEach((v) => (v.id === value && count++));
        return count;
    }

    generateBookingId(number) {
        let lengthIs = 0;

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        console.log('nummer is:' + number)

        return 'B' + year + month + number
    }

    CalculatePriceDifference(month, day, price) {

        console.log(month)
        console.log(day)
        console.log(price)
        if (month == 6 && day == 6 || month == 6 && day == 7 || month == 6 && day == 8 || month == 6 && day == 9 || month == 6 && day == 13 || month == 6 && day == 14 || month == 6 && day == 15 || month == 6 && day == 16 || month == 6 && day == 20 || month == 6 && day == 21 || month == 6 && day == 22 || month == 6 && day == 23 || month == 6 && day == 2 || month == 6 && day == 28 || month == 6 && day == 29 || month == 6 && day == 30) {

            let NewPrice = Number(price) + 20;

            return NewPrice;

        }
        /*
       else if(month == 4 && day == 5||month ==4 && day == 6||month == 4 && day == 7|| month == 3 && day == 8|| month == 3 && day == 9 || month == 3 && day == 10 ||month == 3 && day == 14 ||month == 3 && day == 15 ||month == 3 && day == 16||month == 4 && day == 18 ||month == 4 && day == 19||month == 4 && day == 20||month == 4 && day == 21||month == 4 && day == 26||month == 4 && day == 27||month == 4 && day == 28||month == 4 && day == 29|| month==5 || month==6||month == 7 && day == 13||month == 7 && day == 14||month == 7 && day == 15||month == 7 && day == 16||month == 7 && day == 17||month == 7 && day == 18||month == 7 && day == 19||month == 7 && day == 20||month == 7 && day == 21||month == 7 && day == 23||month == 7 && day == 23||month == 7 && day == 24||month == 7 && day == 25||month == 7 && day == 26||month == 7 && day == 27||month == 7 && day == 13||month == 7 && day == 28||month == 7 && day == 29||month == 11 && day == 15||month == 11 && day == 16||month == 11 && day == 17 
        ||month == 8*/

        else if (month == 6 && day == 18 || month == 6 && day == 19 || month == 6 && day == 20 || month == 6 && day == 21) {

            let NewPrice = Number(price) + 10;

            return NewPrice;



        } else {
            let NewPrice = Number(price);

            return NewPrice;
        }

    }

    calculatePrice(item) {



        for (let i = 0; i < this.state.options.length; i++) {
            if (item === this.state.options[i].id) {
                let PriceHigherSummer = this.CalculatePriceDifference(this.state.selected[0].getMonth(), this.state.selected[0].getDate(), this.state.options[i].price);
                console.log(PriceHigherSummer)
                this.setState({
                    price: PriceHigherSummer
                })
                console.log("aantal:" + this.state.options[i].person)
                localStorage.setItem('roomdataTitle', this.props.data.roomName.name);
                localStorage.setItem('roomdataId', this.props.data.roomName.id);
                localStorage.setItem('roomdataPrice', PriceHigherSummer);
                localStorage.setItem('guests', this.state.options[i].person);
                localStorage.setItem('resourceId', this.state.resourceId);
                localStorage.setItem('fullStartDate', this.state.selected[0]);
                localStorage.setItem('fullEndDate', this.state.selected[1]);
                localStorage.setItem('serviceId', this.props.data.roomName.id);
                localStorage.setItem('totalNights', this.state.totalNights);

                localStorage.setItem('startYear', this.state.selected[0].getFullYear());
                localStorage.setItem('startMonth', this.state.selected[0].getMonth());
                localStorage.setItem('startDay', this.state.selected[0].getDate())
                localStorage.setItem('endYear', this.state.selected[1].getFullYear());
                localStorage.setItem('endMonth', this.state.selected[1].getMonth());
                localStorage.setItem('endDay', this.state.selected[1].getDate())
                console.log(localStorage.getItem('roomdataPrice'));


                if (this.state.totalNights > 0 && this.state.error === false) {
                    this.setState({
                        daysSelected: true
                    })
                }
            }
        }
    }

    checkPast(firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
            return true;
        }

        return false;
    }

    dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    }

    checkDisable(arr, event) {
        console.log(event[0])
        for (let i = 0; i < arr.length; i++) {
            for (let k = 0; k < this.state.disabledDates.length; k++) {
                if (arr[i].getDay() === this.state.disabledDates[k].getDay() && arr[i].getMonth() === this.state.disabledDates[k].getMonth()) {
                    console.log('double date');
                    this.setState({
                        errorMessage: 'er is een datum geselect die reeds bezet is, selecteer een andere datum.'
                    });
                    console.error('dezde datum is al bezet')
                } else {
                    console.log('nothing');
                    this.setState({
                        selected: event
                    })
                    this.calculateNights(event[0], event[1]);
                }
            }
        }
    }
    calculateNights(start, stop) {
        var date1 = new Date(start);
        var date2 = stop;

        console.log("aantal nachten:" + date2)
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.setState({
            totalNights: numberOfNights
        })

        console.log("aantal nachten:" + Number(numberOfNights))




    }

    componentWillUnmount() {
        this.setState({
            totalNights: 0
        })

    }


    onchangeValue(start, end) {

        console.log("state:" + start)
        console.log("end:" + end)

        localStorage.setItem('bookingID', this.generateBookingId())


        let dates = this.dateRange(start, end);



        console.log(dates)

        // this.calculateNights(start,end)
        //this.checkDisable(arr,prevEvent);

        for (let i = 0; i < dates.length; i++) {



            /*for(let j =0; j<this.state.minDates.length;j++){
              console.log("new nights are"+this.calculateNightsMinData(start,end))  
               
                if(dates[i].getDate() === this.state.minDates[j].date.getDate() && dates[i].getMonth() === this.state.minDates[j].date.getMonth() && this.calculateNightsMinData(event[0],event[1]) < this.state.minDates[j].amount){
                    this.setState({
                        errorMessage:'Je moet op de'+this.state.minDates[j].date.getDate()+'-ste minimaal'+this.state.minDates[j].amount+' nachten boeken',
                        error:true
                    });
                    console.error('dezde datum is al bezet');
                    return
                }
                else{
                    this.setState({
                        selected:event,error:false,nightSet: true
                    })
                   // this.calculateNights(event[0],event[1]);
                }
            }*/
        }



        /**/
    }

    setValues(name, id, resource) {

        console.log(this.state.bookingId)


        localStorage.setItem('roomdataTitle', name);
        localStorage.setItem('roomdataId', id);
        localStorage.setItem('roomdataPrice', parseFloat(this.state.prices.filter(guests => id == guests.roomId && this.props.data.amountPersons == guests.person).map((item, i) => ((Number(item.price))))));
        localStorage.setItem('guests', this.props.data.amountPersons);
        localStorage.setItem('resourceId', resource);
        localStorage.setItem('fullStartDate', new Date(this.props.data.startYear, this.props.data.startMonth, this.props.data.startDay));
        localStorage.setItem('fullEndDate', new Date(this.props.data.endYear, this.props.data.endMonth, this.props.data.endDay));
        localStorage.setItem('serviceId', id);
        localStorage.setItem('totalNights', this.state.totalNights);

        localStorage.setItem('startYear', this.props.data.startYear);
        localStorage.setItem('startMonth', this.props.data.startMonth);
        localStorage.setItem('startDay', this.props.data.startDay)
        localStorage.setItem('endYear', this.props.data.endYear);
        localStorage.setItem('endMonth', this.props.data.endMonth);
        localStorage.setItem('endDay', this.props.data.endDay)
        localStorage.setItem('bookingID', this.state.bookingId)


    }



    render() {


        return (
            this.state.loading ?
                <Container>
                    <div className="detail-page">
                        {this.state.noRooms ?
                            <Row className="spinner-row">
                                <Col sm={12} className="spinner-col">

                                </Col>

                                <Col sm={12} className="spinner-col-title">
                                    <h3>Er zijn geen kamers beschikbaar, u kunt wel altijd nog even bellen</h3>
                                </Col>
                            </Row> :
                            <Row className="spinner-row">
                                <Col sm={12} className="spinner-col">
                                    <PropagateLoader />
                                </Col>

                                <Col sm={12} className="spinner-col-title">
                                    <h3>Kamer details worden geladen</h3>
                                </Col>
                            </Row>}
                    </div>
                </Container>
                :
                <div className="home-page">
                    <Row className="room-overview-row">
                        <Col sm={12} className="contact-colum">
                            <h2>Ons kameroverzicht</h2>
                            <p>Heb je een vraag over de mogelijkheden of over een kamer? Bel dan naar: <a href="tel:0625226150">06 25226150</a></p>

                        </Col>
                    </Row>

                    <div className="room-overview ">
                        {this.state.roomsAvaible ?
                            this.state.avaidebleRooms.sort((a, b) => a.sort - b.sort).filter(item => ((this.state.prices.sort((a, b) => (a.person > b.person) ? 1 : -1).filter(guests => item.id == guests.roomId).slice(-1).map((item, i) => (item.person))) >= this.props.data.amountPersons)).map((item, i) => (
                                <Row className="room-row">
                                    <Col md={4} className="room-image">
                                        <Link to={{ pathname: `/kamer-overzicht/details`, state: { roomName: item } }} > {this.state.images.filter(image => item.id == image.id && image.type == 'active').slice(0, 1).map((image) => (
                                            <img src={image.url} />
                                        ))}</Link>

                                    </Col>
                                    <Col md={8} className="room-information">
                                        <Row>
                                            <Col sm={7} className="border-right">
                                                <h2>{item.name}</h2>
                                                <p className="amount-people"><img alt='Het logo van Picoo' src={require('../../assets/images/icons/person-icon.png')} />Max. {this.state.prices.sort((a, b) => (a.person > b.person) ? 1 : -1).filter(guests => item.id == guests.roomId).slice(-1).map((item, i) => (item.person))} Personen</p>
                                                <ul>

                                                    {/*  this.state.facility.filter(facility => item.id == facility.roomId).map((facility,i)=>(
            < li><i class="fas fa-check-circle"></i> {facility.name}</li>
       ))*/}

                                                </ul>

                                                <p className="room-information-desc">
                                                    {item.desc.slice(0, 200)}... <Link to={{ pathname: `/kamer-overzicht/details`, state: { roomName: item } }} >Lees meer</Link>
                                                </p>
                                            </Col>


                                            <Col sm={5} className="room-price">
                                                <p></p>
                                                <h3>
                                                    € {parseFloat(this.state.prices.filter(guests => item.id == guests.roomId && this.props.data.amountPersons == guests.person).map((item, i) => (item.price))).toFixed(2)}
                                                </h3>
                                                <p>per nacht voor {this.props.data.amountPersons} personen</p>
                                                <div className="search-text">
                                                    De totaalprijs voor {this.state.totalNights} nacht(en), is  € {parseFloat(this.state.prices.filter(guests => item.id == guests.roomId && this.props.data.amountPersons == guests.person).map((item, i) => ((Number(item.price) * Number(this.state.totalNights))))).toFixed(2)}
                                                </div>



                                                <Link onClick={() => this.setValues(item.name, item.id, item.resourceId)} className="room-price-button" to={{ pathname: `/online-afrekenen`, state: { roomdata: item.name, totalNights: this.state.totalNights } }} >Boeken</Link>
                                            </Col>
                                        </Row>


                                    </Col>

                                </Row>

                            )) : <Row className="search-field not-found mobile-not-found">
                                <h2>Je hebt niet alle zoek velden correct gevult</h2>
                                <Link to={'/home'}>Terug</Link>

                            </Row>}




                    </div>
                </div>
        );
    }
};

export default RoomSearchComponent;
