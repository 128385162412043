import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { TransferWithinAStationSharp } from '@material-ui/icons';

class PayFamilyBookingOnlineComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: 'iDeal',
            nameClient: '',
            mailClient: '',
            phoneClient: ''

        };
    }

    generateBookingId() {

    }

    componentWillMount() {

        window.scrollTo(0, 0)


    }

    payOrder() {





    }

    changeName(event) {
        this.setState({
            nameClient: event
        })

        localStorage.setItem('nameClient', event)
    }

    changeMail(event) {
        this.setState({
            mailClient: event
        })

        localStorage.setItem('mailClient', event)
    }

    changePhone(event) {
        this.setState({
            phoneClient: event
        })

        localStorage.setItem('phoneClient', event)
    }


    render() {

        return (
            <div className="home-page">
                <Row className="contact-row">
                    <Col sm={12} className="contact-colum">
                        <h2>Kamer boeken!</h2>
                        <p></p>

                    </Col>
                </Row>

                <Row>
                    <Col sm={2}></Col>

                    <Col sm={4} xs={12} className="frontend-form">
                        <h2>Uw gegevens</h2>
                        <form method="post" action={"https://biejanssen.nl/paymentFamily"} id="hook-form" >
                            <label>
                                Uw naam: *
                            </label>
                            <br />
                            <input required type="text" name="nameClient" value={this.state.nameClient} onChange={event => this.changeName(event.target.value)} />
                            <br /><br />
                            <label>
                                Uw e-mail: *
                            </label>
                            <br />
                            <input required type="text" name="emailClient" value={this.state.mailClient} onChange={event => this.changeMail(event.target.value)} />
                            <br /><br />
                            <label>
                                Uw telefoonnummer: *
                            </label>
                            <br />
                            <input required type="text" name="phoneClient" value={this.state.phoneClient} onChange={event => this.changePhone(event.target.value)} />
                            <br /><br />
                            <input type="text" hidden value={localStorage.getItem('bookingID')} name="bookingId" />
                            <input type="text" hidden value={this.state.paymentMethod.toLocaleLowerCase()} name="betaalmethode" />
                            <input type="text" hidden value={(parseFloat(Number(localStorage.getItem('roomdataPrice')) + (2.25 * localStorage.getItem('guests') * this.props.data.totalNights) + 40).toFixed(2)).toString()} name="totalPrice" />
                            <input type="text" hidden value={(parseFloat(localStorage.getItem('roomdataPrice')).toFixed(2)).toString()} name="amount" />
                            <input type="text" hidden value={localStorage.getItem('roomdataPrice')} name="priceNight" />
                            <input type="text" hidden value={this.props.data.totalNights} name="totalNights" />
                            <input type="text" hidden value={localStorage.getItem('guests')} name="amountPeople" />
                            <input type="text" hidden value={localStorage.getItem('fullStartDate')} name="fullstartdate" />

                        </form>

                    </Col>
                    <Col sm={4} className="frontend-form">
                        <h2>Het overzicht</h2>
                        <span >
                            <ul className="total-breakfast">
                                <li>Prijs per nacht incl. ontbijt <span>€ {Number(localStorage.getItem('roomdataPrice')).toFixed(2)}</span></li>
                                <li>Aantal nachten<span>{this.props.data.totalNights}</span></li>
                                <li>Aantal personen<span>{localStorage.getItem('guests')}</span></li>
                                <li>Betaalmethode<span>{this.state.paymentMethod}</span></li>
                                <li>Toeristenbelasting <span>€ {(2.25 * localStorage.getItem('guests') * this.props.data.totalNights).toFixed(2)}</span></li>
                                <li>Schoonmaakkosten <span>€ 40.00</span></li>
                                <hr />
                                <li>Totaal <span>€ {parseFloat(Number(localStorage.getItem('roomdataPrice')) + (2.25 * localStorage.getItem('guests') * this.props.data.totalNights) + 40).toFixed(2)}</span></li>
                            </ul>
                        </span>

                        <button type="submit" form="hook-form">Afrekenen</button>


                    </Col>
                    <Col sm={2}>

                    </Col>

                </Row>

            </div>



        );
    }
};

export default PayFamilyBookingOnlineComponent;