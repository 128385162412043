import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../../components/common/PageTitle";
import AddRoomComponentStepOne from "../../../components/SettingsComponent/AddRoomComponent.js/step-one";


const StepOneViewAddRoom = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen - Kamer toevoegen -  Stap 1" subtitle="Cashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <AddRoomComponentStepOne  eventData={props.location.state}/>
    
  </Container>
);

export default StepOneViewAddRoom;
