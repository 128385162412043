import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import AboutUsComponent from "../../components/Frontend/AboutUsComponent";
import HomeComponent from "../../components/Frontend/HomeComponent";
import FooterView from "../Footer";
import HeaderView from "../Header";



const AboutUsView = (props) => (

  
  <Container fluid  className="sub-page">
 <HeaderView />
    <AboutUsComponent />
    <FooterView />

  
  </Container>
);

export default AboutUsView;
