import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';

class PayBookingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mail: '',
            shortDesc: '',
            totalPrice: '',
            payment: { id: '', name: '' }

        };
    }

    componentWillMount() {
        window.scrollTo(0, 0)
        const query = new URLSearchParams(this.props.id);
        let tempPayment = Firebase.database().ref('/clients/-uhfwoebfwioudb/events/' + query.get('id'));
        tempPayment.on('value', snapshot => {
            let state = snapshot.val();

            this.setState({
                payment: state,
                get: true,
                totalPrice: ((parseFloat(this.state.payment.totalNights) * (2.25 * parseFloat(this.state.payment.persons))) + (parseFloat(this.state.payment.singlePrice) * parseFloat(this.state.payment.totalNights))).toFixed(2).toString()
            })

            localStorage.setItem('key', state.key)
            localStorage.setItem('bookingID', state.bookingsnumber);
        })


    }

    payOrder() {

        localStorage.setItem('key', this.state.payment.key);
        localStorage.setItem('bookingID', this.state.payment.bookingsnumber);

        const totalPrice = ((parseFloat(this.state.payment.totalNights) * 2.25 * parseFloat(this.state.payment.persons)) + (parseFloat(this.state.payment.singlePrice) * parseFloat(this.state.payment.totalNights))).toFixed(2).toString();
        var xhr = new XMLHttpRequest();

        xhr.open('POST', 'https://app.biejanssen.nl/paymentManualBooking');
        xhr.setRequestHeader("Content-Type", "application/json");

        let data = {
            totalPrice: totalPrice,
            nameClient: this.state.payment.name,
            emailClient: this.state.payment.email,
        }
        xhr.send(JSON.stringify(data));

        xhr.onload = () => {
            console.log("status is:" + xhr.responseURL);
            window.location.href = xhr.responseURL;

        };
    }

    render() {

        return (
            <div className="home-page">
                <Row className="contact-row">
                    <Col sm={12} className="contact-colum">
                        <h2>Boeking betalen</h2>
                    </Col>
                </Row>

                <Row>
                    <Col sm={3}></Col>
                    <Col sm={6} className="frontend-table">
                        <h2>Je bestel overzicht</h2>
                        <form method="post" action={"https://biejanssen.nl/paymentManualBooking"} id="hook-form" >
                            <table>
                                <tr>
                                    <td><span className="bookingId">Order nummer</span></td>
                                    <td>{this.state.payment.bookingsnumber}</td>
                                </tr>
                                <tr>
                                    <td><span className="total-fat">Omschrijving</span></td>
                                    <td>Overnachting</td>

                                </tr>
                                <tr>
                                    <td><span className="nameClient">Je naam</span></td>
                                    <td>{this.state.payment.title}</td>
                                </tr>
                                <tr>
                                    <td><span className="emailClient">Je e-mail</span></td>
                                    <td>{this.state.payment.email}</td>
                                </tr>
                                <tr>
                                    <td><span className="total-fat">Datum inchecken</span></td>
                                    <td>{this.state.payment.startDay}-{(Number(this.state.payment.startMonth) + 1)}-{this.state.payment.startYear}</td>
                                </tr>
                                <tr>
                                    <td><span className="total-fat">Datum uitchecken</span></td>
                                    <td>{this.state.payment.endDay}-{(Number(this.state.payment.endMonth) + 1)}-{this.state.payment.endYear}</td>
                                </tr>
                                <tr>
                                    <td><span className="amountPeople">Aantal personen</span></td>
                                    <td>{this.state.payment.persons}</td>
                                </tr>
                                <tr>
                                    <td><span className="priceNight">Prijs per nacht incl. ontbijt</span></td>
                                    <td>€ {this.state.payment.singlePrice}</td>
                                </tr>
                                <tr>
                                    <td><span className="total-fat">Toeristenbelasting</span></td>
                                    <td>€ {(parseFloat(this.state.payment.totalNights) * 2.25 * parseFloat(this.state.payment.persons)).toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td><span className="total-oder">Totaal</span></td>
                                    <td><span className="total-oder">€ {((parseFloat(this.state.payment.totalNights) * (2.25 * parseFloat(this.state.payment.persons))) + (parseFloat(this.state.payment.singlePrice) * parseFloat(this.state.payment.totalNights))).toFixed(2).toString()}</span></td>
                                </tr>



                            </table>
                            <input hidden type="text" name="bookingId" value={this.state.payment.bookingsnumber} />
                            <input hidden type="text" name="nameClient" value={this.state.payment.title} />
                            <input hidden type="text" name="emailClient" value={this.state.payment.email} />
                            <input hidden type="text" name="phoneClient" value={this.state.payment.phone} />

                            <input hidden type="text" name="priceNight" value={this.state.payment.singlePrice} />
                            <input hidden type="text" name="amountPeople" value={this.state.payment.persons} />

                            <input hidden type="text" name="totalNights" value={this.state.payment.totalNights} />

                            <input hidden type="text" name="totalPrice" value={((parseFloat(this.state.payment.totalNights) * (2.25 * parseFloat(this.state.payment.persons))) + (parseFloat(this.state.payment.singlePrice) * parseFloat(this.state.payment.totalNights))).toFixed(2).toString()} />
                            <button type="submit" className="subtmit-button" form="hook-form">Betaal</button>
                        </form>

                        {
               /* <Button onClick={()=>this.payOrder()} >Betaal boeking!</Button>*/}
                    </Col>
                    <Col sm={3}></Col>

                </Row>

            </div>



        );
    }
};

export default PayBookingComponent;