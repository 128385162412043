import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';

class PayBreakfastComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            mail:'',
            shortDesc:'',
            payment:[]
            
        };
    }



    componentWillMount(){
        window.scrollTo(0, 0)
        console.log('mount')
        const query = new URLSearchParams(this.props.id);
        console.log(query.get('id'));
        
        let tempPayment = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/temp-payments/'+query.get('id'));
        tempPayment.on('value', snapshot => {
            let state =  snapshot.val();

          
            this.setState({
                payment: state,
                get: true
            })
            localStorage.setItem('name',state.name);
        localStorage.setItem('mail',state.email);
        localStorage.setItem('date',state.date);
        localStorage.setItem('phone',state.phone);
        localStorage.setItem('adres',state.adres);
        localStorage.setItem('postal',state.zip);
        localStorage.setItem('city',state.city);
        localStorage.setItem('bookingID', state.bookingsnumber)
        localStorage.setItem('amount',state.amount);
        localStorage.setItem('shippingCost',state.shippingCost);
        localStorage.setItem('unitPrice',state.unitPrice);
        localStorage.setItem('total',state.totalPrice);
            console.log(state.email)
          })
    }

    payOrder(){

       
        

        
      /*  var xhr = new XMLHttpRequest();
     
        xhr.open('POST', 'https://app.biejanssen.nl/paymentBreak');
              xhr.setRequestHeader("Content-Type", "application/json");
  
              let data ={
                  totalPrice: this.state.payment.totalPrice,
                  nameClient: this.state.payment.name,
                  emailClient: this.state.payment.email,
              }
             xhr.send(JSON.stringify(data));
  
             xhr.onload = () => {
              console.log("status is:"+xhr.responseURL);
              window.location.href=xhr.responseURL;
          
          };*/
    }

    render() {
       
        return (
            <div className="home-page">
            <Row className="contact-row">
                <Col sm={12} className="contact-colum">
                    <h2>Ontbijt betalen</h2>                    
                </Col>
            </Row>

        <Row> 
                <Col sm={3}></Col>
                <Col sm={6} className="frontend-table">
                    <h2>Je bestel overzicht</h2>
                    <form method="post" action={"https://biejanssen.nl/paymentBreak"} id="hook-form" >
                <table>
              
                    <tr>
                        <td><span className="total-fat">Order nummer</span></td>
                        <td>{this.state.payment.bookingsnumber} <input type="text" name="bookingId" value={this.state.payment.bookingsnumber} hidden/></td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Omschrijving</span></td>
                        <td>Ontbijt</td>

                    </tr>
                    <tr>
                        <td><span className="total-fat">Je naam</span></td>
                        <td>{this.state.payment.name} <input type="text" name="nameClient" value={this.state.payment.name} hidden/></td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Je e-mail</span></td>
                        <td>{this.state.payment.email} <input type="text" name="emailClient" value={this.state.payment.email} hidden/></td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Je telefoonnummer</span></td>
                        <td>{this.state.payment.phone} <input type="text" name="phoneClient" value={this.state.payment.phone} hidden/></td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Aflever datum</span></td>
                        <td>{this.state.payment.date}</td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Aflever adres</span></td>
                        <td>{this.state.payment.adres}, {this.state.payment.zip}, {this.state.payment.city}</td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Aantal ontbijtjes</span></td>
                        <td>{this.state.payment.amount} <input type="text" name="amount" value={this.state.payment.amount} hidden/></td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Prijs per ontbijt</span></td>
                        <td>€ {this.state.payment.unitPrice}</td>
                    </tr>
                    <tr>
                        <td><span className="total-fat">Bezorgkosten ontbijt</span></td>
                        <td>€ {this.state.payment.shippingCost}</td>
                    </tr>

                    <tr>
                        <td><span className="total-oder">Totaal</span></td>
                        <td><span className="total-oder">€ {this.state.payment.totalPrice} <input type="text" name="totalPrice" value={this.state.payment.totalPrice} hidden/></span></td>
                    </tr>

                    <input type="text" name="shippingCost" value={this.state.payment.shippingCost} hidden/>

                    <input type="text" name="singlePrice" value={this.state.payment.unitPrice} hidden />
                    <input type="text" name="adresdeliver" value={this.state.payment.adres} hidden />
                    <input type="text" name="datedeliver" value={this.state.payment.date} hidden />
                    <input type="text" name="namedeliver" value={this.state.payment.name} hidden />
                    <input type="text" name="number" value={'-'} hidden />
                    <input type="text" name="postal" value={this.state.payment.zip} hidden />
                    <input type="text" name="city" value={this.state.payment.city} hidden />
                    <input type="text" name="time" value={'-'} hidden />
                   

                 

               </table>

                <button type="submit" className="subtmit-button" form="hook-form">Betaal</button>
                    </form>

                </Col>
                <Col sm={3}></Col>

          </Row>

            </div>



        );
    }
};

export default PayBreakfastComponent;