import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button,Container } from "shards-react";
import Loader from 'react-loader-spinner'
import ReactStars from "react-rating-stars-component";
class BussinesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            mail:'',
            shortDesc:'',
            phone:'',
            succesMessage:false,
            loader:false,
        };
    }

    resetMessage(){
        this.setState({succesMessage:false});
        return
    }

    sendForm(){
        this.setState({
            loader:true
        })
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://biejanssen.nl/ContactForm", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({name:this.state.name,email:this.state.mail,phone:this.state.phone,desc:this.state.shortDesc})
      })
      .then(res => {
          if(res.status === 201){
            this.setState({
                name:'',
                mail:'',
                phone:'',
                shortDesc:'',
                succesMessage:true,loader:false
            })

            setTimeout(() => this.resetMessage(), 3000);
          }
      });
    }

    render() {
       
        return (
            <div className="home-page">
                 <Row className="surroundings-row">
                <Col sm={12} className="surroundings-colum">
                    <h2>Zakelijk</h2>
                    <p>B&B Bie Janssen ligt in de mooie oude kern van Elsloo vlakbij het bos en de Maasvallei en biedt naast overnachten ook mogelijkheden tot het houden van vergaderingen, trainingen (retreats) en workshops. Dat kan allemaal in de gezellig ingerichte en huiselijke tuinkamer die ruimte biedt aan 5 tot 12 personen. Neem contact met ons op en we bespreken graag en vrijblijvend de mogelijkheden van jouw meeting.
</p>

                    
                </Col>
            </Row>
            <Container>
            <Row className="location-row overlay-top">
        
                <Col sm={6} >
           
                <h2>Benieuwd naar alle mogelijkheden?</h2>
                <p>Download dan onze zakelijke flyer</p>
                <a className="frontend-button-dark" href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/%2F%2FFLYER_JAN_2023_RGB.pdf?alt=media&token=7da09240-f955-4491-a3cc-7443d39ece2a" target='_blank'>Download flyer</a>
                    
                </Col>       
                <Col sm={6} className="location-colom">
                    <img src={require('../../assets/images/biejanssen-flyer.png')}/>
                </Col>         
            </Row>
           
            <Row className="location-row">
            <Col sm={6} className="location-colom">
                    <img src={require('../../assets/images/frontend/zakelijk.jpg')}/>
                </Col>
                <Col sm={6} className="">
           
                    <h2>Faciliteiten</h2>
                    <p>De ontmoetingen vinden plaats in de tuinkamer en hier staat het volgende tot je beschikking:</p>
                    <ul>
                        <li>Beamer (3600 ANSI Lumen)</li>
                        <li>Groot scherm (2 meter bij 2 meter)</li>
                        <li>Flipover/whiteboard</li>
                        <li>Gratis WIFI</li>
                        <li>Airco voor koelen en verwarmen</li>
                        <li>Gevuld koelkastje (consumpties op nacalculatie)</li>
                    </ul>
                    <p>
                    Wij verzorgen (halve) dag arrangementen met koffie, thee, water. Optioneel zijn lunch, diner of iets lekkers voor bij de koffie. Mocht je willen overnachten dan is dit uiteraard ook mogelijk. Ook het afhuren van de gehele B&B voor je evenement behoort tot de mogelijkheden.<br/><br/>
Een dag arrangement met koffie, thee, water, huur ruimte en schoonmaak boek je al voor slechts € 32,50 per persoon met een minimum van 5 personen.

                    </p>
                   

                 
                    
                </Col>                
            </Row>

            <Row className="contact-row-details">
                <Col sm={5} className="contact-colom-details">
                    
                    <h2>Contact</h2>
                    <p>
                    Heb je een vraag? Neem dan contact met ons op via het formulier. Ook kun je ons direct bereiken via onderstaande gegevens. Wij doen er alles aan om je vraag z.s.m. te beantwoorden!
                    </p>
                    <h4>
                    Adresgegevens
                    </h4>
                    <ul>
                        <li>Raadhuisstraat 46, 6181 HB Elsloo</li>
                        <li><b>Email:</b> <a href="mailto:info@biejanssen.nl">info@biejanssen.nl</a></li>
                        <li><b>Telefoon:</b> 06 25226150</li>
                    </ul>
                </Col>
                <Col sm={7} className="contact-colom-details-form">
                 <h2>Stel je vraag</h2>
                    <form>
                    <input required type="text" placeholder="Bedrijfsnaam" name="name" value={this.state.name} onChange={event => this.setState({ name: event.target.value, errorMessage:''})}/>
                    <input  type="text" placeholder="E-mail" name="email" value={this.state.mail} onChange={event => this.setState({ mail: event.target.value, errorMessage:''})}/><br/>
                    <input  requiredtype="text" placeholder="Telefoon" name="phone" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage:''})}/>
                    <textarea name="desc" placeholder="Uw bericht" value={this.state.shortDesc} onChange={event => this.setState({ shortDesc: event.target.value, errorMessage:''})} />
                    {this.state.loader?
                             <button onClick={()=>this.sendForm()} type="submit" className="frontend-button-dark" form="hook-form"><Loader type="ThreeDots" color="white" height={10} width={50} timeout={40000}  /></button>


                    :
                    <button onClick={()=>this.sendForm()} type="submit" className="frontend-button-dark" form="hook-form">Verstuur</button>
                    }
                    </form>
            
                </Col>
            </Row>
            {this.state.succesMessage?
            <Row className="succes-send-form">
                <Col sm={12}>
                    <h5>Bedankt voor je bericht!</h5>
                    <p>Je bericht is succesvol verstuurt, wij nemen zo snel mogelijk contact met je op!</p>
                </Col>
            </Row>:null}

            <Row className="about-us-home-row" >
                <Col sm={12} className="about-us-home">
                    <h2>Wat anderen ervaarden</h2>
                    <p>Hieronder tref je een aantal reviews aan van onze gasten. </p>
                </Col>
               
                    <Col sm={6}>
                    <Row>
                        
                        <Col sm={4}>
                        <h5>Bart Dresens van BLG Wonen</h5>
                        </Col>
                       
                        <Col sm={8}>
                        <p>“We zijn voor onze Heidagen heel hartelijk ontvangen door Peter en Sabrina in hun mooie B&B. Fijne vergaderruimte, fijne kamers en we zijn goed verzorgd met eten en drinken!”</p>
                        </Col>
                    </Row>
                    </Col>
                    <Col sm={6}>
                    <Row>
                        
                        <Col sm={4}>
                        <h5>Eline Smeets-Stans</h5>
                        </Col>
                       
                        <Col sm={8}>
                        <p>“In september was er weer een zeer bijzonder Groei-weekend. Mede dankzij de bijzondere locatie die ik steeds hiervoor mag gebruiken! #dankbaar!”</p>
                        </Col>
                    </Row>
                    </Col>

                    <Col sm={6}>
                    <Row>
                        
                        <Col sm={4}>
                        <h5>Femke Hanssen</h5>
                        </Col>
                       
                        <Col sm={8}>
                        <p>“Afgelopen weekend met 12 personen, voor een nette prijs, gebruik mogen maken van de ruimte die Sabrina en Peter aanbieden voor workshops, vergaderingen etc (gewoon vragen naar de mogelijkheden). Van tevoren al eens gaan kijken hoe het eruit zag, echt een heel mooie locatie, mooie ruimtes en de eigenaren zijn heel erg vriendelijk en denken met je mee. Leuk om te zien hoe enthousiast er werd meegeholpen met versieren! We hebben het, mede hierdoor, echt leuk gehad!”</p>
                        </Col>
                    </Row>
                    </Col>
                   
                    
                    </Row>
            </Container>
            </div>



        );
    }
};

export default BussinesComponent;