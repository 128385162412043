import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ChangeAppointment from "../../components/calendar/changeAppointment";
import AddAppointment from "../../components/calendar/addAppointment";
import ChangeRoomSettingsComponent from "../../components/SettingsComponent/ChangeRoomSettingsComponent";
import ChangeProductSettingsComponent from "../../components/SettingsComponent/ChangeProductRoomSettingsComponent";


const ChangeProductSettingsView = (props) => (

  
  <Container fluid className="main-content-container px-4 backend">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen - Product wijzigen" subtitle="Cashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <ChangeProductSettingsComponent eventData={props.location.state} />
    
  </Container>
);

export default ChangeProductSettingsView;
