import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';

import DeleteIcon from "../../assets/images/svg/times-solid.svg"
import InvoiceIcon from "../../assets/images/svg/invoice.svg"
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-janssen.png'
import { Page,Image, Text, View, Document, StyleSheet,PDFDownloadLink } from '@react-pdf/renderer';

const HeadInvoice = () =>{
    return <View style={styles.page}>
         <View style={styles.section}>
         <Text>Factuur</Text>
         </View>
         <View style={styles.section}>
             <Image style={styles.image} src={Logo} />
         </View>
     </View>
 }
 
 const ToInvoice = ({data}) => {
     return <View style={styles.page}>
         <View style={styles.sectionfull}>
             <Text>{data.name}</Text>
             {/*<Text>De Haamen 1</Text>
             <Text>6191HV, Beek</Text>*/}
         </View>
     </View>
 }
 
 
 const InformationInvoice = ({data}) =>{
     return <View style={styles.pageText}>
         <View style={styles.sectionText}>
         <Text>Tel: {data.phone}</Text>
         <Text>mail: {data.email}</Text>
         </View>
         <View style={[styles.sectionText,styles.textRight]}>
         <Text>Onze ref: {data.bookingnsnumber}</Text>
         <Text>Contactpersoon: Peter Janssen</Text>
         <Text>Mail: info@biejanssen.nl</Text>
         </View>
     </View>
 }
 
 const DateInvoice = ({data}) => {
     return <View style={styles.pageTextReg}>
         <View style={styles.sectionTextReg}>
             <Text>Datum: {data.payDate}</Text>
             <Text>Betreft: {data.desc}</Text>
         </View>
     </View>
 }
 
 const HrLineTop = () =>{
     return <View style={styles.hrLineTop}></View>
 }
 
 const HrLine = () =>{
     return <View style={styles.hrLine}></View>
 }
 
 const TableInvoice = ({data}) =>{
     return <View style={styles.table}>
             <View style={styles.tableRow}>
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>Omschrijving</Text> 
                 </View> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>Aantal</Text> 
                 </View> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>Per stuk</Text> 
                 </View> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>Totaal</Text> 
                 </View> 
                 </View> 
             <View style={styles.tableRow}> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>{data.desc}</Text> 
                 </View> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>{data.amount} </Text> 
                 </View> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>€ {parseFloat(data.price).toFixed(2)}</Text> 
                 </View> 
                 <View style={styles.tableCol}> 
                     <Text style={styles.tableCell}>€ {parseFloat((data.price* data.amount)).toFixed(2)}</Text> 
                 </View> 
             </View> 
         </View>
 }
 
 const TextInvoice = () => {
     return <View style={styles.pageTextReg}>
         <View style={styles.sectionTextReg}>
             <Text>Heeft u verder nog vragen, neem dan direct contact met ons op via:</Text>
            {/* <Text>Gelieve bovenstaand bedrag onder vermelding van het factuurnummer binnen 14 dagen na factuurdatum te betalen op IBAN-nummer: NL98 INGB 0009 543250 t.n.v. PixelPro’s V.O.F.</Text>*/}
         </View>
     </View>
 }
 
 const FooterInvoice = () =>{
     return <View style={styles.pageFooter}>
          <View style={styles.sectionThree}>
             <Text style={styles.footerTextBlue}>een onderneming waar {"\n"}innovatie en maatwerk {"\n"}centraal staan</Text>
          </View>
          <View style={styles.sectionThree}>
             <Text style={styles.footerTextGrey}>Spechthof 7, 6181 KK, Elsloo {"\n"}+31 6 117 346 02{"\n"}info@pixelpros.nl</Text>
          </View>
          <View style={styles.sectionThree}>
          <Text style={styles.footerTextGrey}><Text style={styles.footerTextBlue}>kvk</Text> 75729474{"\n"}<Text style={styles.footerTextBlue}>iban</Text> NL98 INGB 0009 5432 50{"\n"}<Text style={styles.footerTextBlue}>btw</Text> NL860377003B01{"\n"}</Text>
          </View>
      </View>
  }
  
 
 
 
 const MyDoc = (item) => (

   <Document>
   <Page size="A4" style={styles.pageFull}>
 
     <HeadInvoice />
     <HrLineTop/>
     <ToInvoice data={{name:item.billData.name}}/>
     <InformationInvoice data={{bookingnsnumber:item.billData.bookingnsnumber,phone:item.billData.phone,email:item.billData.email}}/>
     <DateInvoice data={{payDate:item.billData.payDate, desc:item.billData.desc}}/>
     <TableInvoice data={{desc:item.billData.desc,amount:item.billData.amount,price:item.billData.price,totalPrice:item.billData.totalPrice}}/>
     <HrLine />
     <TextInvoice />
     <FooterInvoice />
     
   </Page>
 </Document>
 )



class ClientDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bills:[
                {id:1, bookingnsnumber: '001', name:'Joppe Meijers',payDate:'24-02-2021',email:'joppe@pixelpros.nl',phone:'06-10090840',desc: 'Overnachting',amount:1,startdate:'02-02-2021', price: 90,totalPrice: 90},
                {id:1, bookingnsnumber: '002', name:'Joppe Meijers',payDate:'24-02-2021',email:'joppe@pixelpros.nl',phone:'06-10090840',desc: 'Overnachting',amount:2,startdate:'02-02-2021', price: 90,totalPrice: 180},
                {id:1, bookingnsnumber: '003', name:'Joppe Meijers',payDate:'24-02-2021',email:'joppe@pixelpros.nl',phone:'06-10090840',desc: 'Overnachting',amount:3,startdate:'02-02-2021', price: 90,totalPrice: 270},
                {id:1, bookingnsnumber: '004', name:'Joppe Meijers',payDate:'24-02-2021',email:'joppe@pixelpros.nl',phone:'06-10090840',desc: 'Overnachting',amount:4,startdate:'02-02-2021', price: 90,totalPrice: 360},
            
            ]
        };
    }

    componentDidMount(){
        console.log(this.props.clientData.clientData);
    }


   


    render() {  

        
        return (
            <div>

                <Row >
                    <Col sm={4} className="bill-detail-list">
                        <ul>
                            <li>Klantnummer:<span className="bill-detail-list-element"> 001</span></li>
                            <li>Naam:<span className="bill-detail-list-element">Joppe Meijers</span></li>
                            <li>Telefoon:<span className="bill-detail-list-element">06-10090840</span></li>
                            <li>E-mail:<span className="bill-detail-list-element">joppe@pixelpros.nl</span></li>
                        </ul>
                    </Col>
                    <Col sm={8}></Col>
                <Col sm={12} className="bills-component">

<table className="bills-table"> 
  <tr>
    <th>Boekingsnummer</th>
    <th>Naam</th>
    <th>Totaal</th>
    <th>Acties</th>
  </tr>
  {this.state.bills.map((item,i)=>(
    <tr>
      <td>{item.bookingnsnumber} </td>
      <td>{item.name}</td>
      <td>€ {parseFloat(item.totalPrice).toFixed(2)}</td>
      <td><PDFDownloadLink document={<MyDoc billData={item}/>} fileName="somename.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Factuur')}
    </PDFDownloadLink></td>
  </tr>
         ))}                   
 </table>
 </Col>

 <Col sm={12}>
 <Link className="back-link" to={{pathname: `/rekeningen`}} >Terug</Link>
 </Col>
 <Col sm={12} className="black-space">

 </Col>
                </Row>
                
            
            
            </div>
        );
    }
};

const styles = StyleSheet.create({
    pageFull:{
        padding: 40,
    },
  page: {
    flexDirection :'row',
    backgroundColor: 'white',
    marginBottom:'-10%',
    paddingBottom:'-10%',
    width:'100%',
    position:'relative'
  },
  pageFooter:{
    flexDirection :'row',
    backgroundColor: 'white',

    width:'100%',
    position:'relative',
    bottom: 0,
    flex:1,
    justifyContent:'flex-end',
    alignItems:"flex-end"
  },
  pageText: {
    flexDirection :'row',
    backgroundColor: 'white',
    marginTop:-80
  },
  pageTextReg:{
    flexDirection :'row',
    backgroundColor: 'white',
    marginTop: 20
  },
  textRight:{
marginLeft:'40%'
  },
  sectionText:{
    fontSize: 12
  },
  sectionTextReg:{
    fontSize: 12
  },
  section: {

    width:'50%',
    textAlign:'left',
    
    
  },
  sectionThree: {

    width:'33%',
    textAlign:'left',
    
    
  },
  sectionfull:{    
    float:'left',
    width:'100%',
    textAlign:'left',
    marginTop:20,
    fontSize:12
    },
  image: {
    marginLeft:'40%',
    width:120,
    height:30
  },
  line:{
      marginTop: 10,
      borderTopWidth: 2,
      borderTopStyle: 'solid',
      borderTopColor:'black',
      flexBasis:'100%'
  }, hrLineTop:{
    padding:5,
    width:'100%',
    borderBottomWidth: 2,
    borderBottomStyle:'solid',
    borderBottomColor:'black',
    marginTop:-90
},
  hrLine:{
    marginTop:20,
      padding:5,
      width:'100%',
      borderBottomWidth: 2,
      borderBottomStyle:'solid',
      borderBottomColor:'black'
  },
  hrTop:{
      marginTop:-90
  },
  table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, marginTop:"5%" }, 
  tableRow: { margin: "auto", flexDirection: "row",textAlign:'left',alignItems:'left' }, 
  tableCol: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,textAlign:'left' }, 
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10, textAlign:'left', float:'left' },
  footerTextBlue:{
      fontSize: 12,
      color: "blue"
  },
  footerTextGrey:{
    fontSize: 12,
    color: "grey"
},

});

export default ClientDetailComponent;
