import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import RoomOverviewComponent from "../../components/Booking/RoomOverviewComponent";
import HeaderView from "../Header";
import FooterView from "../Footer";


const RoomOverviewView = (props) => (

  
  <Container fluid className="main-content-container sub-page">
    <HeaderView/>

    <RoomOverviewComponent />

    <FooterView />
    
  </Container>
);

export default RoomOverviewView;
