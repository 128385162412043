import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";

// Route Views
import QSortOverviewScreen from "./views/QSort";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import qsortSettingsView from "./views/qSortSettings";
import qsortStart from "./views/QSortStartScreen";
import qsortEnd from "./views/QSortEndScreen";
import qSortSorting from "./views/QSortSortingScreen";
import LoginScreen from './views/LoginScreen';
import Dashboard from "./views/Dashboard";
import Calendar from "./views/CalendarScreen";
import Cashdesk from "./views/CashdeskScreen";
import ChooseMethod from "./views/ChooseMethod";
import TransactionScreen from "./views/CashdeskTransactionScreen";
import CheckScreen from "./views/CashDeskCheckScreen";
import InvoicePDFView from "./views/InvoiceView";
import SignInScreen from "./views/signInView";
import RegistrationView from "./views/Paasveejaarmarkt/RegistrationView";
import RegistrationViewHU from "./views/HuStdenten/RegistrationView";
import OnlineCheckoutScreen from "./views/OnlineCheckoutScreen";
import SettingsScreen from "./views/Settings/SettingsScreen";
import BookingView from "./views/Booking/BookingView";
import RoomOverviewView from "./views/Booking/RoomOverviewView";
import RoomDetailView from "./views/Booking/RoomDetailView";
import PaymentEndPointView from "./views/PaymentEndpointView/PaymentEndPointView";
import ChangeAppointmentView from "./views/Calendar/changeAppointmentView";
import BillsView from "./views/Bills/BillView";
import BillDetailView from "./views/Bills/BillDetailView";
import ClientView from "./views/Clients/ClientView";
import ClientDetailView from "./views/Clients/ClientDetailView";
import HomeView from "./views/Frontend/HomeView";
import AboutUsView from "./views/Frontend/AboutUsView";
import SurroundingsView from "./views/Frontend/SurroundingsView";
import SurpriseboxView from "./views/Frontend/SurpriseboxView";
import ContactView from "./views/Frontend/ContactView";
import AddAppointmentView from "./views/Calendar/AddAppointmentView";
import AddRoomSettingsView from "./views/Settings/AddRoomSettingsView";
import ChangeRoomSettingsView from "./views/Settings/ChangeRoomSettingsView";
import AddProductSettingsView from "./views/Settings/AddProductRoomSettingsView";
import ChangeProductSettingsView from "./views/Settings/ChangeProductRoomSettingsView";
import StepOneViewAddRoom from "./views/Settings/AddRoomView/StepOneView";
import PayBreakfastView from "./views/Frontend/PayBreakfastView";
import ExtraView from "./views/Frontend/ExtraView";
import OnlineCheckoutBreakfastScreen from "./views/OnlineCheckoutBreakfastScreen";
import PayBreakfastOnlineView from "./views/Frontend/PayBreakfastOnlineView";
import PaymentBreakfastEndPointView from "./views/PaymentEndpointView/PaymentBreakfastEndpointView";
import PayBookingfastOnlineView from "./views/Frontend/PayBookingOnlineView";
import PaymentsBookingManView from "./views/PaymentEndpointView/PaymentsBookingManView";
import BillDetailOrderView from "./views/Bills/BillDetailOrderView";
import PayBookingOnlineView from "./views/Booking/PayBookingView";
import VacationView from "./views/Vacation/VacationView";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: Dashboard
  },
  
  {
    path: "/errors",
    layout: DefaultLayout,exact: true,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    exact: true,
    component: ComponentsOverview
  },
  {
    path: "/agenda",
    layout: DefaultLayout,
    exact: true,
    component: Calendar
  },
  {
    path: "/agenda/aanpassen",
    layout: DefaultLayout,
    exact: true,
    component: ChangeAppointmentView
  },
  {
    path: "/agenda/toevoegen",
    layout: DefaultLayout,
    exact: true,
    component: AddAppointmentView
  },
  {
    path: "/kassa",
    layout: DefaultLayout,
    exact: true,
    component: Cashdesk
  },
  {
    path: "/kies-betaalmethode",
    layout: DefaultLayout,
    exact: true,
    component: ChooseMethod
  },
  {
    path: "/transactie-afronden",
    exact: true,
    layout: DefaultLayout,
    component: TransactionScreen
  },
  {
    path: "/transactie-klaar",
    exact: true,
    layout: DefaultLayout,
    component: CheckScreen
  },
  {
    path: "/instellingen",
    exact: true,
    layout: DefaultLayout,
    component: SettingsScreen
  },
  {
    path: "/instellingen/kamer-toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddRoomSettingsView
  },
  {
    path: "/instellingen/kamer-wijzigen",
    exact: true,
    layout: DefaultLayout,
    component: ChangeRoomSettingsView
  },
  {
    path: "/instellingen/product-toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddProductSettingsView
  },
  {
    path: "/vakantie",
    exact: true,
    layout: DefaultLayout,
    component: VacationView
  },
  {
    path: "/instellingen/product-wijzigen",
    exact: true,
    layout: DefaultLayout,
    component: ChangeProductSettingsView
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    exact: true,
    component: Tables
  },
  {
    path: "/invoice-pdf",
    layout: DefaultLayout,
    exact: true,
    component: InvoicePDFView
  },
  {
    path: "/rekeningen",
    layout: DefaultLayout,
    exact: true,
    component: BillsView
  },
  {
    path: "/rekeningen/details",
    layout: DefaultLayout,
    exact: true,
    component: BillDetailView
  },
  {
    path: "/rekeningen/details-order",
    layout: DefaultLayout,
    exact: true,
    component: BillDetailOrderView
  },
  {
    path: "/inschrijven-paasveerjaarmarkt",
    layout: ModalLayout,
    exact: true,
    component: RegistrationView
  },
  {
    path: "/inschrijven-hu-studenten",
    layout: ModalLayout,
    exact: true,
    component: RegistrationViewHU
  },

{
    path: "/instellingen/kamer-toevoegen/stap-1",
    layout: DefaultLayout,
    exact: true,
    component: StepOneViewAddRoom
  },
/*
  {
    path: "/booking-betaling/:id",
    layout: ModalLayout,
    exact: true,
    component: PayBookingfastOnlineView
  },*/
  

  {
    path: "/login",
    layout: ModalLayout,
    exact: true,
    component: LoginScreen
  },

  {
    path: "/klanten",
    layout: DefaultLayout,
    exact: true,
    component: ClientView
  },{
    path: "/klanten/details",
    layout: DefaultLayout,
    exact: true,
    component: ClientDetailView
  },



  {
    path: "/sign-in",
    layout: ModalLayout,
    exact: true,
    component: SignInScreen
  },
];
